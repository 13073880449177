<template>
  <main class="content-page">
    <div class="container">
      <div class="main-ct">
        <div class="box">
          <div class="box-head">
            <div class="back-steps-id">
              <a href="javascript:void(0):" @click="goBack">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="17"
                  height="13"
                  viewBox="0 0 17 13"
                  fill="none"
                >
                  <path
                    d="M5.75452 1.07142C5.98093 0.845009 6.33737 0.845009 6.56378 1.07142C6.78254 1.29018 6.78254 1.65427 6.56378 1.87252L2.46037 5.97592H16.0011C16.3167 5.97592 16.5757 6.22681 16.5757 6.54246C16.5757 6.8581 16.3167 7.11715 16.0011 7.11715H2.46037L6.56378 11.2129C6.78254 11.4393 6.78254 11.8039 6.56378 12.0222C6.33737 12.2486 5.98093 12.2486 5.75452 12.0222L0.679695 6.94734C0.460935 6.72858 0.460935 6.36449 0.679695 6.14624L5.75452 1.07142Z"
                    fill="#414B5B"
                  ></path>
                </svg>
              </a>
            </div>
            <h2 v-show="isEdit">{{ $t("userProfile_edit_title") }}</h2>

            <h2 v-show="!isEdit">{{ $t("userProfile_detail_title") }}</h2>
            <a
              href="javascript:void(0):"
              @click="showEdit"
              class="txt-edit"
              v-show="!isEdit"
              >{{ $t("edit") }}</a
            >
          </div>
          <div class="box-body">
            <form autocomplete="on">
              <div class="group-box-choose-profile">
                <h2 class="title-box-profile">
                  {{ $t("userProfile_edit_avatar_title") }}
                </h2>
                <div class="content-group-box-profile">
                  <ul class="box-ct edit-profile">
                    <li>
                      <div class="d-flex align-items-center">
                        <div class="avt-area">
                          <um-web-file-preview
                            v-if="userProfile.avatar"
                            :fileUrl="userProfile.avatar"
                            :itemWidth="100"
                            :itemHeight="100"
                          />
                          <img
                            src="id/@avata-nodata.png"
                            class="w-100"
                            v-else
                          />
                          <button
                            type="button"
                            class="upload-avt"
                            @click="showUploadAvatar"
                            v-show="isEdit"
                          >
                            <span class="icon-upload"></span>
                          </button>
                        </div>

                        <div class="name-area">
                          <div
                            class="custom-input"
                            :class="{
                              'is-err':
                                submitted &&
                                !$v.userProfile.displayName.required,
                            }"
                          >
                            <input
                              type="text"
                              id="iptvaluename"
                              class="ipt"
                              v-model="userProfile.displayName"
                              name="displayname"
                              :readonly="!isEdit"
                            />
                            <label for="iptvaluename" class="lbl">
                              {{ $t("userProfile_edit_displayname") }}
                              <span class="obl">*</span>
                            </label>
                          </div>
                          <div
                            class="txt-mess ml-0 text-left"
                            v-if="
                              submitted && !$v.userProfile.displayName.required
                            "
                          >
                            {{ $t("address_fullname_required") }}
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="group-box-choose-profile" v-show="isEdit">
                <h2 class="title-box-profile">{{ $t("intro") }}</h2>
                <div class="content-group-box-profile">
                  <ul class="box-ct edit-profile">
                    <li>
                      <div class="block-textarea-tsl">
                        <div class="custom-input">
                          <textarea
                            name=""
                            id=""
                            class="ipt"
                            v-model="userProfile.role"
                          ></textarea>
                          <label for="iptvaluename" class="lbl">
                            {{ $t("about_me") }}
                          </label>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div
                class="group-box-choose-profile"
                v-show="!isEdit && userProfile.role"
              >
                <h2 class="title-box-profile">{{ $t("intro") }}</h2>
                <div class="content-group-box-profile">
                  <p class="txt-content-box-profile">
                    {{ userProfile.role }}
                  </p>
                </div>
              </div>

              <div
                class="group-box-choose-profile"
                v-show="isEdit || userProfile.educationList.length > 0"
              >
                <!-- <div class="group-box-choose-profile"> -->
                <h2 class="title-box-profile">
                  {{ $t("userProfile_edit_education") }}
                </h2>
                <div
                  class="content-group-box-profile"
                  v-for="(item, index) in userProfile.educationList"
                  :key="item.renderKey"
                >
                  <collapse :show="item.show">
                    <span
                      class="item-title"
                      slot="header"
                      v-show="
                        item.school.showText && item.classification.showText
                      "
                    >
                      {{ item.school.showText }} /
                      {{ item.classification.showText }}
                    </span>
                    <div class="content-block-choose-profile" slot="body">
                      <ul class="box-ct edit-profile" v-show="isEdit">
                        <li>
                          <ul class="row lst-itm-info">
                            <li class="col-md-12 col-xl-6">
                              <div
                                class="custom-input"
                                :class="{
                                  'is-err':
                                    submitted &&
                                    !$v.userProfile.educationList.$each[index]
                                      .level.required,
                                }"
                              >
                                <input
                                  type="text"
                                  id="iptvalue"
                                  class="ipt"
                                  value="Placeholder"
                                  v-model="item.level"
                                  name="level"
                                />
                                <label for="iptvalue" class="lbl">
                                  {{ $t("userProfile_edit_education_level") }}
                                  <span class="obl">*</span>
                                </label>
                              </div>
                              <div
                                class="txt-mess ml-0 text-left"
                                v-if="
                                  submitted &&
                                  !$v.userProfile.educationList.$each[index]
                                    .level.required
                                "
                              >
                                {{ $t("userProfile_education_level_req") }}
                              </div>
                            </li>
                            <li class="col-md-12 col-xl-6">
                              <um-web-select-autocomplete
                                v-model="item.school.value"
                                code="id"
                                label="name"
                                :displayText="
                                  $t('userProfile_edit_education_school')
                                "
                                :urlApi="constants.schoolUrl"
                                :urlGetByIdApi="constants.schoolByIdUrl"
                                :isRequired="true"
                                :isSubmitted="submitted"
                                @initText="
                                  (value) => {
                                    initText(
                                      value,
                                      item,
                                      'educationList',
                                      'school'
                                    );
                                  }
                                "
                                @initShowText="
                                  (value) => {
                                    initShowText(
                                      value,
                                      item,
                                      'educationList',
                                      'school'
                                    );
                                  }
                                "
                              />
                              <div
                                class="txt-mess ml-0 text-left"
                                v-if="
                                  submitted &&
                                  !$v.userProfile.educationList.$each[index]
                                    .school.showText.required
                                "
                              >
                                {{ $t("userProfile_education_school_req") }}
                              </div>
                            </li>
                            <li class="col-md-12 col-xl-6">
                              <um-web-select-autocomplete
                                v-model="item.major.value"
                                code="id"
                                label="title"
                                :displayText="
                                  $t('userProfile_edit_education_major')
                                "
                                :urlApi="constants.categoryUrl"
                                :urlGetByIdApi="constants.categoryByIdUrl"
                                :customParams="{
                                  code: constants.education_major_Code,
                                }"
                                @initText="
                                  (value) => {
                                    initText(
                                      value,
                                      item,
                                      'educationList',
                                      'major'
                                    );
                                  }
                                "
                                @initShowText="
                                  (value) => {
                                    initShowText(
                                      value,
                                      item,
                                      'educationList',
                                      'major'
                                    );
                                  }
                                "
                              />
                            </li>
                            <li class="col-md-12 col-xl-6">
                              <um-web-select-autocomplete
                                v-model="item.type.value"
                                code="id"
                                label="title"
                                :displayText="
                                  $t('userProfile_edit_education_type')
                                "
                                :urlApi="constants.categoryUrl"
                                :urlGetByIdApi="constants.categoryByIdUrl"
                                :customParams="{
                                  code: constants.education_type_Code,
                                }"
                                @initText="
                                  (value) => {
                                    initText(
                                      value,
                                      item,
                                      'educationList',
                                      'type'
                                    );
                                  }
                                "
                                @initShowText="
                                  (value) => {
                                    initShowText(
                                      value,
                                      item,
                                      'educationList',
                                      'type'
                                    );
                                  }
                                "
                              />
                            </li>
                            <li class="col-md-12 col-xl-6">
                              <um-web-select-autocomplete
                                v-model="item.classification.value"
                                code="id"
                                label="title"
                                :displayText="
                                  $t(
                                    'userProfile_edit_education_classification'
                                  )
                                "
                                :urlApi="constants.categoryUrl"
                                :urlGetByIdApi="constants.categoryByIdUrl"
                                :isRequired="true"
                                :isSubmitted="submitted"
                                :customParams="{
                                  code: constants.education_classification_Code,
                                }"
                                @initText="
                                  (value) => {
                                    initText(
                                      value,
                                      item,
                                      'educationList',
                                      'classification'
                                    );
                                  }
                                "
                                @initShowText="
                                  (value) => {
                                    initShowText(
                                      value,
                                      item,
                                      'educationList',
                                      'classification'
                                    );
                                  }
                                "
                              />
                              <div
                                class="txt-mess ml-0 text-left"
                                v-if="
                                  submitted &&
                                  !$v.userProfile.educationList.$each[index]
                                    .classification.showText.required
                                "
                              >
                                {{
                                  $t("userProfile_education_classification_req")
                                }}
                              </div>
                            </li>
                            <li class="col-md-6 col-xl-3">
                              <div class="custom-input selbox">
                                <!-- {{item.startDate}} -->
                                <um-web-datetimepicker
                                  v-model="item.startDate"
                                  type="month"
                                  inputClass="ipt ipt-datetime"
                                  class="custom-date"
                                />
                                <label for="inputDateTimeStart" class="lbl">
                                  {{ $t("userProfile_experience_start") }}
                                </label>
                              </div>
                            </li>
                            <li
                              class="col-md-6 col-xl-3"
                              v-show="!item.isStudying"
                            >
                              <div class="custom-input selbox">
                                <um-web-datetimepicker
                                  v-model="item.endDate"
                                  type="month"
                                  inputClass="ipt ipt-datetime"
                                  class="custom-date"
                                />
                                <label for="inputDateTimeEnd" class="lbl">
                                  {{ $t("userProfile_experience_end") }}
                                </label>
                              </div>
                            </li>

                            <li
                              class="
                                col-md-12 col-xl-6
                                d-flex
                                align-items-center
                              "
                            >
                              <div
                                class="
                                  form-check form-check-inline
                                  checkbox-check
                                  mr-0
                                "
                              >
                                <input
                                  class="form-check-input"
                                  type="checkbox"
                                  value=""
                                  :id="item.renderKey"
                                  v-model="item.isStudying"
                                  @change="setCheckboxIsStudying(item)"
                                />
                                <label
                                  class="form-check-label"
                                  :for="item.renderKey"
                                >
                                  {{
                                    $t("userProfile_edit_education_studying")
                                  }}
                                </label>
                              </div>
                            </li>
                          </ul>
                        </li>
                      </ul>
                      <ul class="row lst-itm-info" v-show="!isEdit">
                        <li class="col-md-12 col-xl-6">
                          <div class="item-table-list-content">
                            <p class="txt-title">
                              {{ $t("userProfile_edit_education_level") }}:
                            </p>
                            <p class="txt-content-table">{{ item.level }}</p>
                          </div>
                        </li>
                        <li class="col-md-12 col-xl-6">
                          <div class="item-table-list-content">
                            <p class="txt-title">
                              {{ $t("userProfile_edit_education_school") }}:
                            </p>
                            <p class="txt-content-table">
                              {{ item.school.showText }}
                            </p>
                          </div>
                        </li>
                        <li class="col-md-12 col-xl-6">
                          <div class="item-table-list-content">
                            <p class="txt-title">
                              {{ $t("userProfile_edit_education_major") }}:
                            </p>
                            <p class="txt-content-table">
                              {{ item.major.showText }}
                            </p>
                          </div>
                        </li>
                        <li class="col-md-12 col-xl-6">
                          <div class="item-table-list-content">
                            <p class="txt-title">
                              {{ $t("userProfile_edit_education_type") }}:
                            </p>
                            <p class="txt-content-table">
                              {{ item.type.showText }}
                            </p>
                          </div>
                        </li>
                        <li class="col-md-12 col-xl-6">
                          <div class="item-table-list-content">
                            <p class="txt-title">
                              {{
                                $t("userProfile_edit_education_classification")
                              }}:
                            </p>
                            <p class="txt-content-table">
                              {{ item.classification.showText }}
                            </p>
                          </div>
                        </li>
                        <li class="col-md-6 col-xl-3">
                          <div class="item-table-list-content">
                            <p class="txt-title">
                              {{ $t("userProfile_experience_start") }}:
                            </p>
                            <p class="txt-content-table">
                              {{ item.startDate | moment("MM/YYYY") }}
                            </p>
                          </div>
                        </li>
                        <li class="col-md-6 col-xl-3" v-show="!item.isStudying">
                          <div class="item-table-list-content">
                            <p class="txt-title">
                              {{ $t("userProfile_experience_end") }}:
                            </p>
                            <p class="txt-content-table">
                              {{ item.endDate | moment("MM/YYYY") }}
                            </p>
                          </div>
                        </li>

                        <li
                          class="col-md-12 col-xl-6 d-flex align-items-center"
                        >
                          <div
                            class="
                              form-check form-check-inline
                              checkbox-check
                              mr-0
                            "
                          >
                            <input
                              class="form-check-input"
                              type="checkbox"
                              value=""
                              :id="item.renderKey"
                              :checked="item.isStudying"
                              :disabled="true"
                            />
                            <label class="form-check-label">
                              {{
                                $t("userProfile_edit_education_studying")
                              }}</label
                            >
                          </div>
                        </li>
                      </ul>
                      <div class="lst-btn d-flex">
                        <a
                          href="javascript:void(0);"
                          @click="deleteBlock(item, 'educationList')"
                          class="btn-delete"
                          v-show="isEdit"
                          >{{ $t("delete") }}</a
                        >
                      </div>
                    </div>
                  </collapse>
                </div>
                <div class="add-qt" v-show="isEdit">
                  <a
                    href="javascript:void(0);"
                    class="add-new-addr"
                    @click="addEducation"
                  >
                    <i class="plus">+</i>
                    {{ $t("userProfile_edit_education_add") }}
                  </a>
                </div>
              </div>
              <div
                class="group-box-choose-profile"
                v-show="isEdit || userProfile.experienceList.length > 0"
              >
                <h2 class="title-box-profile">
                  {{ $t("userProfile_experience") }}
                </h2>
                <div
                  class="content-group-box-profile"
                  v-for="(item, index) in userProfile.experienceList"
                  :key="item.renderKey"
                >
                  <collapse :show="item.show">
                    <span
                      class="item-title"
                      slot="header"
                      v-show="item.company.showText && item.roleName"
                      >{{ item.company.showText }} / {{ item.roleName }}</span
                    >
                    <div class="content-block-choose-profile" slot="body">
                      <ul class="box-ct edit-profile" v-show="isEdit">
                        <li>
                          <ul class="row lst-itm-info">
                            <li class="col-md-12 col-xl-6">
                              <um-web-select-autocomplete
                                v-model="item.company.value"
                                code="id"
                                label="name"
                                :displayText="
                                  $t('userProfile_experience_company')
                                "
                                :urlApi="constants.companyUrl"
                                :urlGetByIdApi="constants.companyByIdUrl"
                                :isRequired="true"
                                :isSubmitted="submitted"
                                @initText="
                                  (value) => {
                                    initText(
                                      value,
                                      item,
                                      'experienceList',
                                      'company'
                                    );
                                  }
                                "
                                @initShowText="
                                  (value) => {
                                    initShowText(
                                      value,
                                      item,
                                      'experienceList',
                                      'company'
                                    );
                                  }
                                "
                              />
                              <div
                                class="txt-mess ml-0 text-left"
                                v-if="
                                  submitted &&
                                  !$v.userProfile.experienceList.$each[index]
                                    .company.showText.required
                                "
                              >
                                {{ $t("userProfile_experience_company_req") }}
                              </div>
                            </li>
                            <li class="col-md-12 col-xl-6">
                              <div
                                class="custom-input"
                                :class="{
                                  'is-err':
                                    submitted &&
                                    !$v.userProfile.experienceList.$each[index]
                                      .roleName.required,
                                }"
                              >
                                <input
                                  type="text"
                                  id="iptvalue7"
                                  class="ipt"
                                  v-model="item.roleName"
                                />
                                <label for="iptvalue7" class="lbl">
                                  {{ $t("userProfile_experience_role") }}
                                  <span class="obl">*</span>
                                </label>
                              </div>
                              <div
                                class="txt-mess ml-0 text-left"
                                v-if="
                                  submitted &&
                                  !$v.userProfile.experienceList.$each[index]
                                    .roleName.required
                                "
                              >
                                {{ $t("userProfile_experience_role_req") }}
                              </div>
                            </li>
                            <li class="col-md-6 col-xl-3">
                              <div class="custom-input selbox">
                                <!-- {{item.startDate}} -->
                                <um-web-datetimepicker
                                  v-model="item.startDate"
                                  type="month"
                                  inputClass="ipt ipt-datetime"
                                  class="custom-date"
                                />
                                <label for="inputDateTimeStart" class="lbl">
                                  {{ $t("userProfile_experience_start") }}
                                </label>
                              </div>
                            </li>
                            <li
                              class="col-md-6 col-xl-3"
                              v-show="!item.isWorking"
                            >
                              <div class="custom-input selbox">
                                <um-web-datetimepicker
                                  v-model="item.endDate"
                                  type="month"
                                  inputClass="ipt ipt-datetime"
                                  class="custom-date"
                                />
                                <label for="inputDateTimeEnd" class="lbl">
                                  {{ $t("userProfile_experience_end") }}
                                </label>
                              </div>
                            </li>
                            <li
                              class="
                                col-md-12 col-xl-6
                                d-flex
                                align-items-center
                              "
                            >
                              <div
                                class="
                                  form-check form-check-inline
                                  checkbox-check
                                  mr-0
                                "
                              >
                                <input
                                  class="form-check-input"
                                  type="checkbox"
                                  value=""
                                  :id="item.renderKey"
                                  v-model="item.isWorking"
                                  @change="setCheckboxIsWorking(item)"
                                />
                                <label
                                  class="form-check-label"
                                  :for="item.renderKey"
                                >
                                  {{ $t("userProfile_experience_working") }}
                                </label>
                              </div>
                            </li>
                            <li class="col-md-12">
                              <div class="custom-input selbox">
                                <textarea
                                  class="textarea-ipt ipt"
                                  id="ipttextarea"
                                  v-model="item.address"
                                ></textarea>
                                <label for="ipttextarea" class="lbl">
                                  {{ $t("userProfile_experience_address") }}
                                </label>
                              </div>
                            </li>
                          </ul>
                        </li>
                      </ul>
                      <ul class="box-ct edit-profile" v-show="!isEdit">
                        <li>
                          <ul class="row lst-itm-info">
                            <li class="col-md-12 col-xl-6">
                              <div class="item-table-list-content">
                                <p class="txt-title">
                                  {{ $t("userProfile_experience_company") }}:
                                </p>
                                <p class="txt-content-table">
                                  {{ item.company.showText }}
                                </p>
                              </div>
                            </li>
                            <li class="col-md-12 col-xl-6">
                              <div class="item-table-list-content">
                                <p class="txt-title">
                                  {{ $t("userProfile_experience_role") }}:
                                </p>
                                <p class="txt-content-table">
                                  {{ item.roleName }}
                                </p>
                              </div>
                            </li>
                            <li class="col-md-12 col-xl-6">
                              <div class="item-table-list-content">
                                <p class="txt-title">
                                  {{ $t("userProfile_experience_start") }}:
                                </p>
                                <p class="txt-content-table">
                                  {{ item.startDate | moment("MM/YYYY") }}
                                </p>
                              </div>
                            </li>
                            <li class="col-md-12 col-xl-6">
                              <div
                                class="item-table-list-content"
                                v-show="!item.isWorking"
                              >
                                <p class="txt-title">
                                  {{ $t("userProfile_experience_end") }}:
                                </p>
                                <p class="txt-content-table">
                                  {{ item.endDate | moment("MM/YYYY") }}
                                </p>
                              </div>
                            </li>
                            <li class="col-md-12 col-xl-12">
                              <div class="item-table-list-content d-block">
                                <p class="txt-title">
                                  {{ $t("userProfile_experience_address") }}:
                                </p>
                                <p class="txt-content-table">
                                  {{ item.address }}
                                </p>
                              </div>
                            </li>
                            <li class="col-12 d-flex align-items-center">
                              <div
                                class="
                                  form-check form-check-inline
                                  checkbox-check
                                  mr-0
                                "
                              >
                                <input
                                  class="form-check-input"
                                  type="checkbox"
                                  value=""
                                  :disabled="isEdit"
                                  :checked="item.isWorking"
                                  :id="item.renderKey"
                                />
                                <label class="form-check-label">
                                  {{
                                    $t("userProfile_experience_working")
                                  }}</label
                                >
                              </div>
                            </li>
                          </ul>
                        </li>
                      </ul>
                      <div class="lst-btn d-flex">
                        <a
                          href="javascript:void(0);"
                          @click="deleteBlock(item, 'experienceList')"
                          class="btn-delete"
                          v-show="isEdit"
                          >{{ $t("delete") }}</a
                        >
                      </div>
                    </div>
                  </collapse>
                </div>
                <div class="add-qt">
                  <a
                    href="javascript:void(0);"
                    class="add-new-addr"
                    @click="addExperience"
                    v-show="isEdit"
                  >
                    <i class="plus">+</i>
                    {{ $t("userProfile_experience_add") }}
                  </a>
                </div>
              </div>
              <div
                class="group-box-choose-profile"
                v-show="isEdit || userProfile.certificationList.length > 0"
              >
                <h2 class="title-box-profile">
                  {{ $t("userProfile_certification") }}
                </h2>
                <div
                  class="content-group-box-profile"
                  v-for="(item, index) in userProfile.certificationList"
                  :key="item.renderKey"
                >
                  <div class="content-group-box-profile">
                    <collapse :show="item.show">
                      <span
                        class="item-title"
                        slot="header"
                        v-show="
                          item.certificationName && item.classification.showText
                        "
                      >
                        {{ item.certificationName }} /
                        {{ item.classification.showText }}
                      </span>
                      <div class="content-block-choose-profile" slot="body">
                        <ul class="box-ct edit-profile" v-show="isEdit">
                          <li>
                            <ul class="row lst-itm-info">
                              <li class="col-md-12 col-xl-6">
                                <div
                                  class="custom-input"
                                  :class="{
                                    'is-err':
                                      submitted &&
                                      !$v.userProfile.certificationList.$each[
                                        index
                                      ].certificationName.required,
                                  }"
                                >
                                  <input
                                    type="text"
                                    id="iptvalue8"
                                    class="ipt"
                                    v-model="item.certificationName"
                                    name="certificationName"
                                  />
                                  <label for="iptvalue8" class="lbl">
                                    {{ $t("userProfile_certification_name") }}
                                    <span class="obl">*</span>
                                  </label>
                                </div>
                                <div
                                  class="txt-mess ml-0 text-left"
                                  v-if="
                                    submitted &&
                                    !$v.userProfile.certificationList.$each[
                                      index
                                    ].certificationName.required
                                  "
                                >
                                  {{ $t("userProfile_certification_name_req") }}
                                </div>
                              </li>
                              <li class="col-md-12 col-xl-6">
                                <div
                                  class="custom-input"
                                  :class="{
                                    'is-err':
                                      submitted &&
                                      !$v.userProfile.certificationList.$each[
                                        index
                                      ].placeOfIssue.required,
                                  }"
                                >
                                  <input
                                    type="text"
                                    id="iptvalue9"
                                    class="ipt"
                                    name="placeOfIssue"
                                    v-model="item.placeOfIssue"
                                  />
                                  <label for="iptvalue9" class="lbl">
                                    {{
                                      $t(
                                        "userProfile_certification_placeofissue"
                                      )
                                    }}
                                    <span class="obl">*</span>
                                  </label>
                                </div>
                                <div
                                  class="txt-mess ml-0 text-left"
                                  v-if="
                                    submitted &&
                                    !$v.userProfile.certificationList.$each[
                                      index
                                    ].placeOfIssue.required
                                  "
                                >
                                  {{
                                    $t(
                                      "userProfile_certification_placeofissue_req"
                                    )
                                  }}
                                </div>
                              </li>
                              <li class="col-md-12 col-xl-6">
                                <div class="custom-input">
                                  <um-web-datetimepicker
                                    v-model="item.certificateDate"
                                    type="month"
                                    inputClass="ipt ipt-datetime"
                                    class="custom-date"
                                  />
                                  <label for="inputDateTimeEnd2" class="lbl">
                                    {{ $t("userProfile_certification_time") }}
                                  </label>
                                </div>
                              </li>
                              <li class="col-md-12 col-xl-6">
                                <um-web-select-autocomplete
                                  v-model="item.classification.value"
                                  code="id"
                                  label="title"
                                  :displayText="
                                    $t(
                                      'userProfile_certification_classification'
                                    )
                                  "
                                  :isRequired="true"
                                  :isSubmitted="submitted"
                                  :urlApi="constants.categoryUrl"
                                  :urlGetByIdApi="constants.categoryByIdUrl"
                                  :customParams="{
                                    code: constants.education_classification_Code,
                                  }"
                                  @initText="
                                    (value) => {
                                      initText(
                                        value,
                                        item,
                                        'certificationList',
                                        'classification'
                                      );
                                    }
                                  "
                                  @initShowText="
                                    (value) => {
                                      initShowText(
                                        value,
                                        item,
                                        'certificationList',
                                        'classification'
                                      );
                                    }
                                  "
                                />
                                <div
                                  class="txt-mess ml-0 text-left"
                                  v-if="
                                    submitted &&
                                    !$v.userProfile.certificationList.$each[
                                      index
                                    ].classification.showText.required
                                  "
                                >
                                  {{
                                    $t(
                                      "userProfile_certification_classification_req"
                                    )
                                  }}
                                </div>
                              </li>
                              <li class="col-md-12">
                                <h4 class="tit-child d-flex align-items-center">
                                  <span class="mr-3">
                                    {{
                                      $t("userProfile_certification_filenote")
                                    }}
                                  </span>
                                  <um-web-file-preview
                                    v-if="item.filePath"
                                    :fileUrl="item.filePath"
                                    :itemWidth="100"
                                    :itemHeight="100"
                                  />
                                  <img
                                    src="@/assets/images/icon-doc-upload.svg"
                                    alt=""
                                    @click="showUploadFile(index)"
                                  />
                                </h4>
                              </li>
                            </ul>
                          </li>
                        </ul>
                        <ul class="box-ct edit-profile" v-show="!isEdit">
                          <li>
                            <ul class="row lst-itm-info">
                              <li class="col-md-12 col-xl-6">
                                <div class="item-table-list-content">
                                  <p class="txt-title">
                                    {{ $t("userProfile_certification_name") }}:
                                  </p>
                                  <p class="txt-content-table">
                                    {{ item.certificationName }}
                                  </p>
                                </div>
                              </li>
                              <li class="col-md-12 col-xl-6">
                                <div class="item-table-list-content">
                                  <p class="txt-title">
                                    {{
                                      $t(
                                        "userProfile_certification_placeofissue"
                                      )
                                    }}:
                                  </p>
                                  <p class="txt-content-table">
                                    {{ item.placeOfIssue }}
                                  </p>
                                </div>
                              </li>
                              <li class="col-md-12 col-xl-6">
                                <div class="item-table-list-content">
                                  <p class="txt-title">
                                    {{ $t("userProfile_certification_time") }}:
                                  </p>
                                  <p class="txt-content-table">
                                    {{
                                      item.certificateDate | moment("MM/YYYY")
                                    }}
                                  </p>
                                </div>
                              </li>
                              <li class="col-md-12 col-xl-6">
                                <div class="item-table-list-content">
                                  <p class="txt-title">
                                    {{
                                      $t(
                                        "userProfile_certification_classification"
                                      )
                                    }}:
                                  </p>
                                  <p class="txt-content-table">
                                    {{ item.classification.showText }}
                                  </p>
                                </div>
                              </li>
                              <li class="col-md-12 col-xl-12">
                                <div class="item-table-list-content">
                                  <p class="txt-title">
                                    {{
                                      $t("userProfile_certification_filetext")
                                    }}:
                                  </p>
                                  <um-web-file-preview
                                    v-if="item.filePath"
                                    :fileUrl="item.filePath"
                                    :itemWidth="100"
                                    :itemHeight="100"
                                  />
                                </div>
                              </li>
                            </ul>
                          </li>
                        </ul>
                        <div class="lst-btn d-flex">
                          <a
                            href="javascript:void(0);"
                            class="btn-delete"
                            v-show="isEdit"
                            @click="deleteBlock(item, 'certificationList')"
                            >{{ $t("delete") }}</a
                          >
                        </div>
                      </div>
                    </collapse>
                  </div>
                </div>
                <div class="add-qt">
                  <a
                    href="javascript:void(0);"
                    class="add-new-addr"
                    v-show="isEdit"
                    @click="addCertification"
                  >
                    <i class="plus">+</i>
                    {{ $t("userProfile_certification_add") }}
                  </a>
                </div>
              </div>
              <div
                class="group-box-choose-profile"
                v-show="isEdit || userProfile.referenceList.length > 0"
              >
                <h2 class="title-box-profile">
                  {{ $t("userProfile_references") }}
                </h2>
                <div
                  class="content-group-box-profile"
                  v-for="(item, index) in userProfile.referenceList"
                  :key="item.renderKey"
                >
                  <collapse :show="item.show">
                    <span
                      class="item-title"
                      slot="header"
                      v-show="item.company.showText && item.fullName"
                      >{{ item.fullName }} / {{ item.company.showText }}</span
                    >
                    <div class="content-block-choose-profile" slot="body">
                      <ul class="box-ct edit-profile" v-show="isEdit">
                        <li>
                          <ul class="row lst-itm-info">
                            <li class="col-md-12 col-xl-6">
                              <div
                                class="custom-input"
                                :class="{
                                  'is-err':
                                    submitted &&
                                    !$v.userProfile.referenceList.$each[index]
                                      .fullName.required,
                                }"
                              >
                                <input
                                  type="text"
                                  id="iptvalue12"
                                  name="fullname"
                                  class="ipt"
                                  v-model="item.fullName"
                                />
                                <label for="iptvalue12" class="lbl">
                                  {{ $t("userProfile_references_fullname") }}
                                  <span class="obl">*</span>
                                </label>
                              </div>
                              <div
                                class="txt-mess ml-0 text-left"
                                v-if="
                                  submitted &&
                                  !$v.userProfile.referenceList.$each[index]
                                    .fullName.required
                                "
                              >
                                {{ $t("userProfile_references_fullname_req") }}
                              </div>
                            </li>
                            <li class="col-md-12 col-xl-6">
                              <um-web-select-autocomplete
                                v-model="item.company.value"
                                code="id"
                                label="name"
                                :displayText="
                                  $t('userProfile_references_company')
                                "
                                :urlApi="constants.companyUrl"
                                :urlGetByIdApi="constants.companyByIdUrl"
                                :isRequired="true"
                                :isSubmitted="submitted"
                                @initText="
                                  (value) => {
                                    initText(
                                      value,
                                      item,
                                      'referenceList',
                                      'company'
                                    );
                                  }
                                "
                                @initShowText="
                                  (value) => {
                                    initShowText(
                                      value,
                                      item,
                                      'referenceList',
                                      'company'
                                    );
                                  }
                                "
                              />
                              <div
                                class="txt-mess ml-0 text-left"
                                v-if="
                                  submitted &&
                                  !$v.userProfile.referenceList.$each[index]
                                    .company.required
                                "
                              >
                                {{ $t("userProfile_references_company_req") }}
                              </div>
                            </li>
                            <li class="col-md-12 col-xl-6">
                              <div
                                class="custom-input"
                                :class="{
                                  'is-err':
                                    submitted &&
                                    !$v.userProfile.referenceList.$each[index]
                                      .reference.required,
                                }"
                              >
                                <input
                                  type="text"
                                  id="iptvalue14"
                                  class="ipt"
                                  v-model="item.reference"
                                />
                                <label for="iptvalue14" class="lbl">
                                  {{
                                    $t("userProfile_references_relationship")
                                  }}
                                  <span class="obl">*</span>
                                </label>
                              </div>
                              <div
                                class="txt-mess ml-0 text-left"
                                v-if="
                                  submitted &&
                                  !$v.userProfile.referenceList.$each[index]
                                    .reference.required
                                "
                              >
                                {{
                                  $t("userProfile_references_relationship_req")
                                }}
                              </div>
                            </li>
                            <li class="col-md-12 col-xl-6">
                              <div
                                class="custom-input"
                                :class="{
                                  'is-err':
                                    submitted &&
                                    !$v.userProfile.referenceList.$each[index]
                                      .roleName.required,
                                }"
                              >
                                <input
                                  type="text"
                                  id="iptvalue15"
                                  class="ipt"
                                  v-model="item.roleName"
                                />
                                <label for="iptvalue15" class="lbl">
                                  {{ $t("userProfile_references_role") }}
                                  <span class="obl">*</span>
                                </label>
                              </div>
                              <div
                                class="txt-mess ml-0 text-left"
                                v-if="
                                  submitted &&
                                  !$v.userProfile.referenceList.$each[index]
                                    .roleName.required
                                "
                              >
                                {{ $t("userProfile_references_role_req") }}
                              </div>
                            </li>
                            <li class="col-md-12 col-xl-6">
                              <div class="custom-input">
                                <input
                                  type="text"
                                  id="iptvalue16"
                                  class="ipt"
                                  v-model="item.phoneNumber"
                                />
                                <label for="iptvalue16" class="lbl">
                                  {{ $t("userProfile_references_phone") }}
                                </label>
                              </div>
                            </li>
                          </ul>
                        </li>
                      </ul>
<ul class="box-ct edit-profile" v-show="!isEdit">
                                                
                                                <li>
                                                    <ul class="row lst-itm-info">
                                                        <li class="col-md-12 col-xl-6">
                                                            <div class="item-table-list-content">
                                                                <p class="txt-title"> {{ $t("userProfile_references_fullname") }}:</p>
                                                                <p class="txt-content-table">{{item.fullName}}</p>
                                                            </div>
                                                        </li>
                                                        <li class="col-md-12 col-xl-6">
                                                            <div class="item-table-list-content">
                                                                <p class="txt-title">{{ $t('userProfile_references_company')}}:</p>
                                                                <p class="txt-content-table">{{item.company.showText}}</p>
                                                            </div>
                                                        </li>
                                                        <li class="col-md-12 col-xl-6">
                                                            <div class="item-table-list-content">
                                                                <p class="txt-title">{{
                                    $t("userProfile_references_relationship")
                                  }}:</p>
                                                                <p class="txt-content-table">{{item.reference}}</p>
                                                            </div>
                                                        </li>
                                                        <li class="col-md-12 col-xl-6">
                                                            <div class="item-table-list-content">
                                                                <p class="txt-title">    {{ $t("userProfile_references_role") }}:</p>
                                                                <p class="txt-content-table">{{item.roleName}}</p>
                                                            </div>
                                                        </li>
                                                        <li class="col-md-12 col-xl-6">
                                                            <div class="item-table-list-content">
                                                                <p class="txt-title">  {{ $t("userProfile_references_phone") }}:</p>
                                                                <p class="txt-content-table">{{item.phoneNumber}}</p>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </li>
                                            </ul>
                      <div class="lst-btn d-flex">
                        <a
                          href="javascript:void(0);"
                          @click="deleteBlock(item, 'referenceList')"
                          class="btn-delete"
                          v-show="isEdit"
                          >{{ $t("delete") }}</a
                        >
                      </div>
                    </div>
                  </collapse>
                </div>
                <div class="add-qt">
                  <a
                    href="javascript:void(0);"
                    class="add-new-addr"
                    v-show="isEdit"
                    @click="addReference"
                  >
                    <i class="plus">+</i>
                    {{ $t("userProfile_references_add") }}
                  </a>
                </div>
              </div>
              <div class="group-box-choose-profile" v-show="isEdit">
                <h2 class="title-box-profile">{{ $t("userProfile_block") }}</h2>
                <ul class="box-ct edit-profile">
                  <li>
                    <ul class="list-form-check-profile">
                      <li class="form-check checkbox-check">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          value=""
                          id="checkbox-usefor"
                          :checked="isSelectAll"
                          @change="checkAll($event)"
                        />
                        <label class="form-check-label" for="checkbox-usefor"
                          >Tất cả khối chức năng</label
                        >
                      </li>

                      <li
                        class="form-check checkbox-check"
                        v-for="item in blockList"
                        :key="item.id"
                      >
                        <input
                          class="form-check-input"
                          type="checkbox"
                          :value="item.id"
                          v-model="userProfile.blockId"
                          :id="item.url"
                        />
                        <label class="form-check-label" :for="item.url">
                          {{ item.title }}
                        </label>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
                   <div class="group-box-choose-profile" v-show="!isEdit">
                <h2 class="title-box-profile">{{ $t("userProfile_block") }}</h2>
                <ul class="box-ct edit-profile">
                  <li>
                    <ul class="list-form-check-profile">


                      <li
                        class="form-check checkbox-check"
                        v-for="item in blockList"
                        :key="item.id"
                      >
                        <input
                          class="form-check-input"
                          type="checkbox"
                          :value="item.id"
                          v-model="userProfile.blockId"
                          :id="item.url"
                          :disabled="true"
                        />
                        <label class="form-check-label">
                          {{ item.title }}
                        </label>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
                   <div class="btn-area mt-5 mb-3" v-show="isEdit">
                       <button type="button"
                               class="btn btn-update"
                               @click="updateUserProfile">
                           {{ $t("update") }}
                       </button>
                       <button type="button" class="btn button-cancel-info" @click="goBack">
                           {{ $t("cancel") }}
                       </button>
                   </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <um-web-upload
      :width="700"
      @close="closeUploadAVatar"
      v-if="isUploadAvatar"
    />
    <um-web-upload
      :width="700"
      @close="closeUploadFile"
      v-if="isUploadFile"
      exts="doc;docx;pdf;"
    />
  </main>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import collapse from "../../components/common/collapse.vue";
import userProfileConstants from "../../constants/userProfileConstants";
export default {
  layout: "manager",
  components: { collapse },
  data() {
    return {
      checkedNames: [],
      userProfile: {
        id: null,
        userId: null,
        displayName: null,
        avatar: null,
        blockId: [],
        certificationList: [],
        educationList: [],
        experienceList: [],
        referenceList: [],
      },
      isUploadAvatar: false,
      isUploadFile: false,
      submitted: false,
      currentId: null,
      constants: userProfileConstants,
      blockList: [],
      isSelectAll: false,
      currentIndex: null,
      isEdit: false,
    };
  },
  watch: {
    "userProfile.blockId"(val) {
      if (val.length == this.blockList.length) {
        this.isSelectAll = true;
      } else {
        this.isSelectAll = false;
      }
    },
  },
  created() {
    if(this.$route.query.isEdit)
    {
      this.isEdit = true;
    }
    this.blockList = this.blocks.filter((x) => x.isProfile == true);
    if (this.$route.params && this.$route.params.id) {
      // get by
      this.id = this.$route.params.id;
      this.getUserInfo(this.id);
    }
  },
  validations: {
    userProfile: {
      displayName: {
        required,
      },
      educationList: {
        $each: {
          level: {
            required,
          },
          school: {
            showText: {
              required,
            },
          },
          classification: {
            showText: {
              required,
            },
          },
        },
      },
      experienceList: {
        $each: {
          roleName: {
            required,
          },
          company: {
            showText: {
              required,
            },
          },
        },
      },
      certificationList: {
        $each: {
          certificationName: {
            required,
          },
          placeOfIssue: {
            required,
          },
          classification: {
            showText: {
              required,
            },
          },
        },
      },
      referenceList: {
        $each: {
          fullName: {
            required,
          },
          roleName: {
            required,
          },
          reference: {
            required,
          },
          company: {
            showText: {
              required,
            },
          },
        },
      },

      // experienceList: [],
      // referenceList: [],
    },
  },
  methods: {
    getUserInfo(id) {
      var vm = this;
      const $loading = this.$loading.show();
      this.$axios
        .get(`${this.$api.USER_PROFILE_INFO}?id=${id}`)
        .then((data) => {
          $loading.hide();
          this.userProfile = data.result;
          // format date
          // this.userProfile.experienceList = data.result.experienceList.map(
          //   (element) => {
          //     return {
          //       ...element,
          //       startDate: element.startDate
          //         ? vm.$moment(element.startDate).format("YYYY-MM-DD")
          //         : null,
          //       endDate: element.startDate
          //         ? vm.$moment(element.endDate).format("YYYY-MM-DD")
          //         : null,
          //     };
          //   }
          // );
          // this.userProfile.certificationList =
          //   data.result.certificationList.map((element) => {
          //     return {
          //       ...element,
          //       certificateDate: element.certificateDate
          //         ? vm.$moment(element.certificateDate).format("YYYY-MM-DD")
          //         : null,
          //     };
          //   });
        })
        .catch((ex) => {
          $loading.hide();
        });
    },
    updateUserProfile() {
      this.submitted = true;
      this.$v.$touch();
      if (!this.$v.$invalid) {
        const $loading = this.$loading.show();
        $loading.hide();
        this.$axios
          .put(this.$api.USER_PROFILE_UPDATE, this.userProfile)
          .then((data) => {
            $loading.hide();
            this.$success(this.$t("noti_edit"));
            this.isEdit = false;
            // this.$router.push("/manager/profile");
          })
          .catch((ex) => {
            $loading.hide();
          });
      }
    },
    showUploadAvatar() {
      this.isUploadAvatar = true;
    },
    closeUploadAVatar(obj) {
      if (obj) {
        this.userProfile.avatar = obj.fileUrl;
      }
      this.isUploadAvatar = false;
    },
    showUploadFile(index) {
      this.currentIndex = index;
      this.isUploadFile = true;
    },
    closeUploadFile(obj) {
      if (obj) {
        this.userProfile.certificationList[this.currentIndex].filePath =
          obj.fileUrl;
      }
      this.currentIndex = null;
      this.isUploadFile = false;
    },
    goBack() {
      this.$router.push("/manager/profile");
    },
    addEducation() {
      const newData = {
        id: null,
        level: null,
        renderKey: this.uuidv4(),
        school: {
          value: null,
          text: null,
          showText: null,
        },
        major: {
          value: null,
          text: null,
          showText: null,
        },
        type: {
          value: null,
          text: null,
          showText: null,
        },
        classification: {
          value: null,
          text: null,
          showText: null,
        },
        isStudying: false,
        show: true,
      };
      this.userProfile.educationList.push(newData);
    },
    addExperience() {
      const newData = {
        id: null,
        renderKey: this.uuidv4(),
        roleName: null,
        company: {
          value: null,
          text: null,
          showText: null,
        },
        startDate: null,
        endDate: null,
        address: null,
        isWorking: false,
        show: true,
      };
      this.userProfile.experienceList.push(newData);
    },
    addCertification() {
      const newData = {
        id: null,
        certificationName: null,
        classification: {
          value: null,
          text: null,
          showText: null,
        },
        certificateDate: null,
        placeOfIssue: null,
        filePath: null,
        renderKey: this.uuidv4(),
        show: true,
      };
      this.userProfile.certificationList.push(newData);
    },
    addReference() {
      const newData = {
        id: null,
        fullName: null,
        company: {
          value: null,
          text: null,
          showText: null,
        },
        reference: null,
        phoneNumber: null,
        renderKey: this.uuidv4(),
        roleName: null,
        show: true,
      };
      this.userProfile.referenceList.push(newData);
    },
    deleteBlock(item, block) {
      this.$confirm(
        this.$t("confirm_delete"),
        this.$t("confirm"),
        this.$t("cancel")
      ).then(() => {
        var index = this.userProfile[block].indexOf(item);
        if (index > -1) {
          this.userProfile[block].splice(index, 1);
        }
      });
    },
    initText(value, item, block, object) {
      var index = this.userProfile[block].indexOf(item);
      if (index > -1) {
        //  this.userProfile[block][index][object].value = null;
        this.userProfile[block][index][object].text = value;
      }
    },
    initShowText(value, item, block, object) {
      var index = this.userProfile[block].indexOf(item);
      if (index > -1) {
        this.userProfile[block][index][object].showText = value;
      }
    },
    checkAll(event) {
      if (event.currentTarget.checked) {
        this.userProfile.blockId = this.blockList.map((e) => e.id);
      } else {
        this.userProfile.blockId = [];
      }
    },
    uuidv4() {
      return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
        (
          c ^
          (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
        ).toString(16)
      );
    },
    setCheckboxIsWorking(item) {
      var listUnselected = this.userProfile.experienceList.filter(
        (e) => e.renderKey != item.renderKey
      );
      if (listUnselected.length > 0) {
        listUnselected.forEach((element) => {
          element.isWorking = false;
        });
      }
    },
    setCheckboxIsStudying(item) {
      alert("có vào");
      var listUnselected = this.userProfile.educationList.filter(
        (e) => e.renderKey != item.renderKey
      );
      if (listUnselected.length > 0) {
        listUnselected.forEach((element) => {
          element.isStudying = false;
        });
      }
    },
    showEdit() {
      this.isEdit = true;
    },
  },
};
</script>

<style scoped>
</style>
