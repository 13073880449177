import axios from'../../plugins/axios'
import apiUrl from '../../constants/api'

export const getListPermission = ({ commit }) => {
    return new Promise((resolve, reject) => {
        return axios.get(apiUrl.PERMISSION_GET_LIST).then(response => {
            commit("PERMISSION_LIST", response.result);
             return resolve(response.result);
         }).catch(err => {
             return reject(err);
         });
     })
}

export const getListGroup = ({ commit }, data) => {
    return new Promise((resolve, reject) => {
        return axios.post(apiUrl.GROUP_GET_LIST, data).then(response => {
            commit("GROUP_LIST", response.result);
            return resolve(response.result);
        }).catch(err => {
            return reject(err);
        });
    })
}

export const getListModule = ({ commit }) => {
    return new Promise((resolve, reject) => {
        return axios.get(apiUrl.MODULE_GET_LIST).then(response => {
            commit("MODULE_LIST", response.result);
            return resolve(response.result);
        }).catch(err => {
            return reject(err);
        });
    })
}

export const getListModuleWithTree = ({ commit }, isSystem) => {
    return new Promise((resolve, reject) => {
        return axios.get(apiUrl.MODULE_GET_LIST_WITH_TREE, isSystem).then(response => {
            commit("MODULE_LIST_WITH_TREE", response.result);
            return resolve(response.result);
        }).catch(err => {
            return reject(err);
        });
    })
}
