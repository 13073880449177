import Vue from 'vue';
import { configPassword } from '../constants/enumConfig.js'
export const commonMixin = {
    computed: {
        appSettings() {
            return this.$store.state.system.appSettings;
        },
        currentUser() {
            var userInfo = null;
            if (this.$store && this.$store.state && this.$store.state.user && this.$store.state.user.info) { userInfo = Object.assign({}, this.$store.state.user.info); }
            else if (this.$store && this.$store.state && this.$store.state.auth && this.$store.state.auth.currentUser) { userInfo = Object.assign({}, this.$store.state.auth.currentUser); }

            if (!userInfo && typeof localStorage !== 'undefined' && this.$auth) {
                try {
                    const authSettings = this.$auth.getSetting()
                    const oidcUser = JSON.parse(
                        localStorage.getItem(
                            `oidc.user:${authSettings.authority}:${authSettings.client_id}`
                        ) || null
                    )
                    if (oidcUser) {
                        userInfo = oidcUser.profile
                    }
                } catch (e) {
                    // eslint-disable-next-line no-console
                    console.log(e)
                }
            }
            if (!userInfo && this.$config && this.$config.auth) {
                userInfo = JSON.parse(cookieS.getItem(`oidc.user:${this.$config.auth.authorityUrl}:${this.$config.auth.clientId}`) || null)
            }
            //if (this.$store && this.$store.state && this.$store.state.user && this.$store.state.user.info) {
            //    var _user = this.$store.state.bndUser.userInfo;
            //    userInfo.full_name = _user.full_name;
            //    if (!userInfo.avatar && _user.avatar) {
            //        userInfo.avatar = _user.avatar;
            //    }
            //    userInfo.language = _user.language;
            //}
            return userInfo;
        }
    },
    methods: {
        copyText(value) {
            navigator.clipboard.writeText(value);
        },
        isPermission(...permissionIds) {
            let userPermission = this.userPermissions;
            if (!userPermission || userPermission.length == 0)
                userPermission = this.appSettings.userPermission;
            let permission = userPermission.filter((item) => {
                return permissionIds.indexOf(item.code) >= 0;
            });
            return (permission && permission.length > 0);
        },
        checkPassword(inputtxt) {
            //var paswd = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,200}$/;
            var paswd = "/^[a-zA-Z0-9!@#$%^&*.]$/";
            var config = this.appSettings.configs.data.result.filter(o => o.typeName === 'Security');
            if (config.find(o => o.key === configPassword.NumberOfCharacters)) {
                var numberOfCharacters = config.find(o => o.key === configPassword.NumberOfCharacters).value === 'true'
                if (numberOfCharacters) {
                    paswd = paswd.replace('$/','{6,200}$/')
                }
            }
            if (config.find(o => o.key === configPassword.PasswordIncludeSpecialCharacter)) {
                var passwordIncludeSpecialCharacter = config.find(o => o.key === configPassword.PasswordIncludeSpecialCharacter).value === 'true'
                if (passwordIncludeSpecialCharacter) {
                    paswd = paswd.replace('/^', '/^(?=.*[!@#$%^&*])')
                }
            }
            if (config.find(o => o.key === configPassword.PasswordIncludeNumberCharacter)) {
                var passwordIncludeNumberCharacter = config.find(o => o.key === configPassword.PasswordIncludeNumberCharacter).value === 'true'
                if (passwordIncludeNumberCharacter) {
                    paswd = paswd.replace('/^', '/^(?=.*[0-9])')
                }
            }
            if (config.find(o => o.key === configPassword.PasswordIncludeUpercaseCharacter)) {
                var passwordIncludeNumberCharacter = config.find(o => o.key === configPassword.PasswordIncludeNumberCharacter).value === 'true'
                if (passwordIncludeNumberCharacter) {
                    paswd = paswd.replace('/^', '/^(?=.*[A-Z])')
                }
            }
            var rg = new RegExp(paswd.replaceAll('/',''))
            if (inputtxt.match(rg)) {
                return true;
            }
            else {
                return false;
            }
        }
    }
};
Vue.mixin(commonMixin);
