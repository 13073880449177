<template>
  <router-view></router-view>
</template>

<script>
export default {
  created() {
    this.setLanguage(this.currentLanguage || 'vi-vn');
  },
};
</script>
