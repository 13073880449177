<template>
  <main role="main">
    <section class="jumbotron text-center">
      <div class="container">
        <h1 class="jumbotron-heading">
          {{$t("Logout")}}
        </h1>
      </div>
    </section>

    <div class="album py-5 bg-light">
      <div class="container">
        <div class="row">
          <div class="col-md-4 col-centered">
            <form class="form-signin">
              <button
                class="btn btn-lg btn-primary btn-block mt-2 "
                type="button"
                @click="logout"
              >
                Đăng xuất thiết bị hiện tại
              </button>
              <button
                class="btn btn-lg btn-secondary btn-block mt-2 "
                type="button"
                @click="logoutAllDevice"
              >
                Đăng xuất tất cả các thiết bị
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>
<script>
import { mapActions } from 'vuex';
export default {
  layout: 'manager',
  methods: {
    ...mapActions('user', ['logoutAll']),
    logout() {
      this.$confirm(this.$t('confirm_logout')).then(() => {
          this.$auth.signOut();
      });
    },
    logoutAllDevice() {
      this.$confirm(this.$t('confirm_logout')).then(() => {
        const $loading = this.$loading.show();
        this.logoutAll().then((_) => {
          $loading.hide();
        });
      });
    },
  },
  created() {},
};
</script>
