<template>
    <div class="wrap" id="app" v-if="currentUser && !initializing">
        <auth />
        <left-menu />
        <div class="bg-sidebar-left" :class="{'open-bg':isOpenMenuMobile}"></div>
        <div class="content-bigContent-right">
            <!-- Main -->
            <router-view></router-view>
        </div>
    </div>
</template>
<script>
    import ui from "../store/ui";
    import leftMenu from '../components/layout/left-menu.vue'
    import { mapGetters, mapMutations, mapActions } from 'vuex';
    import auth from '../components/system/auth'
    export default {
        components: { auth, leftMenu},
        data() {
            return {
                initializing: true
            }
        },
        async created() {
            this.$auth.getProfile().then((profile) => {
                this.setUserInfo(profile);
            });
            this.initSystem().then(() => {
                this.appSettings.format = { dateTime: "HH:mm DD-MM-YYYY", date: "DD-MM-YYYY", time: "HH:mm" }
                this.initializing = false;
            });
        },
        methods: {
            ...mapMutations({ setUserInfo: 'user/updateInfo' }),
            ...mapActions('system', ['initSystem']),
        },
        computed: {
            ...mapGetters(['isOpenMenuMobile']),
        },
        beforeCreate() {
            if (!this.$store._modulesNamespaceMap["ui/"])
                this.$store.registerModule("ui", ui);
        }
    };
</script>
