import Vue from 'vue';
import Vue2Filters from 'vue2-filters';
import VueMoment from 'vue-moment';

Vue.use(Vue2Filters);
Vue.use(VueMoment);
// import languageMixin from '../mixin/language'
// Vue.filter('formatDate', (value) => {
//     console.log(Vue.mixin);
//     if (value) {
//         return moment(String(value)).format(dateFormat)
//     }
// });

// Vue.filter('formatFullDate', (value) => {
//     console.log(languageMixin);
//     if (value) {
//         return moment(String(value)).format(fullDateFormat)
//     }
// });
