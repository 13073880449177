import Vue from 'vue';
import VueRouter from 'vue-router';
import appDefault from '../layouts/default';
import appMananger from '../layouts/manager';
import appEmpty from '../layouts/empty';
import appIframe from '../layouts/iframe';
// import store from '../store/user/store'

const _import = require('./_import_sync');
import { SUPPORTED_LOCALES } from '../locales/supported-locales'

function getLocaleRegex() {
    let reg = ''
    SUPPORTED_LOCALES.forEach((locale, index) => {
        reg = `${reg}${locale.code}${index !== SUPPORTED_LOCALES.length - 1 ? '|' : ''}`
    })
    return `(${reg})`
}

Vue.use(VueRouter);

const routes = [{
    path: `/:lang${getLocaleRegex()}?`,
    component: appEmpty,
    children: [{
        path: 'manager',
        name: 'Mananger',
        component: appMananger,
        children: [{
            path: '',
            component: _import('manager/info/index'),
            meta: { requiresAuth: true }
        },
        {
            path: 'change-password',
            component: _import('manager/change-password'),
            meta: { requiresAuth: true }
        },
        {
            path: 'logout',
            component: _import('manager/logout'),
            meta: { requiresAuth: true }
        },
        {
            path: 'profile',
            component: _import('manager/profile'),
            meta: { requiresAuth: true }
        },
        {
            path: 'profile-edit/:id',
            component: _import('manager/profile-edit'),
            meta: { requiresAuth: true }
        },
        {
            path: 'email',
            component: _import('manager/email/index'),
            meta: { requiresAuth: true }
        },
        {
            path: 'phone',
            component: _import('manager/phone/index'),
            meta: { requiresAuth: true }
        },
        {
            path: 'address',
            component: _import('manager/address/index'),
            meta: { requiresAuth: true }
        },
        {
            path: 'address/:id',
            component: _import('manager/address/create'),
            meta: { requiresAuth: true }
        },
        {
            path: 'info',
            component: _import('manager/info/index'),
            meta: { requiresAuth: true }
        },
        {
            path: 'device',
            component: _import('manager/device/index'),
            meta: { requiresAuth: true }
        },
        {
            path: 'device/:id',
            component: _import('manager/device/detail'),
            meta: { requiresAuth: true }
        },
        {
            path: '2fa',
            component: _import('manager/2fa'),
            meta: { requiresAuth: true }
        }
        ]
    },
    {
        path: 'user',
        name: 'user',
        component: appMananger,
        children: [{
            path: '',
            component: _import('user/index'),
            meta: { requiresAuth: true }
        }
        ]
    },
    {
        path: 'password-config',
        name: 'user',
        component: appMananger,
        children: [{
            path: '',
            component: _import('configpassword/index'),
            meta: { requiresAuth: true }
        }
        ]
    },
    {
        path: 'client-redirect-uris',
        name: 'clientredirecturis',
        component: appMananger,
        children: [{
            path: '',
            component: _import('client-redirect-uris/index'),
            meta: { requiresAuth: true }
        }
        ]
    },
    {
        path: 'client-cors-origin',
        name: 'client-cors-origin',
        component: appMananger,
        children: [{
            path: '',
            component: _import('client-cors-origin/index'),
            meta: { requiresAuth: true }
        }
        ]
    },
    {
        path: 'role',
        name: 'role',
        component: appMananger,
        children: [{
            path: '',
            component: _import('role/index'),
            meta: { requiresAuth: true }
        }
        ]
    },
    {
        path: '',
        name: 'Home',
        component: appDefault,
        children: [{
            path: '/',
            component: _import('index')
        }, {
            path: 'error',
            component: _import('oidc/error')
        }, {
            path: 'login',
            component: _import('login')

        }, {
            path: 'forgot-password',
            component: _import('forgotpassword')
        },
        {
            path: 'callback',
            component: _import('oidc/callback')
        },
        //{
        //path: 'register',
        //component: _import('register')
        //},
        {
            path: 'logout',
            component: _import('oidc/logout')
        },
        {
            path: 'forgot-password',
            component: _import('forgotpassword')
        }, {
            path: 'silent-renew',
            component: _import('oidc/silent-renew')
        }, {
            path: 'captcha',
            component: _import('captcha')
        },
        ]
    },
    {
        path: '',
        name: 'Home',
        component: appIframe,
        children: [{
            path: 'address',
            props: { iframe: true },
            component: _import('manager/address/index'),
        },
        {
            path: 'address/:id',
            props: { iframe: true },
            component: _import('manager/address/create'),
        },
        {
            path: 'info',
            props: { iframe: true },
            component: _import('manager/info/index'),
        },
        {
            path: 'change-password',
            props: { iframe: true },
            component: _import('manager/change-password'),
        }]
    },
    {
        path: '*',
        component: _import('404'),
    }
    ]
}
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
});

// router.beforeEach((to, from, next) => {
//     if (to.matched.some(record => record.meta.requiresAuth)) {
//         try {
//             if (this.$cookie.get(this.$cookieConstants.ACCESS_TOKEN))
//                 return next();
//             router.push({ path: '/', query: { returnUrl: to.fullPath }, params: {} });
//         } catch (e) {
//             next();
//         }
//     } else {
//         next();
//     }
// });

export default router;
