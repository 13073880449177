<template>
    
    <div class="content-page home-page">
        <div class="container">
            <div class="main-ct">
                <div class="overview-info">
                    <div class="user-avt">
                        <avatar :src="currentUser.avatar" :username="currentUser.full_name || currentUser.name" :size="100" />
                    </div>
                    <strong class="txt-tit">Xin chào {{currentUser.full_name || currentUser.name}}</strong>
                    <span class="txt-desc">
                        {{ $t('welcome_desc') }}
                        <a href="javascript:void(0);" class="txt-link">
                            {{ $t('looking_for_more_information') }}
                        </a>
                    </span>
                </div>
                <div class="box-info">
                    <ul class="row lst-itm">
                        <template v-for="(item, index) in listMenu.sub">
                            <li class="col-md-6" :key="index" v-if="item.description">
                                <div class="box">
                                    <router-link :to="getUrl(item)" class="itm-link">
                                        <strong class="txt-tit">
                                            <span class="line-color line-brown"></span>{{item.title}}
                                        </strong>
                                        <p class="txt-desc">
                                            {{$t(item.description)}}
                                        </p>
                                    </router-link>
                                </div>
                            </li>
                        </template>
                    </ul>
                </div>
                <div class="other-info">
                    <div class="box">
                        <div class="box-head">
                            <h2>Bạn đang tìm thông tin khác</h2>
                        </div>
                        <ul class="box-ct lst-ct type2">
                            <li>
                                <a href="javascript:void(0);" class="itm-link">
                                    <img src="id/icon-compass.svg" alt="" />
                                    <span class="txt-tit">Tìm kiếm trong cài đặt</span>
                                </a>
                            </li>
                            <li>
                                <a href="javascript:void(0);" class="itm-link">
                                    <img src="id/icon-email-b.svg" alt="" />
                                    <span class="txt-tit">Hộp thư hỗ trợ</span>
                                </a>
                            </li>
                            <li>
                                <a href="javascript:void(0);" class="itm-link">
                                    <img src="id/icon-intro.svg" alt="" />
                                    <span class="txt-tit">Giới thiệu</span>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="txt-comment d-flex align-items-center">
                    <p>
                        Chỉ bạn mới thấy được các tùy chọn cài đặt của mình. Có thể bạn cũng nên xem lại các tùy chọn
                        cài đặt của mình trong các ứng dụng của hệ sinh thái, hoặc các dịch vụ của BnD mà bạn sử
                        dụng thường xuyên nhất. BnD giữ bí mật và bảo vệ an toàn dữ liệu của bạn. <a href="javascript:void(0);" class="txt-link">Tìm hiểu thêm</a>
                    </p>
                    <img src="id/img-security.png" alt="" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        layout: 'manager',
        middleware: 'authenticated',
        data() {
            return {
                isActiveEmail: false,
                isActivePhone: false
            };
        },
        computed: {
            listMenu() {
                if (this.$store.state.bndSystem)
                    return this.$store.state.bndSystem.menuList.find(k => k.id == 0);
                return [];
            }
        },
        methods: {
            getUrl(item) {
                if (item.url) return item.url;
                if (item.sub && item.sub[0]) return item.sub[0].url;
                return "";
            }
        },
        created() {
            const $loading = this.$loading.show();
            this.$axios
                .get(this.$api.ACCOUNT_GET_ACTIVE_DATA)
                .then((response) => {
                    if (response.data.isOk) {
                        this.isActiveEmail = response.data.isActiveEmail;
                        this.isActivePhone = response.data.isActivePhone;
                    } else {
                        this.$error(this.responseData.msg);
                    }
                    $loading.hide();
                })
                .catch((ex) => {
                    $loading.hide();
                });
        }
    };
</script>
