/* global window, document, gapi */
export function loadRecaptchaSdk(siteKey) {
    return new Promise((resolve) => {
        window.initRecaptcha = function () { // eslint-disable-line func-names
            grecaptcha.ready(function () {
                resolve('Recaptcha SDK Loaded!');
            });
        };
        (function (d, s, id) { // eslint-disable-line func-names
            const fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) { return resolve('Recaptcha SDK Loaded!'); }
            const js = d.createElement(s); js.id = id;
            js.src = '//www.google.com/recaptcha/api.js?render=' + siteKey + '&onload=initRecaptcha';
            fjs.parentNode.insertBefore(js, fjs);
        }(document, 'script', 'google-recaptcha-jssdk'));
    });
}

export function validate(siteKey, action = 'submit') {
    return new Promise((resolve) => {
        grecaptcha.ready(function () {
            grecaptcha.execute(siteKey, { action: action }).then(function (token) {
                return resolve(token);
            });
        });
    });
}
