<template>
    <div>
        <input :type="showPass ? 'text': 'password'" id="ipt-password" class="ipt" v-model="password" v-on:keydown.enter="$emit('enter')"  autocomplete="new-password">
        <label for="ipt-password" class="lbl" v-if="labelName">{{labelName}}</label>
        <i :class="{'icon-hiden': showPass, 'icon-show': !showPass}" @click="showPass = !showPass"></i>
    </div>
</template>

<script>
    export default {
        name: 'InputPass',
        props: {
            label: {
                type: String,
                default: ''
            },
            value: {

                type: String,
                default: ''
            }
        },

        data() {
            return {
                password: '',
                showPass: false,
                labelName: ''
            };
        },
        watch: {
            password(val) {
                this.$emit('input', val);
            }
        },
        created() {
            if (this.value != this.password) this.password = this.value;
            if (!this.label) this.labelName = this.$t('password');
            else this.labelName = this.label
        }
    };
</script>
