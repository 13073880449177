const userProfileConstants = {
    schoolUrl: '/v1.0/School/GetAllFromEs',
    companyUrl: '/v1.0/Company/GetAllFromEs',
    categoryUrl: '/v1.0/BlockCategory/GetAllFromEs',
    schoolByIdUrl: '/v1.0/School/GetById?id=',
    companyByIdUrl: '/v1.0/Company/GetById?id=',
    categoryByIdUrl: '/v1.0/BlockCategory/GetById?id=',
    education_classification_Code: 'classification', // Loại tốt nghiệp
    education_major_Code: 'training_industry', // Ngành đào tạo
    education_type_Code: 'course_category' // Loại khóa học/ Loại hình đào tạo
}
export default userProfileConstants;