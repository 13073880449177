import * as actions from './actions'

const store = {
    state: {
        authenticated: false,
        info: null,
    },
    mutations: {
        updateInfo(state, info) {
            state.info = info;
            state.authenticated = info != null;
        }
    },
    actions,
    getters: {}
};


export default store;