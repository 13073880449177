<template>
  <main class="content-page">
    <div class="container">
      <div class="main-ct">
        <div class="box type2">
          <div class="box-head">
            <h2>{{ $t("phone_manager") }}</h2>
          </div>
          <ul class="box-ct lst-ct lst-manager mt-2">
            <li
              v-for="item in listphone"
              :key="item.id"
              v-bind:class="{
                'is-verified': item.isConfirmed,
                'itm-txt': !item.isConfirmed,
              }"
            >
              <span class="itm-txt">{{ item.name }}</span>
              <div class="lst-btn d-flex ct-lst-btn">
                <a
                  href="javascript:void(0):"
                  @click="showVerifyPopup(item.name)"
                  v-show="!item.isConfirmed"
                  class="itm-link"
                  >{{ $t("phone_verify") }}</a
                >
                <a
                  href="javascript:void(0);"
                  class="btn-delete"
                  @click="showDelete(item)"
                  >{{ $t("delete") }}</a
                >
              </div>
            </li>
          </ul>
          <a
            class="add-new-addr mt-3"
            href="javascript:void(0):"
            @click="showCreatephone"
            ><i class="plus">+</i> {{ $t("add_phone") }}</a
          >
        </div>
      </div>
    </div>
    <modalcustom v-if="showCreate">
      <div class="modal fade popup show" slot="content">
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <!-- HTML của phần thêm phone mới -->
            <div class="modal-header justify-content-center">
              <h5 class="modal-title">{{ $t("add_phone") }}</h5>
            </div>
            <form autocomplete="on">
              <div class="modal-body type2">
                <div class="custom-input mt-3 mb-2">
                  <input
                    type="phone"
                    v-model="phone"
                    id="phone"
                    name="phone"
                    class="form-control"
                    :class="{ 'is-invalid': submitted && $v.phone.$error }"
                  />
                  <div
                    v-if="submitted && $v.phone.$error"
                    class="invalid-feedback"
                  >
                    <span v-if="!$v.phone.required">
                      {{ $t("phone_required") }}
                    </span>
                  </div>
                  <label for="addphone" class="lbl">
                    {{ $t("phone_add_lbl") }}
                  </label>
                </div>
                <span class="txt">{{ $t("phone_note") }}</span>
              </div>
            </form>
            <div class="modal-footer justify-content-around">
              <button
                type="button"
                class="btn btn-light"
                data-dismiss="modal"
                @click="showCreatephone"
              >
                {{ $t("phone_cancel") }}
              </button>
              <button
                type="button"
                class="btn btn-complete"
                @click="Createphone"
              >
                {{ $t("phone_save") }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </modalcustom>
    <modalcustom v-if="showVerify">
      <div class="modal fade popup show" slot="content">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <!-- HTML của phần xác thực phone vừa thêm -->
            <div class="modal-header justify-content-center">
              <h5 class="modal-title" id="exampleModalLongTitle">
                {{ $t("phone_verify_opt") }}
              </h5>
            </div>
            <div class="modal-body type2">
              <span class="txt"> {{ $t("phone_verify_note") }}</span>
              <form class="ipt-code">
                <v-otp-input
                  ref="otpInput"
                  input-classes="otp-input"
                  separator=""
                  :num-inputs="6"
                  :should-auto-focus="true"
                  :is-input-num="true"
                  @on-complete="handleOnComplete"
                />
              </form>
              <div class="mt-1">
                <span v-if="countSecond > 0">Gửi lại sau: </span>

                <em class="txt-time" v-if="countSecond > 0">
                  {{ countSecond }}
                </em>
                <button
                  type="button"
                  class="resend-code"
                  @click="sendOtp"
                  v-if="countSecond <= 0"
                >
                  {{ $t("phone_resend_code") }}
                </button>
              </div>
            </div>
            <div class="modal-footer justify-content-around">
              <button
                type="button"
                class="btn btn-light"
                @click="closeVerifyPopup"
              >
                {{ $t("phone_remind_later") }}
              </button>
              <button
                type="button"
                class="btn btn-complete"
                @click="activephone"
                v-show="otp && otp.length == 6"
              >
                {{ $t("phone_ccomplete") }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </modalcustom>
    <modalcustom v-if="showDeleteOTP">
      <div class="modal fade popup show" slot="content">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <!-- HTML của phần xác thực phone vừa thêm -->
            <div class="modal-header justify-content-center">
              <h5 class="modal-title" id="exampleModalLongTitle">
                {{ $t("phone_verify_opt") }}
              </h5>
            </div>
            <div class="modal-body type2">
              <span class="txt"> {{ $t("phone_verify_note") }}</span>
              <form class="ipt-code">
                <v-otp-input
                  ref="otpInput"
                  input-classes="otp-input"
                  separator=""
                  :num-inputs="6"
                  :should-auto-focus="true"
                  :is-input-num="true"
                  @on-complete="handleOnComplete"
                />
              </form>
              <div class="mt-1">
                <span v-if="countSecond > 0">Gửi lại sau: </span>

                <em class="txt-time" v-if="countSecond > 0">
                  {{ countSecond }}
                </em>
                <button
                  type="button"
                  class="resend-code"
                  @click="sendOtp"
                  v-if="countSecond <= 0"
                >
                  {{ $t("phone_resend_code") }}
                </button>
              </div>
            </div>
            <div class="modal-footer justify-content-around">
              <button
                type="button"
                class="btn btn-light"
                @click="closeDeletePopup"
              >
                {{ $t("phone_remind_later") }}
              </button>
              <button type="button" class="btn btn-complete" @click="deleteOtp" v-show="otp && otp.length == 6">
                {{ $t("phone_ccomplete") }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </modalcustom>
  </main>
</template>

<script>
const phoneConst = 1;
import { required } from "vuelidate/lib/validators";
import modalcustom from "../../../components/common/modalcustom.vue";
export default {
  components: { modalcustom },
  data() {
    return {
      isLoaded: false,
      listphone: [],
      showVerify: false,
      showCreate: false,
      phone: null,
      otp: null,
      submitted: false,
      countSecond: 90,
      showDeleteOTP: false,
      item: {},
    };
  },
  validations: {
    // otp: {
    //   required,
    // },
    phone: {
      required,
    },
  },
  created() {
    this.getPhone();
  },
  methods: {
    getPhone() {
      const $loading = this.$loading.show();
      this.$axios
        .get(`${this.$api.EMAIL_PHONE_GET_ALL}?type=1`)
        .then((data) => {
          this.listphone = data.result;
          $loading.hide();
        })
        .catch((ex) => {
          $loading.hide();
        });
    },
    Createphone() {
      this.submitted = true;
      this.$v.$touch();
      if (!this.$v.$invalid) {
        const $loading = this.$loading.show();
        // Check phone/phone đã có hay chưa?
        this.$axios
          .get(
            `${this.$api.EMAIL_PHONE_CHECKEXISTED}?userPhoneEmailName=${this.phone}`
          )
          .then((data) => {
            $loading.hide();
            if (data.result) {
              this.$error(this.$t("phone_already"));
            } else {
              // Tạo mới
              var formData = {
                type: phoneConst,
                Name: this.phone,
                isConfirmed: false,
              };
              this.$axios
                .post(this.$api.EMAIL_PHONE_CREATE, formData)
                .then((data) => {
                  this.getPhone();
                  this.$success(this.$t("phone_success"));
                })
                .catch((ex) => {
                  $loading.hide();
                });
              this.showCreate = false;
              this.showVerifyPopup();
            }
          })
          .catch((ex) => {
            $loading.hide();
          });
      }
    },
    closeVerifyPopup() {
      this.showVerify = false;
    },
    showVerifyPopup(name) {
      if (name) {
        this.phone = name;
      }
      this.showVerify = !this.showVerify;
      this.sendOtp();
    },
    showCreatephone() {
      this.showCreate = !this.showCreate;
    },
    sendOtp() {
      const $loading = this.$loading.show();
      this.$axios
        .get(`${this.$api.EMAIL_PHONE_SEND_OTP}?EmailPhoneName=${this.phone}`)
        .then((data) => {
          $loading.hide();
          this.countSecond = data.result.times;
          this.countDownTimer();
        });
    },
    countDownTimer() {
      if (this.countSecond > 0) {
        setTimeout(() => {
          this.countSecond -= 1;
          this.countDownTimer();
        }, 1000);
      }
    },
    activephone() {
      const $loading = this.$loading.show();
      var formData = {
        UserPhoneEmailName: this.phone,
        Otp: this.otp,
      };
      this.$axios
        .post(this.$api.EMAIL_PHONE_ACTIVE_PHONE, formData)
        .then((data) => {
          $loading.hide();
          this.$success(this.$t("phone_success_verify"));
          this.closeVerifyPopup();
          this.getPhone();
        })
        .catch((ex) => {
          this.$error(ex.response.data.responseException.exceptionMessage);
          $loading.hide();
        });
    },
    handleOnComplete(value) {
      this.otp = value;
    },
    handleOnComplete(value) {
      this.otp = value;
    },
    showDelete(item) {
      this.$confirm(
        this.$t("confirm_delete"),
        this.$t("confirm"),
        this.$t("confirm_cancel")
      ).then(() => {
        if (item.isConfirmed) {
          this.showDeleteOTP = true;
          this.phone = item.name;
          this.item = item;
          this.sendOtp();
        } else {
          this.deleteItem(item);
        }
      });
    },
    deleteItem(item) {
      const $loading = this.$loading.show();
      this.$axios
        .delete(`${this.$api.EMAIL_PHONE_DELETE}?userPhoneEmailId=${item.id}`)
        .then((response) => {
          $loading.hide();
          this.$success(this.$t("useremail_noti_delete"));
          this.getPhone();
        })
        .catch((ex) => {
          $loading.hide();
        });
    },
    deleteOtp() {
      const $loading = this.$loading.show();
      var formData = {
        PhoneEmailName: this.phone,
        Otp: this.otp,
      };
      this.$axios
        .post(this.$api.EMAIL_PHONE_DELETE_OTP, formData)
        .then((data) => {
          vm.getPhone();
          $loading.hide();
          this.$success(this.$t("useremail_noti_delete"));
          this.showDeleteOTP = false;
        })
        .catch((ex) => {
          this.$error(ex.response.data.responseException.exceptionMessage);
          $loading.hide();
        });
    },
    closeDeletePopup() {
      this.showDeleteOTP = false;
    },
  },
};
</script>

<style scoped>
.popup .modal-dialog {
  width: 400px;
  height: 304px;
}
</style>
