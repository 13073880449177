<template>
    <main class="content-page">
        <div class="container">
            <div class="main-ct">
                <div class="box identity-box">
                    <div class="box-head">
                        <h2>{{$t('notification-config')}}</h2>
                    </div>
                    <div class="box-ct lst-ct lst-expand">
                        <ul class="box-child">
                            <!-- [D] Thêm class "on" vào class "itm-expand" để hiển thị nội dung -->
                            <li class="itm-expand" :class="{'on': expandId == index}" v-for="(m, index) in moduleConfig" :key="index">
                                <div class="tit-expand" @click="expand(index)">
                                    <button type="button" class="btn-expand">
                                        <strong>{{m.moduleName}}</strong>
                                        <!--<span class="txt-desc">Lorem ipsum dolor sit am</span>-->
                                    </button>
                                </div>
                                <div class="ct-expand">
                                    <ul class="lst-expand">
                                        <li class="itm d-flex flex-column ">
                                            <strong class="txt-tit">Nơi bạn nhận các thông báo</strong>
                                            <div class="txt-detail">
                                                <img src="id/icon-email-l.svg" alt="" class="img" />
                                                <span class="txt">Nhận qua Email</span>
                                                <div class="switch-btn">
                                                    <label class="switch">
                                                        <input type="checkbox" :checked="getCheckGroup(m, 'email')" value="email" v-model="m.notificationGroup">
                                                        <span class="slider"></span>
                                                    </label>
                                                </div>
                                            </div>
                                        </li>
                                        <li class="itm d-flex align-items-center">
                                            <div class="txt-detail">
                                                <img src="id/icon-box.svg" alt="" class="img" />
                                                <span class="txt">Thông báo trên Web</span>
                                                <div class="switch-btn">
                                                    <label class="switch">
                                                        <input type="checkbox" :checked="getCheckGroup(m, 'web')" value="web" v-model="m.notificationGroup">
                                                        <span class="slider"></span>
                                                    </label>
                                                </div>
                                            </div>
                                        </li>
                                        <li class="itm d-flex align-items-center">
                                            <div class="txt-detail">
                                                <img src="id/icon-box.svg" alt="" class="img" />
                                                <span class="txt">Thông báo trên App</span>
                                                <div class="switch-btn">
                                                    <label class="switch">
                                                        <input type="checkbox" :checked="getCheckGroup(m, 'app')" value="app" v-model="m.notificationGroup">
                                                        <span class="slider"></span>
                                                    </label>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </li>
                        </ul>
                        <div class="btn-area mt-4 pt-3">
                            <button type="button" class="btn btn-update" @click="save">{{$t('update')}}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>

<script>
    export default {
        data() {
            return {
                expandId: 0,
                moduleConfig: null
            };
        },
        methods: {
            save() {
                const $loading = this.$loading.show();
                this.$axios.post(this.$api.USER_NOTIFICATION_SAVE_CONFIG, this.moduleConfig)
                    .then(() => {
                        $loading.hide();
                        this.$success(this.$t('info_noti_info_success'));
                    })
                    .catch((ex) => {
                        $loading.hide();
                    });
            },
            getConfig() {
                const $loading = this.$loading.show();
                this.$axios.get(this.$api.USER_NOTIFICATION_GET_CONFIG)
                    .then((res) => {
                        $loading.hide();
                        this.moduleConfig = res.result;
                    })
                    .catch((ex) => {
                        $loading.hide();
                    });
            },
            getCheckGroup(item, group) {
                if (!item.notificationGroup)
                    item.notificationGroup = [];
                return item.notificationGroup.includes(group);
            },
            expand(id) {
                if (this.expandId == id)
                    this.expandId = -1;
                else
                    this.expandId = id;
            }
        },
        created() {
            this.getConfig();
        }
    };
</script>

<style>
</style>
