<template>
    <div>
        <div id="logout_iframe">
        </div>
    </div>
</template>
<script type="text/javascript">
    export default {
        layout: 'empty',
        async created() {
            //let referrer = new URL(document.referrer);
            //window.location = referrer.origin + "/#/oidc/logout";
            //return;

            const query = window.location.search;
            var logoutIdQuery = ''
            if (query && query.toLowerCase().indexOf('?logoutid=') == 0) {
                logoutIdQuery = this.getParameterByName('logoutId');
            }

            const response = await fetch(this.$api.ACCOUNT_LOGOUT + '?logoutId=' + logoutIdQuery, {
                credentials: 'include',
            });
            const data = await response.json();

            if (data.signOutIFrameUrl) {
                const iframe = document.createElement('iframe');
                iframe.width = 0;
                iframe.height = 0;
                iframe.class = 'signout';
                iframe.src = data.signOutIFrameUrl;
                document.getElementById('logout_iframe').appendChild(iframe);
            }
            try {
                localStorage.clear();
                (function () {
                    var cookies = document.cookie.split("; ");
                    for (var c = 0; c < cookies.length; c++) {
                        var d = window.location.hostname.split(".");
                        while (d.length > 0) {
                            var cookieBase = encodeURIComponent(cookies[c].split(";")[0].split("=")[0]) + '=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=' + d.join('.') + ' ;path=';
                            var p = location.pathname.split('/');
                            document.cookie = cookieBase + '/';
                            while (p.length > 0) {
                                document.cookie = cookieBase + p.join('/');
                                p.pop();
                            };
                            d.shift();
                        }
                    }
                })();
            } catch {

            }

            if (data.postLogoutRedirectUri) {
                window.location = data.postLogoutRedirectUri;
            } else {
                try {
                    let referrer = new URL(document.referrer);
                    if (window.location.hostname != referrer.hostname) {
                        window.location = referrer.origin + "/oidc/logout";
                    }
                    else
                        this.$router.push('/');
                } catch {
                    this.$router.push('/');
                }
            }
        },
        methods: {
            getParameterByName(name, url = window.location.href) {
                name = name.replace(/[\[\]]/g, '\\$&');
                var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
                    results = regex.exec(url);
                if (!results) return null;
                if (!results[2]) return '';
                return decodeURIComponent(results[2].replace(/\+/g, ' '));
            }
        }
    };
</script>
