import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)
const store = {
    namespaced: true,
    state: {
        currentUser: null,
        userPermission: null
    },
    mutations: {
        setCurrentUser: (state, data) => {
            state.currentUser = data;
        },
        setUserPermission: (state, data) => {
            state.userPermission = data;
        },
    },
    actions: {
    },
    getters: {
        currentUser: state => state.currentUser,
        userPermission: state => state.userPermission
    }
}
export default store;