<template>
  <div
    class="list-navigtion-item"
    v-if="total > pageSize && showPager"
  >
    <a
      href="javascript:;"
      class="item-navigation navigation-left"
      v-if="showNextPrev"
      :class="{'disabled-item' : pageIndex <= 1}"
      @click="changePage(pageIndex - 1)"
    >

      <svg
        width="7"
        height="12"
        viewBox="0 0 7 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.8373 11.8402C7.05422 11.6273 7.05426 11.2819 6.83726 11.0689L1.67456 6.00013L6.8373 0.931146C7.05422 0.718164 7.05426 0.372782 6.83726 0.159764C6.6203 -0.0532546 6.26856 -0.0532546 6.05159 0.159764L0.496038 5.61446C0.391853 5.71675 0.333334 5.85547 0.333334 6.00013C0.333334 6.14478 0.391889 6.28355 0.496074 6.3858L6.05163 11.8402C6.26855 12.0533 6.62033 12.0533 6.8373 11.8402Z"
          fill="#414B5B"
        ></path>
      </svg>

    </a>
    <template v-if="showNumber">
      <a
        v-for="(item, index) in items"
        @click="changePage(item.value)"
        :key="index"
        class="item-navigation"
        :class="{'active': pageIndex === item.value}"
      >
        {{ item.text }}
      </a>
    </template>
    <a
      href="javascript:;"
      class="item-navigation navigation-right"
      v-if="showNextPrev"
      :class="{'disabled-item': pageIndex >= totalPage}"
      @click="changePage(totalPage)"
    >

      <svg
        width="7"
        height="12"
        viewBox="0 0 7 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0.494735 11.8402C0.27781 11.6273 0.277772 11.2819 0.494772 11.0689L5.65748 6.00013L0.494735 0.931146C0.277809 0.718164 0.277772 0.372782 0.494772 0.159764C0.711735 -0.0532546 1.06348 -0.0532546 1.28044 0.159764L6.83599 5.61446C6.94018 5.71675 6.9987 5.85547 6.9987 6.00013C6.9987 6.14478 6.94014 6.28355 6.83596 6.3858L1.2804 11.8402C1.06348 12.0533 0.711698 12.0533 0.494735 11.8402Z"
          fill="#414B5B"
        ></path>
      </svg>

    </a>
  </div>
</template>
<style scoped>
.disabled {
  opacity: 0.5;
  pointer-events: none;
}
</style>
<script>
export default {
  name: "Pager",
  props: {
    total: {
      type: Number,
      default: 0,
      validator: (val) => val >= 0,
    },
    pageIndex: {
      type: Number,
      default: 1,
      validator: (val) => val >= 1,
    },
    pageCount: {
      type: Number,
      default: 7,
      validator(val) {
        return val >= 5 && val <= 21 && val % 2 !== 0;
      },
    },
    pageSize: {
      type: Number,
      default: 20,
    },
    showNextPrev: {
      type: Boolean,
      default: true,
    },
    showFirstLast: {
      type: Boolean,
      default: false,
    },
    showDetailText: {
      type: Boolean,
      default: false,
    },
    showNumber: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    showPager() {
      return this.totalPage > 1;
    },
    itemCount() {
      if (this.pageSize > this.total) return this.total;
      else return this.pageSize;
    },
    prevCls() {
      return (
        "kt-datatable__pager-link kt-datatable__pager-link--prev " +
        (this.pageIndex <= 1
          ? "kt-datatable__pager-link--disabled disabled"
          : "")
      );
    },
    nextCls() {
      return (
        "kt-datatable__pager-link kt-datatable__pager-link--next " +
        (this.pageIndex >= this.totalPage
          ? "kt-datatable__pager-link--disabled disabled"
          : "")
      );
    },
    showPageCount() {
      return this.pageCount - 2;
    },
    totalPage() {
      return Math.ceil(this.total / this.pageSize);
    },
    items() {
      if (this.total === 0) return [];
      const showPageCount = this.showPageCount;
      const arr = [];
      const start = 1;
      const end = this.totalPage;

      if (end <= showPageCount + 2) {
        for (let i = start; i <= end; i++) {
          arr.push({ text: i, value: i });
        }
        return arr;
      }
      arr.push({ text: start, value: start });
      if (this.pageIndex - start >= showPageCount - 1) {
        const go = this.pageIndex - showPageCount;
        arr.push({
          text: "...",
          value: go < 1 ? 1 : go,
        });
      }

      let listStart = this.pageIndex - Math.floor(showPageCount / 2);
      if (listStart <= 1) listStart = 2;
      let listEnd = listStart + showPageCount - 1;
      if (listEnd >= end) listEnd = end - 1;
      listStart = listEnd - showPageCount + 1;

      for (let i = listStart; i <= listEnd; i++) {
        arr.push({ text: i, value: i });
      }

      if (end - this.pageIndex >= showPageCount - 1) {
        const go = this.pageIndex + showPageCount;
        arr.push({
          text: "...",
          value: go > end ? end : go,
        });
      }
      arr.push({ text: end, value: end });
      return arr;
    },
  },
  methods: {
    changePage(page) {
      this.$emit("update:pageIndex", page);
      this.$emit("change", page);
    },
  },
};
</script>

<style scoped>
.item-navigation {
  cursor: pointer;
}
</style>
