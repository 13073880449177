import Vue from 'vue';
import VueI18n from 'vue-i18n';
import en from '@/locales/en.json';
import vi from '@/locales/vi.json';

Vue.use(VueI18n);

const messages = { 'vi-vn': vi, 'en-us': en };
export const i18n = new VueI18n({
  locale: 'vi-vn',
  messages,
});
