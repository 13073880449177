<template>
  <div class="content-popup-page">
    <div class="main-popup-page">
      <div class="main-popup-top">
        <h2 class="name-popup">Phân quyền</h2>
        <div class="block-user-new-page">
          <div class="block-add-edit-page">
            <div class="form-add-group-permission">
              <span class="txt-label">Chọn nhóm quyền</span>
              <div class="content-fomr-add-permission">
                <div class="content-choose-option-dvc">
                     <select class="ct-select"
                v-if="listRole && listRole.length > 0"
                v-model="currentRoleId"
              
                @change="getRolePermission(currentRoleId)"
            >
                <option v-for="role in listRole" :key="'role_' + role.id" :value="role.id">
                    {{ role.roleName }}
                </option>
            </select>
                
                  <span class="icon-slect">
                    <svg
                      width="9"
                      height="5"
                      viewBox="0 0 9 5"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8.88018 0.122028C8.72044 -0.0406667 8.46141 -0.0406945 8.30164 0.122056L4.5001 3.99408L0.698359 0.122028C0.538623 -0.0406667 0.279586 -0.0406945 0.119823 0.122056C-0.0399409 0.284778 -0.0399409 0.548583 0.119823 0.711306L4.21084 4.87797C4.28756 4.95611 4.39161 5 4.5001 5C4.60859 5 4.71266 4.95608 4.78935 4.87794L8.88015 0.711278C9.03994 0.548583 9.03994 0.28475 8.88018 0.122028Z"
                        fill="#414B5B"
                      ></path>
                    </svg>
                  </span>
                </div>
                <div class="lst-choose-item-permission">
                  <div class="item-permission">
                    <div class="box-check-type">
                      <input type="checkbox" id="check-4" v-model="selectAll" />
                      <label for="check-4">
                        <span class="check">
                          <img src="@/assets/img-fix/icon-check.svg" alt="" />
                        </span>
                        <span class="checked">
                          <img
                            src="@/assets/img-fix/icon-check-active.svg"
                            alt=""
                          />
                        </span>
                        <span class="txt"
                          >Chọn tất cả quyền đang có (bên dưới)</span
                        >
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="wp-ht-group-permission">
                  <template v-for="(group,indx) in memberPermission">
              <div class="item-ht-group-permission"  v-if="group.permission && group.permission.length > 0"
                    :key="'g_' + group.id">
                <div class="title-ht-group-permission">
                  <p> {{ group.name }}</p>
                </div>
                <div class="content-ht-group-permission">
                  <div class="lst-choose-item-permission">
                    <div class="item-permission"  v-for="(per,index) in group.permission"
                            :key="'p_' + per.code">
                      <div class="box-check-type">
                        <input type="checkbox" :id="`check-${indx}-${index}`"  v-model="per.isSelect" />
                        <label :for="`check-${indx}-${index}`">
                          <span class="check">
                            <img src="@/assets/img-fix/icon-check.svg" alt="" />
                          </span>
                          <span class="checked">
                            <img
                              src="@/assets/img-fix/icon-check-active.svg"
                              alt=""
                            />
                          </span>
                          <span class="txt"> {{ per.name }}</span>
                        </label>
                      </div>
                    </div>
              
                  </div>
                </div>
              </div>
                  </template>
            </div>
            <div class="block-save-group-permission">
              <div class="form-add-group-permission">
                <span class="txt-label">Lưu lại nhóm quyền trên</span>
                <div class="content-fomr-add-permission">
                  <input type="text" v-model="roleName" />
                </div>
                <p class="txt-small">
                  Đặt tên cho nhóm mà bạn đã phân các quyền ở trên. Nhóm này sẽ
                  được lưu lại và giúp bạn phân quyền nhanh chóng hơn cho các
                  người dùng khác.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="bottom-popup-action">
        <div class="left-bottom-popup-action">
          <div class="btn-back-page">
            <a href="javascript:;" @click="$emit('back')">
              <span class="icon">
                <svg
                  width="22"
                  height="15"
                  viewBox="0 0 22 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0.292786 8.17433L7.29286 14.7368C7.48146 14.9076 7.73406 15.0021 7.99626 15C8.25846 14.9978 8.50928 14.8992 8.69469 14.7254C8.8801 14.5516 8.98527 14.3165 8.98755 14.0706C8.98982 13.8248 8.88903 13.588 8.70687 13.4112L3.41382 8.44902L21 8.44902C21.2652 8.44902 21.5196 8.35025 21.7071 8.17443C21.8946 7.99862 22 7.76016 22 7.51152C22 7.26288 21.8946 7.02442 21.7071 6.84861C21.5196 6.67279 21.2652 6.57402 21 6.57402L3.41382 6.57402L8.70687 1.61183C8.80238 1.52535 8.87857 1.4219 8.93098 1.30753C8.98338 1.19315 9.01097 1.07013 9.01212 0.945647C9.01328 0.821167 8.98798 0.697718 8.93769 0.582503C8.88741 0.467288 8.81316 0.362613 8.71927 0.274588C8.62537 0.186563 8.51372 0.116951 8.39082 0.0698125C8.26792 0.0226734 8.13624 -0.00104549 8.00346 3.59697e-05C7.87068 0.00111742 7.73946 0.0269801 7.61746 0.0761134C7.49545 0.125248 7.38511 0.196666 7.29286 0.286207L0.292786 6.84871C0.105313 7.02451 -1.23095e-06 7.26293 -1.25269e-06 7.51152C-1.27442e-06 7.76011 0.105313 7.99852 0.292786 8.17433Z"
                    fill="#414B5B"
                  />
                </svg>
              </span>
              <span class="txt">Thông tin</span>
            </a>
          </div>
        </div>
        <div class="right-bottom-popup-action">
          <div class="btn-save">
            <a href="javascript:;" @click="updatePermission" class="btn-bg-linear"> Hoàn thành </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Permission",
  props: ["memberId", "roleId"],
  data() {
    return {
      memberPermission: [],
      listRole: [],
      selectAll: false,
      currentRoleId: 0,
      roleName:""
    };
  },
  watch: {
    selectAll(val) {
      if (val) {
        for (var group of this.memberPermission) {
          for (var per of group.permission) {
            per.isSelect = true;
          }
        }
      }
    },
    currentRoleId(val) {
      if (val > 0) {
        this.selectAll = false;
      }
    },
  },
  created() {
    this.currentRoleId = this.roleId;
    this.getListRole();
    this.getUserPermission();
  },
  methods: {
    getUserPermission() {
      this.$axios
        .get(this.$api.USER_GET_LIST_PERMISSION_BY_ID, {
          params: { UserId: this.memberId },
        })
        .then((res) => {
          this.memberPermission = res.data.result;
        })
        .catch((err) => {
          return this.$message(err.message, "error");
        });
    },
    getRolePermission(id) {
      this.currentRoleId = id;
      this.$axios
        .get(this.$api.ROLE_GET_LIST_PERMISSION_BY_ID, {
          params: { roleId: id },
        })
        .then((res) => {
          this.memberPermission = res.data.result;
        })
        .catch((err) => {
          return this.$message(err.message, "error");
        });
    },
    updatePermission() {
      if (this.currentRoleId <= 0) {
        return this.$message("Vai trò bắt buộc phải chọn!", "error");
      }
      let objPermission = [];
      for (var group of this.memberPermission) {
        for (var per of group.permission) {
          if (per.isSelect) objPermission.push(per);
        }
      }
      let loading = this.$loading.show();
      let objSave= {
  roleId: this.currentRoleId,
            UserId: this.memberId,
            UserPermission: JSON.stringify(objPermission),
            roleName:this.roleName
      }
      this.$axios
        .post(this.$api.USER_UPDATE_PERMISSION,objSave)
        .then(() => {
          loading.hide();
          this.$message("Cập nhật thành công!");
        })
        .catch((err) => {
          loading.hide();
          return this.$message(err.message, "error");
        });
    },
    getListRole() {
      this.$axios
        .get(this.$api.ROLE_GET_LIST)
        .then((res) => {
          this.listRole = res.data.result;
        })
        .catch(() => {});
    },
  },
};
</script>
