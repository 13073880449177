import Vue from 'vue';
import Vuex from 'vuex';

export default {
    state: () => ({
        isCloseMenu: false,
        isOpenMenuMobile: false
    }),
    mutations: {
        toggleMenu: (state) => {
            state.isCloseMenu = !state.isCloseMenu;
        },
        toggleMenuMobile: (state, value) => {
            state.isOpenMenuMobile = value;
        }
    },
    actions: {
    },
    getters: {
        isCloseMenu: state => state.isCloseMenu,
        isOpenMenuMobile: state => state.isOpenMenuMobile
    }
}
