import * as actions from './actions'

const store = {
    state: {
        permissions: null,
        groupList: null,
        moduleList: null,
        moduleListWithTree: null
    },
    mutations: {
        PERMISSION_LIST: (state, data) => {
            state.permissions = data;
        },
        GROUP_LIST: (state, data) => {
            state.groupList = data.data;
        },
        MODULE_LIST: (state, data) => {
            state.moduleList = data;
        },
        MODULE_LIST_WITH_TREE: (state, data) => {
            state.moduleListWithTree = data;
        }
    },
    actions,
    getters: {
        permissions: state => state.permissions,
        groupList: state => state.groupList,
        moduleList: state => state.moduleList,
        moduleListWithTree: state => state.moduleListWithTree
    }
};


export default store;
