import { UserManager, WebStorageStateStore, Log } from 'oidc-client';
import { API_URL } from './api';

const BASE_URL = API_URL;

const settings = {
    userStore: new WebStorageStateStore({ store: window.localStorage }),
    authority: BASE_URL,
    client_id: 'AitaClient',
    client_authentication: 'client_secret_post',
    client_secret: '4ef3ad9d53596f0d55f08137732610769cac91601920da140a555644b61e1bc1',
    redirect_uri: `${BASE_URL}/callback`,
    automaticSilentRenew: true,
    silent_redirect_uri: `${BASE_URL}/silent-renew`,
    response_type: 'code',
    scope: 'openid profile email offline_access',
    post_logout_redirect_uri: BASE_URL,
    filterProtocolClaims: true,
    loadUserInfo: true,
};
const mgr = new UserManager(settings);

Log.logger = console;
Log.level = Log.INFO;

mgr.events.addUserLoaded(function (user) {
    console.log('New User Loaded：', arguments);
});

mgr.events.addAccessTokenExpiring(function () {
    console.log('AccessToken Expiring：', arguments);
});

mgr.events.addAccessTokenExpired(function () {
    console.log('AccessToken Expired：', arguments);
    //alert('Session expired. Going out!');
    mgr.signoutRedirect().then((resp) => {
        console.log('signed out', resp);
    }).catch((err) => {
        console.log(err);
    });
});

mgr.events.addSilentRenewError(function () {
    console.error('Silent Renew Error：', arguments);
});

mgr.events.addUserSignedOut(function () {
    //alert('Going out!');
    console.log('UserSignedOut：', arguments);
    mgr.signoutRedirect().then((resp) => {
        console.log('signed out', resp);
    }).catch((err) => {
        console.log(err);
    });
});

export default class AuthService {
    getSetting() {
        return settings;
    }

    // Renew the token manually
    renewToken() {
        const self = this;
        return new Promise((resolve, reject) => {
            mgr.signinSilent().then((user) => {
                if (user == null) {
                    self.signIn(null);
                } else {
                    return resolve(user);
                }
            }).catch((err) => {
                console.log(err);
                return reject(err);
            });
        });
    }

    // Get the user who is logged in
    getUser() {
        const self = this;
        return new Promise((resolve, reject) => {
            mgr.getUser().then((user) => {
                if (user == null) {
                    self.signIn();
                    return resolve(null);
                }
                return resolve(user);
            }).catch((err) => {
                console.log(err);
                return reject(err);
            });
        });
    }

    // Check if there is any user logged in
    getSignedIn() {
        const self = this;
        return new Promise((resolve, reject) => {
            mgr.getUser().then((user) => {
                if (user == null) {
                    self.signIn();
                    return resolve(false);
                }
                return resolve(true);
            }).catch((err) => {
                console.log(err);
                return reject(err);
            });
        });
    }

    // Redirect of the current window to the authorization endpoint.
    signIn() {
        mgr.signinRedirect().catch((err) => {
            console.log(err);
        });
    }

    // Redirect of the current window to the end session endpoint
    signOut() {
        return new Promise((resolve, reject) => {
            mgr.signoutRedirect().then((resp) => {
                console.log('signed out', resp);
                return resolve(resp);
            }).catch((err) => {
                console.log(err);
                return reject(err);
            });
        });
    }

    signoutPopup() {
        mgr.signoutPopup().then((resp) => {
            console.log('signed out', resp);
        }).catch((err) => {
            console.log(err);
        });
    }

    signinSilentCallback() {
        mgr.signinSilentCallback();
    }

    signinRedirectCallback() {
        return new Promise((resolve, reject) => {
            mgr.signinRedirectCallback().then((user) => resolve(user)).catch((err) => {
                console.log(err);
                return reject(err);
            });
        });
    }

    // Get the profile of the user logged in
    getProfile(isAutoLogin = true) {
        const self = this;
        return new Promise((resolve, reject) => {
            mgr.getUser().then((user) => {
                if (user == null) {
                    if (isAutoLogin) {
                        self.signIn();
                        return resolve(null);
                    } else {
                        return resolve(null);
                    }
                }
                return resolve(user.profile);
            }).catch((err) => {
                console.log(err);
                return reject(err);
            });
        });
    }

    // Get the token id
    getIdToken() {
        const self = this;
        return new Promise((resolve, reject) => {
            mgr.getUser().then((user) => {
                if (user == null) {
                    self.signIn();
                    return resolve(null);
                }
                return resolve(user.id_token);
            }).catch((err) => {
                console.log(err);
                return reject(err);
            });
        });
    }

    // Get the session state
    getSessionState() {
        const self = this;
        return new Promise((resolve, reject) => {
            mgr.getUser().then((user) => {
                if (user == null) {
                    self.signIn();
                    return resolve(null);
                }
                return resolve(user.session_state);
            }).catch((err) => {
                console.log(err);
                return reject(err);
            });
        });
    }

    // Get the access token of the logged in user
    getAcessToken() {
        const self = this;
        return new Promise((resolve, reject) => {
            mgr.getUser().then((user) => {
                if (user == null) {
                    self.signIn();
                    return resolve(null);
                }
                return resolve(user.access_token);
            }).catch((err) => {
                console.log(err);
                return reject(err);
            });
        });
    }

    // Takes the scopes of the logged in user
    getScopes() {
        const self = this;
        return new Promise((resolve, reject) => {
            mgr.getUser().then((user) => {
                if (user == null) {
                    self.signIn();
                    return resolve(null);
                }
                return resolve(user.scopes);
            }).catch((err) => {
                console.log(err);
                return reject(err);
            });
        });
    }

    // Get the user roles logged in
    getRole() {
        const self = this;
        return new Promise((resolve, reject) => {
            mgr.getUser().then((user) => {
                if (user == null) {
                    self.signIn();
                    return resolve(null);
                }
                return resolve(user.profile.role);
            }).catch((err) => {
                console.log(err);
                return reject(err);
            });
        });
    }
}
