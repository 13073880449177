<template>
    <main class="content-page">
        <div class="container">
            <div class="main-ct">
                <div class="box type2 acti-history">
                    <div class="box-head">
                        <h2>{{$t('activity')}}</h2>
                    </div>
                    <ul class="box-ct lst-ct mt-2">
                        <li class="d-flex align-items-center" v-for="item in listData" :key="item.id">
                            <div class="avt-area">
                                <avatar :src="currentUser.avatar" :username="currentUser.full_name || currentUser.name" class="w-100" :size="64"/>
                            </div>
                            <div class="itm-txt d-flex flex-column justify-content-center">
                                <span><em>{{currentUser.full_name || currentUser.name}}</em> <span v-html="item.actionText"></span></span>
                                <span>{{item.createdDate | moment(appSettings.format.dateTime)}}</span>
                            </div>
                        </li>
                    </ul>
                    <div class="text-right">
                        <vue-pagination :total="total" :pageSize="objData.pageSize" v-model="objData.pageIndex" class="mt-2 " @change="pageChange" />
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>
<script>
    export default {
        layout: 'manager',
        middleware: 'authenticated',
        data() {
            return {
                objData: {
                    pageIndex: 1,
                    pageSize: 10,
                },
                total: 0,
                listData: []
            };
        },
        created() {
            this.getData();
        },
        methods: {
            pageChange(page) {
                this.objData.pageIndex = page;
                this.getData();
            },
            getData() {
                const $loading = this.$loading.show();
                this.$axios
                    .get(this.$api.USER_GET_ACTIVITYLOG, { params: this.objData })
                    .then((response) => {
                        $loading.hide();
                        this.listData = response.result.data;
                        this.total = response.result.totalRows;
                    })
                    .catch((ex) => {
                        $loading.hide();
                        const error = this.$api.getApiError(ex);
                        this.$error(error);
                    });
            }
        },
    };
</script>
