<!-- Please remove this file from your project -->
<template>
    <div class="main-sidebar-left" :class="{'open-sidebar': isOpenMenuMobile}">
        <div class="top-content-sidebar">
            <div class="icon-nav-sidebar" @click="toggleMenu">
                <svg width="25" height="18" viewBox="0 0 25 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M25 0.9C25 1.13869 24.9059 1.36761 24.7385 1.5364C24.571 1.70518 24.3439 1.8 24.1071 1.8H0.892857C0.656057 1.8 0.428955 1.70518 0.261512 1.5364C0.0940686 1.36761 0 1.13869 0 0.9C0 0.661305 0.0940686 0.432387 0.261512 0.263604C0.428955 0.0948213 0.656057 0 0.892857 0H24.1071C24.3439 0 24.571 0.0948213 24.7385 0.263604C24.9059 0.432387 25 0.661305 25 0.9ZM20.5357 8.1H0.892857C0.656057 8.1 0.428955 8.19482 0.261512 8.3636C0.0940686 8.53239 0 8.7613 0 9C0 9.23869 0.0940686 9.46761 0.261512 9.6364C0.428955 9.80518 0.656057 9.9 0.892857 9.9H20.5357C20.7725 9.9 20.9996 9.80518 21.1671 9.6364C21.3345 9.46761 21.4286 9.23869 21.4286 9C21.4286 8.7613 21.3345 8.53239 21.1671 8.3636C20.9996 8.19482 20.7725 8.1 20.5357 8.1ZM12.5 16.2H0.892857C0.656057 16.2 0.428955 16.2948 0.261512 16.4636C0.0940686 16.6324 0 16.8613 0 17.1C0 17.3387 0.0940686 17.5676 0.261512 17.7364C0.428955 17.9052 0.656057 18 0.892857 18H12.5C12.7368 18 12.9639 17.9052 13.1313 17.7364C13.2988 17.5676 13.3929 17.3387 13.3929 17.1C13.3929 16.8613 13.2988 16.6324 13.1313 16.4636C12.9639 16.2948 12.7368 16.2 12.5 16.2Z" fill="white"></path>
                </svg>
            </div>
            <div class="logo-page">
                <router-link to="/manager/info">
                    <h3>Trang chủ</h3>
                </router-link>
                <!--<a href="javascript:;">

                <img src="@/assets/img-fix/logo.png" alt="">
            </a>-->
            </div>
        </div>
        <div class="wp-navs-main">
            <div class="lst-navs">
                <div class="item-nav-side" :class="{'active': true}" v-for="(item, index) in topMenu" :key="`top_${index}`" v-if="isAllowMenu(item)">
                    <rounter-link :to="item.url">
                        <div class="nav-parent ">
                            <div class="icon-illus">
                                <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M14.3312 15H9.4586C9.10828 15 8.82166 14.7126 8.82166 14.3614V11.3278C8.82166 10.5615 8.21656 9.95476 7.45223 9.95476C6.6879 9.95476 6.0828 10.5615 6.0828 11.3278V14.3614C6.0828 14.7126 5.79618 15 5.44586 15H0.636943C0.286624 15 0 14.7126 0 14.3614V6.1868C0 5.61203 0.286624 5.06919 0.732484 4.74987L7.13376 0.119745C7.35669 -0.0399149 7.64331 -0.0399149 7.86624 0.119745L14.2675 4.74987C14.7134 5.06919 15 5.61203 15 6.1868V14.3933C14.9682 14.7126 14.6815 15 14.3312 15ZM10.0955 13.7547H13.7261V6.1868C13.7261 6.02714 13.6306 5.86748 13.5032 5.77169L7.48408 1.39702L1.46497 5.73976C1.33758 5.83555 1.24204 5.99521 1.24204 6.15487V13.7227H4.87261V11.3278C4.87261 9.8909 6.05096 8.70942 7.48408 8.70942C8.9172 8.70942 10.0955 9.8909 10.0955 11.3278V13.7547Z" fill="#D4D9E1"></path>
                                </svg>
                            </div>
                            <div class="text">{{item.title}}</div>
                        </div>
                    </rounter-link>
                </div>
            </div>
            <div class="lst-navs-group">
                <div class="item-nav-group-child" :class="{'active': true,'open-nav-child':listCollapseMenu[index]}" v-for="(item, index) in treeMenu" :key="`group_${index}_${keyChangeMenu}`" v-if="isAllowMenu(item)">
                    <div class="nav-parent" @click="collapseMenu(index)">
                        <div class="icon-collapse">
                            <svg width="5" height="9" viewBox="0 0 5 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0.122027 0.11982C-0.040667 0.279556 -0.0406948 0.538592 0.122055 0.698356L3.99408 4.4999L0.122028 8.30164C-0.0406667 8.46138 -0.0406945 8.72041 0.122056 8.88018C0.284778 9.03994 0.548583 9.03994 0.711306 8.88018L4.87797 4.78916C4.95611 4.71244 5 4.60839 5 4.4999C5 4.39141 4.95608 4.28734 4.87794 4.21065L0.711278 0.119847C0.548583 -0.0399437 0.28475 -0.0399441 0.122027 0.11982Z" fill="#D4D9E1"></path>
                            </svg>
                        </div>
                        <div class="text">{{item.title}}</div>
                    </div>
                    <div class="nav-child-lv1">
                        <div class="item-nav-side" :class="{active:checkActive(sub.url)}" v-for="(sub, i) in item.subMenus" :key="`sub_${i}`" v-if="isAllowMenu(sub)">
                            <router-link :to="sub.url">
                                <div class="nav-parent" style="height:24px">
                                    <div class="icon-illus">
                                        <!--<svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clip-path="url(#clip0_1789_5692)">
                                            <path d="M14.414 9.66704C14.7377 9.66704 15 9.40469 15 9.08108V2.99483C15 2.2702 14.5841 1.64779 13.9145 1.37061C13.2457 1.09369 12.5126 1.23933 12.0005 1.75035L8.08552 5.60858V2.99486C8.08552 2.27023 7.66957 1.64779 7.00005 1.37061C6.33131 1.09381 5.59813 1.23933 5.08602 1.75041L0.516793 6.25331C0.515738 6.25434 0.514713 6.25539 0.513658 6.25642C-0.171244 6.9417 -0.171215 8.05662 0.513717 8.74185C0.514889 8.74302 0.516061 8.74419 0.517262 8.74536L5.09484 13.2478C5.43518 13.5873 5.87247 13.7654 6.32314 13.7653C6.54966 13.7653 6.77967 13.7203 7.00224 13.6281C7.67042 13.3515 8.08549 12.7288 8.08549 12.0031V9.38963L12 13.2471C12.3419 13.5885 12.7816 13.7676 13.235 13.7676C13.4631 13.7676 13.6948 13.7222 13.9191 13.6293C14.5858 13.3533 14.9999 12.7332 14.9999 12.011C14.9999 11.6873 14.7376 11.425 14.414 11.425C14.0904 11.425 13.828 11.6874 13.828 12.011C13.828 12.3707 13.5546 12.5119 13.4708 12.5465C13.3855 12.5818 13.0869 12.6768 12.8275 12.4173C12.8265 12.4162 12.8254 12.4152 12.8244 12.4142L8.25568 7.91198C8.02924 7.68397 8.02924 7.31439 8.25568 7.08628L12.8244 2.58379C12.8255 2.58274 12.8265 2.58168 12.8276 2.58066C13.0844 2.32361 13.3813 2.41826 13.4662 2.45345C13.5511 2.48858 13.828 2.63146 13.828 2.99486V9.08111C13.8281 9.40472 14.0904 9.66704 14.414 9.66704ZM6.554 12.5454C6.47033 12.58 6.17742 12.6732 5.92174 12.4173C5.92054 12.4161 5.91934 12.4149 5.91814 12.4137L1.3413 7.91207C1.11477 7.68406 1.11474 7.31442 1.34121 7.08628L5.90994 2.58379C5.91099 2.58274 5.91208 2.58168 5.91313 2.58063C6.16995 2.32366 6.46684 2.41829 6.55172 2.45342C6.63659 2.48855 6.91356 2.63146 6.91356 2.99483V12.0031H6.91359C6.91359 12.3681 6.63835 12.5104 6.554 12.5454Z" fill="#D4D9E1"></path>
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_1789_5692">
                                                <rect width="15" height="15" fill="white"></rect>
                                            </clipPath>
                                        </defs>
                                    </svg>-->

                                    </div>
                                    <div class="text">{{sub.title}}</div>
                                </div>
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="close-popup-sidebar" @click="closeMenuMobile">
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.93803 8.00418L15.7966 1.14562C16.0613 0.889968 16.0686 0.468148 15.813 0.203437C15.5573 -0.061274 15.1355 -0.0686141 14.8708 0.187039C14.8652 0.192388 14.8598 0.197854 14.8544 0.203437L7.99582 7.062L1.13729 0.203398C0.872577 -0.0522551 0.450759 -0.044915 0.195107 0.219796C-0.0542988 0.478026 -0.0542988 0.887392 0.195107 1.14562L7.05364 8.00418L0.195107 14.8627C-0.0650355 15.1229 -0.0650355 15.5447 0.195107 15.8049C0.455288 16.065 0.877106 16.065 1.13729 15.8049L7.99582 8.94636L14.8543 15.8049C15.1191 16.0606 15.5409 16.0532 15.7965 15.7885C16.0459 15.5303 16.0459 15.1209 15.7965 14.8627L8.93803 8.00418Z" fill="#fff">
                </path>
            </svg>
        </div>
    </div>
</template>

<script>
    import { mapGetters, mapMutations } from 'vuex'
    export default {
        name: 'Left',
        data() {
            return {
                listCollapseMenu: [],
                keyChangeMenu: 0
            }
        },
        watch: {
            '$route'(to, from) {
                this.closeMenuMobile();
            }
        },
        computed: {
            ...mapGetters('system', ['menuList']),
            ...mapGetters(['isOpenMenuMobile']),
            topMenu() {
                if (this.menuList)
                    return this.menuList.filter(k => !k.tree);
                return []
            },
            treeMenu() {
                if (this.menuList)
                    return this.menuList.filter(k => k.tree);
                return []
            }
        },
        methods: {
            ...mapMutations(['toggleMenu','toggleMenuMobile']),
            isAllowMenu(menuItem, permissions) {
                if (menuItem.requireAdmin) {
                    return this.currentUser.is_system.toLowerCase()==='true';
                }
                return true;
                // Nếu có con thì check quyền theo con
                if (menuItem.subMenus && menuItem.subMenus.length > 0) {
                    for (const item of menuItem.subMenus) {
                        if (!item.permissions || item.permissions.length === 0) { item.permissions = permissions || menuItem.permissions; }
                        if (item.permissions && item.permissions.length > 0) {
                            if (this.isPermission(...item.permissions)) { return true; }
                        }

                        // Nếu có con cấp nữa thì cấp theo con
                        if (item.subMenus && item.subMenus.length > 0) {
                            for (const k of item.subMenus) {
                                if (!k.permissions || k.permissions.length === 0) { k.permissions = permissions; }
                                if (k.permissions && k.permissions.length > 0) {
                                    if (this.isPermission(...k.permissions)) { return true; }
                                }
                            }
                        }
                    }
                }
                if (!menuItem.permissions || menuItem.permissions.length === 0) { return false; }
                return this.isPermission(...menuItem.permissions);
            },
            checkActive(url) {
                //console.log('12341234',this.$route)
                return this.$route.path.includes(url);
            },
            collapseMenu(index) {
                if (this.listCollapseMenu[index]) {
                    this.listCollapseMenu[index] = false
                } else {
                    this.listCollapseMenu[index]=true
                }
                this.keyChangeMenu++;
            },
            closeMenuMobile() {
                document.body.classList.remove('overflow-hidden')
                this.toggleMenuMobile(false)
            }
        },
        created() {
            this.listCollapseMenu[0] = true;
        }
    }
</script>
