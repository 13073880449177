<template>
    <div style="text-align:center; margin:200px auto;">
        <div v-if="error" style="color:red">{{error}}</div>
        <a href="/" @click="reloadPage">Quay lại</a>
    </div>
</template>
<script type="text/javascript">

    import { ACCOUNT_GET_ERROR } from '../../constants/api'
    export default {
        data() {
            return { error: '' };
        },
        async created() {
            try {
                const query = window.location.search;
                const errorIdQuery = query && query.toLowerCase().indexOf('?errorid=') == 0 && query;

                const response = await fetch(`${this.$api.ACCOUNT_GET_ERROR}${errorIdQuery}`, {
                    credentials: 'include',
                });
                const data = await response.json();
                this.error = data.result.errorDescription;
            } catch (ex) {
                this.error = 'Lỗi! Vui lòng trở lại trang chủ và thử lại!'
            }
            try {
                localStorage.clear();
            } catch (ex2) {

            }
            try {
                var cookies = document.cookie.split(";");

                for (var i = 0; i < cookies.length; ++i) {
                    var myCookie = cookies[i];
                    var pos = myCookie.indexOf("=");
                    var name = pos > -1 ? myCookie.substr(0, pos) : myCookie;
                    document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
                }
            }
            catch (ex) {

            }
        },
        methods: {
            reloadPage() {
                window.location.href = '/';
            }
        }
    };
</script>
