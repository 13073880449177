<template>
  <div>
  </div>
</template>
<script type="text/javascript">
export default {
  async created() {
        this.$auth.signinSilentCallback();
  },
};
</script>
