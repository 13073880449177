<template>
    <main class="content-page">
        <div class="container">
            <div class="main-ct">
                <div class="box type2 delete-acc">
                    <div class="box-head">
                        <h2> {{$t("delete_account")}}</h2>
                    </div>
                    <div class="box-ct lst-ct mt-4">
                        <!-- [D] 4.6 Xóa tài khoản thì dùng đoạn code này -->
                        <div class="txt-noti mb-3" v-if="false">
                            <span class="itm-txt">Tài khoản của bạn đang có 4 khóa học chưa hoàn thành, 500 người bạn trong mạng xã hội Vitan</span>
                        </div>

                        <!-- [D] 4.6.1 Xác nhận xóa tài khoản thì dùng đoạn code này -->
                        <div class="confirm-delete" v-if="!isPasswordValid">
                            <span>{{$t('enter_password_confirm')}}</span>
                            <div class="custom-input mt-4">
                                <input-password v-model="password" />
                            </div>
                            <div class="btn-area mt-4">
                                <button type="button" class="btn btn-confirm" @click="checkPassword"> {{ $t("register_next_step") }}</button>
                            </div>
                        </div>
                        <div class="confirm-delete" v-else>
                            <div class="custom-input mt-4">
                                <label class="checkbox-inline">
                                    <input type="checkbox"
                                           v-model="isConfirm"> {{$t("delete_account_confirm")}}
                                </label>
                            </div>
                            <!--<span class="txt-mess">{{$t("delete_account_confirm")}}?</span>-->
                            <div class="btn-area mt-4">
                                <button type="button" class="btn btn-confirm" @click="deleteAccount"> {{ $t("delete_account") }}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>

<script>
    import { mapActions } from 'vuex';
    export default {
        data() {
            return {
                isConfirm: false,
                isPasswordValid: false,
                password: ''
            };
        },
        methods: {
            ...mapActions('user', ['logoutAll']),
            deleteAccount() {
                if (!this.isConfirm) {
                    return this.$error(this.$t('delete_account_confirm_error'));
                }
                const $loading = this.$loading.show();
                this.$axios
                    .delete(this.$api.ACCOUNT_DELETE)
                    .then(() => {
                        this.$success(this.$t('delete_success'));
                        $loading.hide();
                        this.$auth.signOut();
                    })
                    .catch((ex) => {
                        $loading.hide();
                    });
            },
            checkPassword() {
                const $loading = this.$loading.show();
                this.$axios
                    .post(this.$api.ACCOUNT_CHECK_PASSWORD, {
                        password: this.password,
                    })
                    .then(() => {
                        $loading.hide();
                        this.isPasswordValid = true;
                    })
                    .catch((ex) => {
                        $loading.hide();
                        this.$error(this.$t('invalid_password'));
                    });
            },
        },
    };
</script>

<style>
</style>
