<template>
    <main :class="{'content-page': !iframe}">
        <div class="container">
            <div class="main-ct">
                <div class="box type2">
                    <div class="box-head">
                        <div class="back-steps-id">
                            <a href="javascript:void(0):" @click="goBack">
                                <svg xmlns="http://www.w3.org/2000/svg"
                                     width="17"
                                     height="13"
                                     viewBox="0 0 17 13"
                                     fill="none">
                                    <path d="M5.75452 1.07142C5.98093 0.845009 6.33737 0.845009 6.56378 1.07142C6.78254 1.29018 6.78254 1.65427 6.56378 1.87252L2.46037 5.97592H16.0011C16.3167 5.97592 16.5757 6.22681 16.5757 6.54246C16.5757 6.8581 16.3167 7.11715 16.0011 7.11715H2.46037L6.56378 11.2129C6.78254 11.4393 6.78254 11.8039 6.56378 12.0222C6.33737 12.2486 5.98093 12.2486 5.75452 12.0222L0.679695 6.94734C0.460935 6.72858 0.460935 6.36449 0.679695 6.14624L5.75452 1.07142Z"
                                          fill="#414B5B"></path>
                                </svg>
                            </a>
                        </div>
                        <h2 v-if="id">{{ $t("address_edit_title") }}</h2>
                        <h2 v-else>{{ $t("address_add_title") }}</h2>
                    </div>
                    <form autocomplete="on">
                        <div class="box-ct">
                            <ul class="row lst-itm-info">
                                <li class="col-md-6">
                                    <div class="custom-input"
                                         :class="{
                      'is-err': submitted && !$v.address.name.required,
                    }">
                                        <input type="text"
                                               id="ipt-fullname"
                                               class="ipt"
                                               name="name"
                                               v-model="address.name" />
                                        <label for="ipt-fullname" class="lbl">
                                            {{ $t("address_full_name") }}
                                            <span class="obl">*</span>
                                        </label>
                                    </div>
                                    <div class="txt-mess ml-0 text-left"
                                         v-if="submitted && !$v.address.name.required">
                                        {{ $t("address_fullname_required") }}
                                    </div>
                                </li>
                                <!-- <li class="col-md-6">
                                  <div
                                    class="custom-input"

                                  >
                                    <input
                                      type="text"
                                      id="ipt-company"
                                      class="ipt"
                                      v-model="address.company"
                                      name="company"
                                    />
                                    <label for="ipt-company" class="lbl"
                                      >{{ $t("address_company") }}
                                      </label
                                    >
                                  </div>

                                </li> -->
                                <li class="col-md-6">
                                    <div class="custom-input"
                                         :class="{
                      'is-err': submitted && !$v.address.phoneNumber.required,
                    }">
                                        <input type="text"
                                               id="ipt-company"
                                               class="ipt"
                                               v-model="address.phoneNumber"
                                               name="phoneNumber" />
                                        <label for="ipt-company" class="lbl">
                                            {{ $t("address_phone") }}
                                            <span class="obl">*</span>
                                        </label>
                                    </div>
                                    <div class="txt-mess ml-0 text-left"
                                         v-if="submitted && !$v.address.phoneNumber.required">
                                        {{ $t("address_phone_required") }}
                                    </div>
                                </li>
                                <um-web-address v-model="address.locality"
                                                 label="title"
                                                 code="id"
                                                 :showSearch="true"
                                                 :isRequired="true"
                                                 :isSubmitted="submitted" />
                                <li class="col-md-12">
                                    <div class="custom-input selbox"
                                         :class="{
                      'is-err': submitted && !$v.address.address.required,
                    }">
                                        <textarea class="textarea-ipt ipt"
                                                  id="textarea-address"
                                                  name="address"
                                                  v-model="address.address"></textarea>
                                        <label for="textarea" class="lbl">
                                            {{ $t("address_address") }} <span class="obl">*</span>
                                        </label>
                                    </div>
                                    <div class="txt-mess ml-0 text-left"
                                         v-if="submitted && !$v.address.address.required">
                                        {{ $t("address_address_required") }}
                                    </div>
                                    <div class="addr-type-area">
                                        <div class="addr-type d-flex flex-wrap align-items-center">
                                            <strong>{{ $t("address_type") }}</strong>

                                            <ul class="d-flex d-flex align-items-center">
                                                <li class="form-check form-check-inline radio-check">
                                                    <input class="form-check-input"
                                                           type="radio"
                                                           name="addr-type"
                                                           value="1"
                                                           id="addr-type1"
                                                           v-model="address.addressType" />
                                                    <label class="form-check-label" for="addr-type1">
                                                        {{
                            $t("address_is_house")
                                                        }}
                                                    </label>
                                                </li>
                                                <li class="form-check form-check-inline radio-check">
                                                    <input class="form-check-input"
                                                           type="radio"
                                                           name="addr-type"
                                                           value="2"
                                                           id="addr-type2"
                                                           v-model="address.addressType" />
                                                    <label class="form-check-label" for="addr-type2">
                                                        {{
                            $t("address_is_company")
                                                        }}
                                                    </label>
                                                </li>
                                            </ul>
                                        </div>
                                        <div class="form-check form-check-inline checkbox-check">
                                            <input class="form-check-input"
                                                   type="checkbox"
                                                   v-model="address.isDefaultAddress"
                                                   id="fixed-addr" />
                                            <label class="form-check-label" for="fixed-addr">
                                                {{ $t("address_default_title") }}
                                            </label>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                            <div class="btn-area mt-4">
                                <button type="button"
                                        class="btn btn-update"
                                        @click="SaveUserAddress">
                                    {{ $t("address_update") }}
                                </button>
                                <button type="button" class="btn button-cancel-info" @click="goBack">
                                    {{ $t("cancel") }}
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </main>
</template>

<script>
    import { required, email } from "vuelidate/lib/validators";
    export default {
        props: ['iframe'],
        data() {
            return {
                id: null,
                address: {
                    name: null,
                    company: null,
                    address: null,
                    addressType: 1,
                    locality: {
                        provinceId: null,
                        districtId: null,
                        wardId: null,
                    },
                    provinceId: null,
                    districtId: null,
                    wardId: null,
                    phoneNumber: null,
                    isDefaultAddress: false,
                },
                isLoaded: false,
                submitted: false,
            };
        },
        validations: {
            address: {
                name: { required },
                locality: {
                    provinceId: { required },
                    districtId: { required },
                    wardId: { required },
                },
                // provinceId: { required },
                // districtId: { required },
                // wardId: { required },
                phoneNumber: { required },
                address: { required },
            },
        },
        computed: {
            backUrl() {
                return this.iframe ? "/address" : "/manager/address";
            }
        },
        created() {
            if (this.$route.params && this.$route.params.id && this.$route.params.id > 0) {
                // get by
                this.id = this.$route.params.id;
                this.GetUserAddress(this.id);
            } else {
                this.checkDefaultAddress();
                this.setDefaulNameAndPhone();
            }
            //this.getEmail();
        },
        methods: {
            checkDefaultAddress() {
                const $loading = this.$loading.show();
                this.$axios
                    .get(this.$api.CHECK_DEFAULT_ADDRESS)
                    .then((data) => {
                        if (!data.result) {
                            this.address.isDefaultAddress = true;
                        }
                        $loading.hide();
                    })
                    .catch((ex) => {
                        $loading.hide();
                    });
            },
            setDefaulNameAndPhone() {
                const $loading = this.$loading.show();
                this.$axios
                    .get(this.$api.USER_NAME_PHONE)
                    .then((data) => {
                        this.address.name = data.result.fullName;
                        this.phoneNumber = data.result.phoneNumber;
                        $loading.hide();
                    })
                    .catch((ex) => {
                        $loading.hide();
                    });
            },
            SaveUserAddress() {
                this.submitted = true;
                this.$v.$touch();
                if (!this.$v.$invalid) {
                    const $loading = this.$loading.show();
                    // Mapping
                    this.address.provinceId = this.address.locality.provinceId;
                    this.address.districtId = this.address.locality.districtId;
                    this.address.wardId = this.address.locality.wardId;
                    //
                    if (this.id) {
                        this.$axios
                            .put(this.$api.USER_ADDRESS_UPDATE, this.address)
                            .then((data) => {
                                $loading.hide();
                                this.$success(this.$t("address_noti_edit"));
                                this.$router.push(this.backUrl);
                            })
                            .catch((ex) => {
                                $loading.hide();
                            });
                    } else {
                        this.$axios
                            .post(this.$api.USER_ADDRESS_CREATE, this.address)
                            .then((data) => {
                                $loading.hide();
                                this.$success(this.$t("address_noti_add"));
                                this.$router.push(this.backUrl);
                            })
                            .catch((ex) => {
                                $loading.hide();
                            });
                    }
                }
            },
            GetUserAddress(id) {
                var vm = this;
                const $loading = this.$loading.show();
                this.$axios
                    .get(`${this.$api.USER_ADDRESS_GET_BY_ID}?userAddressId=${id}`)
                    .then((data) => {
                        vm.address = data.result;
                        // Mapping
                        vm.address.locality = {
                            provinceId: vm.address.provinceId,
                            districtId: vm.address.districtId,
                            wardId: vm.address.wardId,
                        };
                        //
                        $loading.hide();
                    })
                    .catch((ex) => {
                        $loading.hide();
                    });
            },
            goBack() {
                this.$router.push(this.backUrl);
            },
        },
    };
</script>
