<template>
    <!--<a class="login100-social-item" href="#" @click.stop.prevent="buttonClicked">
          <i :class="getIconCls" /><span>{{ getButtonText }}</span>
      </a>-->

    <button type="button"
            class="choice-facebook ml-0"
            @click.stop.prevent="buttonClicked"
            :disabled="!isLoadedFbSdk">
        <slot name="body">
            <img src="@/assets/images/logo-fb.svg" alt="" width="20" height="20"/>
            <span class="txt-mxh ml-2">Đăng nhập bằng facebook</span>
        </slot>
    </button>
</template>
<script>
    import { loadFbSdk, fbLogout, fbLogin } from "./helpers.js";
    export default {
        name: "FacebookLogin",
        props: {
            appId: {
                type: String,
                required: true,
            },
            version: {
                type: String,
                default: "v2.9",
            },
            logoutLabel: {
                type: String,
                default: "Thoát",
            },
            loginLabel: {
                type: String,
                default: "login_with_facebook",
            },
            loginOptions: {
                type: Object,
                default() {
                    return {
                        scope: "email",
                    };
                },
            },
            isLinking: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                isWorking: false,
                isConnected: false,
                isLoadedFbSdk: false
            };
        },
        computed: {
            getButtonText() {
                return this.loginLabel;
            },
            getIconCls() {
                if (this.isWorking) {
                    return "fa fa-spinner fa-pulse";
                }
                return "fab fa-facebook-f";
            },
        },
        mounted() {
            this.isWorking = true;
            loadFbSdk(this.appId, this.version).then((response) => {
                this.isConnected = false;
                this.isWorking = false;
                this.$emit("sdk-loaded", {
                    isConnected: this.isConnected,
                    FB: window.FB,
                });
                this.isLoadedFbSdk=true
            });
        },
        methods: {
            buttonClicked() {
                this.$emit("click");
                try {
                    window.fbAsyncInit();
                } catch (ex) { }
                this.login();
            },
            logout() {
                this.isWorking = true;
                fbLogout().then((response) => {
                    this.isWorking = false;
                    this.isConnected = false;
                    this.$emit("logout", response);
                });
            },
            login() {
                this.isWorking = true;
                fbLogin(this.loginOptions).then((response) => {
                    if (response.status === "connected") {
                        this.isConnected = true;
                    } else {
                        this.isConnected = false;
                    }
                    this.isWorking = false;
                    this.$emit("login", response);
                });
            },
        },
    };
</script>
<style scoped>
    .choice-facebook {
        background-color: #fff;
        background-image: none;
        border: 1px solid #dadce0;
        border-top-color: rgb(218, 220, 224);
        border-top-style: solid;
        border-top-width: 1px;
        border-right-color: rgb(218, 220, 224);
        border-right-style: solid;
        border-right-width: 1px;
        border-bottom-color: rgb(218, 220, 224);
        border-bottom-style: solid;
        border-bottom-width: 1px;
        border-left-color: rgb(218, 220, 224);
        border-left-style: solid;
        border-left-width: 1px;
        border-image-source: initial;
        border-image-slice: initial;
        border-image-width: initial;
        border-image-outset: initial;
        border-image-repeat: initial;
        color: #3c4043;
        cursor: pointer;
        font-family: "Google Sans",arial,sans-serif;
        font-size: 14px;
        height: 40px;
        letter-spacing: 0.25px;
        outline: none;
        overflow: hidden;
        padding: 0 12px;
        position: relative;
        text-align: center;
        vertical-align: middle;
        white-space: nowrap;
        border-radius: 4px;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
        border-bottom-left-radius: 4px;
    }
</style>
