import Vue from 'vue';
import { SUPPORTED_LOCALES } from '../locales/supported-locales';

export const languageMixin = {
  methods: {
    setLanguage(locale) {
      this.$i18n.locale = locale;
    },
    localePath(url, locale) {
      url = url.replace(this.currentLanguage, '');
      if (locale) url = `/${locale}${url}`;
      else if (url.indexOf(this.currentLanguage) < 0) url = `/${this.currentLanguage}${url}`;
      url = url.replace('//', '/');
      return url;
    },
  },
  computed: {
    supportedLocales() {
      return SUPPORTED_LOCALES;
    },
    currentLanguage() {
      return this.$route.params.lang ? this.$route.params.lang.toLowerCase() : ''; // : 'vi-vn'
    },
    dateFormat() {
      const locale = this.supportedLocales.find((c) => c.code == (this.currentLanguage ? this.currentLanguage : 'vi-vn'));
      if (locale) return locale.dateFormat;
    },
    fullDateFormat() {
      const locale = this.supportedLocales.find((c) => c.code == (this.currentLanguage ? this.currentLanguage : 'vi-vn'));
      console.log(locale);
      if (locale) return locale.fullDateFormat;
    },
  },
  watch: {
    '$route.params.lang': function () {
      this.setLanguage(this.$route.params.lang ? this.$route.params.lang.toLowerCase() : 'vi-vn');
    },
  },
};
Vue.mixin(languageMixin);
