<!-- Please remove this file from your project -->
<template>
    <div class="popup-page ct-popup-small" id="add-detele-popup" :class="{'open-popup':isShowByDate && isShowHandle}">
        <div class="content-popup-page">
            <div class="main-popup-page">
                <div class="block-notification-detele">
                    <h2 class="title">Thông báo</h2>
                    <div class="box-notification-detele">
                        <div class="icon-detele">
                            <img src="img-fix/icon-notification.svg" alt="">
                        </div>
                        <p class="txt-content-detele">Còn 10 ngày đến hạn đặt lại mật khẩu. Vui lòng đổi mật khẩu trước thời hạn để tránh bị khóa tài khoản.</p>
                    </div>
                    <div class="group-bottom-btn-popup">
                        <div class="left-bottom-btn-popup">
                            <div class="btn-cancel">
                                <a href="javascript:;" class="btn-text" @click="isShowHandle=false">Đóng</a>
                            </div>
                        </div>
                        <div class="right-bottom-btn-popup">
                            <div class="btn-save">
                                <router-link class="btn-bg-linear" to="/manager/change-password">Đổi mật khẩu</router-link>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
        <div class="bg-popup-page"></div>
    </div>
</template>

<script>
    export default {
        computed: {
            isShowByDate() {
                return false;
            }
        },
        data() {
            return {
                isShowHandle: true
            }
        },
        methods: {          
        }
    }
</script>
