<template>
    <main class="content-page">
        <div>
            <div class="container">
                <div class="main-ct">
                    <div class="box type2">
                        <div class="box-head">
                            <h2>{{ $t("email_manager") }}</h2>
                        </div>
                        <ul class="box-ct lst-ct lst-manager mt-2">
                            <li v-for="item in listEmail"
                                :key="item.id"
                                v-bind:class="{
                  'is-verified': item.isConfirmed,
                  'itm-txt': !item.isConfirmed,
                }">
                                <span class="itm-txt">{{ item.name }}</span>
                                <div class="lst-btn d-flex ct-lst-btn">
                                    <a href="javascript:void(0):"
                                       @click="showVerifyPopup(item.name)"
                                       v-show="!item.isConfirmed"
                                       class="itm-link">{{ $t("email_verify") }}</a>
                                    <a href="javascript:void(0);"
                                       class="btn-delete"
                                       @click="showDelete(item)">Xoá</a>
                                </div>
                            </li>
                        </ul>
                        <a class="add-new-addr mt-3" href="javascript:void(0):" @click="showCreateEmail"><i class="plus">+</i> {{ $t("add_email") }}</a>
                    </div>
                </div>
            </div>
            <modalcustom v-if="showCreate">
                <div class="modal fade popup show" slot="content">
                    <div class="modal-dialog modal-dialog-centered" role="document">
                        <div class="modal-content">
                            <!-- HTML của phần thêm email mới -->
                            <div class="modal-header justify-content-center">
                                <h5 class="modal-title">{{ $t("add_email") }}</h5>
                            </div>
                            <div class="modal-body type2">
                                <div class="custom-input mt-3 mb-2">
                                    <input type="email"
                                           v-model="email"
                                           id="email"
                                           name="email"
                                           class="form-control"
                                           :class="{ 'is-invalid': submitted && $v.email.$error }" />
                                    <div v-if="submitted && $v.email.$error"
                                         class="invalid-feedback">
                                        <span v-if="!$v.email.required">
                                            {{
                      $t("email_required")
                                            }}
                                        </span>
                                        <span v-if="!$v.email.email">
                                            {{
                      $t("email_invalid")
                                            }}
                                        </span>
                                    </div>
                                    <label for="addEmail" class="lbl">
                                        {{
                    $t("email_add_lbl")
                                        }}
                                    </label>
                                </div>
                                <span class="txt">{{ $t("email_note") }}</span>
                            </div>
                            <div class="modal-footer justify-content-around">
                                <button type="button"
                                        class="btn btn-light"
                                        data-dismiss="modal"
                                        @click="showCreateEmail">
                                    {{ $t("email_cancel") }}
                                </button>
                                <button type="button"
                                        class="btn btn-complete"
                                        @click="CreateEmail">
                                    {{ $t("email_save") }}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </modalcustom>
            <modalcustom v-if="showVerify">
                <div class="modal fade popup show" slot="content">
                    <div class="modal-dialog modal-dialog-centered">
                        <div class="modal-content">
                            <!-- HTML của phần xác thực email vừa thêm -->
                            <div class="modal-header justify-content-center">
                                <h5 class="modal-title" id="exampleModalLongTitle">
                                    {{ $t("email_verify_opt") }}
                                </h5>
                            </div>
                            <div class="modal-body type2">
                                <span class="txt"> {{ $t("email_verify_note") }}</span>
                                <form class="ipt-code">
                                    <v-otp-input ref="otpInput"
                                                 input-classes="otp-input"
                                                 separator=""
                                                 :num-inputs="6"
                                                 :should-auto-focus="true"
                                                 :is-input-num="true"
                                                 @on-complete="handleOnComplete" />
                                </form>
                                <div class="mt-1">
                                    <span v-if="countSecond > 0">Gửi lại sau: </span>

                                    <em class="txt-time" v-if="countSecond > 0">
                                        {{
                    countSecond
                                        }}
                                    </em>
                                    <button type="button"
                                            class="resend-code"
                                            @click="sendOtp"
                                            v-if="countSecond <= 0">
                                        {{ $t("email_resend_code") }}
                                    </button>
                                </div>
                            </div>
                            <div class="modal-footer justify-content-around">
                                <button type="button"
                                        class="btn btn-light"
                                        @click="closeVerifyPopup">
                                    {{ $t("email_remind_later") }}
                                </button>
                                <button type="button"
                                        class="btn btn-complete"
                                        @click="activeEmail" v-show="otp && otp.length == 6">
                                    {{ $t("email_ccomplete") }}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </modalcustom>
            <modalcustom v-if="showDeleteOTP">
                <div class="modal fade popup show" slot="content">
                    <div class="modal-dialog modal-dialog-centered">
                        <div class="modal-content">
                            <div class="modal-header justify-content-center">
                                <h5 class="modal-title" id="exampleModalLongTitle">
                                    {{ $t("email_verify_opt") }}
                                </h5>
                            </div>
                            <div class="modal-body type2">
                                <span class="txt"> {{ $t("email_verify_note") }}</span>
                                <form class="ipt-code">
                                    <v-otp-input ref="otpInput"
                                                 input-classes="otp-input"
                                                 separator=""
                                                 :num-inputs="6"
                                                 :should-auto-focus="true"
                                                 :is-input-num="true"
                                                 @on-complete="handleOnComplete" />
                                </form>
                                <div class="mt-1">
                                    <span v-if="countSecond > 0">Gửi lại sau: </span>
                                    <em class="txt-time" v-if="countSecond > 0">
                                        {{
                    countSecond
                                        }}
                                    </em>
                                    <button type="button"
                                            class="resend-code"
                                            @click="sendOtp"
                                            v-if="countSecond <= 0">
                                        {{ $t("email_resend_code") }}
                                    </button>
                                </div>
                            </div>
                            <div class="modal-footer justify-content-around">
                                <button type="button"
                                        class="btn btn-light"
                                        @click="closeDeletePopup">
                                    {{ $t("email_remind_later") }}
                                </button>
                                <button type="button"
                                        class="btn btn-complete"
                                        @click="deleteOtp(item)" v-show="otp && otp.length == 6">
                                    {{ $t("email_ccomplete") }}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </modalcustom>
        </div>
    </main>
</template>

<script>
    const emailConst = 2;
    import { required, email } from "vuelidate/lib/validators";
    import modalcustom from "../../../components/common/modalcustom.vue";
    export default {
        components: { modalcustom },
        data() {
            return {
                isLoaded: false,
                listEmail: [],
                showVerify: false,
                showCreate: false,
                email: null,
                otp: null,
                submitted: false,
                countSecond: 90,
                showDeleteOTP: false,
                item: {},
            };
        },
        validations: {
            // otp: {
            //   required,
            // },
            email: {
                required,
                email,
            },
        },
        created() {
            this.getEmail();
        },
        methods: {
            getEmail() {
                const $loading = this.$loading.show();
                this.$axios
                    .get(`${this.$api.EMAIL_PHONE_GET_ALL}?type=2`)
                    .then((data) => {
                        this.listEmail = data.result;
                        $loading.hide();
                    })
                    .catch((ex) => {
                        $loading.hide();
                    });
            },
            CreateEmail() {
                this.submitted = true;
                this.$v.$touch();
                if (!this.$v.$invalid) {
                    const $loading = this.$loading.show();
                    // Check email/phone đã có hay chưa?
                    this.$axios
                        .get(
                            `${this.$api.EMAIL_PHONE_CHECKEXISTED}?userPhoneEmailName=${this.email}`
                        )
                        .then((data) => {
                            $loading.hide();
                            if (data.result) {
                                this.$error(this.$t("email_already"));
                            } else {
                                // Tạo mới
                                var formData = {
                                    type: emailConst,
                                    Name: this.email,
                                    isConfirmed: false,
                                };
                                this.$axios
                                    .post(this.$api.EMAIL_PHONE_CREATE, formData)
                                    .then((data) => {
                                        this.getEmail();
                                        this.$success(this.$t("email_success"));
                                        $loading.hide();
                                    })
                                    .catch((ex) => {
                                        $loading.hide();
                                    });
                                this.showCreate = false;
                                this.showVerifyPopup();
                            }
                        })
                        .catch((ex) => {
                            $loading.hide();
                        });
                }
            },
            closeVerifyPopup() {
                this.showVerify = false;
            },
            showVerifyPopup(name) {
                if (name) {
                    this.email = name;
                }
                this.showVerify = !this.showVerify;
                this.sendOtp();
            },
            showCreateEmail() {
                this.showCreate = !this.showCreate;
                this.email = null;
                this.submitted = false;
            },
            sendOtp() {
                const $loading = this.$loading.show();
                this.$axios
                    .get(`${this.$api.EMAIL_PHONE_SEND_OTP}?emailPhoneName=${this.email}`)
                    .then((data) => {
                        $loading.hide();
                        this.countSecond = data.result.times;
                        this.countDownTimer();
                    });
            },
            countDownTimer() {
                if (this.countSecond > 0) {
                    setTimeout(() => {
                        this.countSecond -= 1;
                        this.countDownTimer();
                    }, 1000);
                }
            },
            activeEmail() {
                const $loading = this.$loading.show();
                var formData = {
                    PhoneEmailName: this.email,
                    Otp: this.otp,
                };
                this.$axios
                    .post(this.$api.EMAIL_PHONE_ACTIVE_EMAIL, formData)
                    .then((data) => {
                        $loading.hide();
                        this.$success(this.$t("email_success_verify"));
                        this.closeVerifyPopup();
                        this.getEmail();
                    })
                    .catch((ex) => {
                        this.$error(ex.response.data.responseException.exceptionMessage);
                        $loading.hide();
                    });
            },
            handleOnComplete(value) {
                this.otp = value;
            },
            showDelete(item) {
                this.$confirm(
                    this.$t("confirm_delete"),
                    this.$t("confirm"),
                    this.$t("confirm_cancel")
                ).then(() => {
                    if (item.isConfirmed) {
                        this.showDeleteOTP = true;
                        this.email = item.name;
                        this.item = item;
                        this.sendOtp();
                    } else {
                        this.deleteItem(item);
                    }
                });
            },
            deleteItem(item) {
                var $loading = this.$loading.show();
                this.$axios
                    .delete(`${this.$api.EMAIL_PHONE_DELETE}?userPhoneEmailId=${item.id}`)
                    .then((response) => {
                        $loading.hide();
                        this.$success(this.$t("useremail_noti_delete"));
                        this.getEmail();
                    })
                    .catch((ex) => {
                        $loading.hide();
                    });
            },
            deleteOtp() {
                const $loading = this.$loading.show();
                var formData = {
                    PhoneEmailName: this.email,
                    Otp: this.otp,
                };
                this.$axios
                    .post(this.$api.EMAIL_PHONE_DELETE_OTP, formData)
                    .then((data) => {
                        $loading.hide();
                        this.$success(this.$t("useremail_noti_delete"));
                        this.showDeleteOTP = false;
                        //  this.getEmail();
                    })
                    .catch((ex) => {
                        this.$error(ex.response.data.responseException.exceptionMessage);
                        $loading.hide();
                    });
            },
            closeDeletePopup() {
                this.showDeleteOTP = false;
            },
        },
    };
</script>

<style scoped>
    .popup .modal-dialog {
        width: 400px;
        height: 304px;
    }
</style>
