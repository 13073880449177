<template>
    <router-view></router-view>
</template>
<script>
    export default {
        created() { },
        mounted() {
            if (document)
                document.body.classList.add('ct-reponsive')
        }
    };
</script>
