<template>
  <span></span>
</template>
<script>
import auth from "../../store/auth/store";
import apiUrl from "@/constants/api";
import { mapMutations } from "vuex";
export default {
  name: "auth",
  props: {
    authorize: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    this.checkLogin();
  },
  watch: {
    $route(val) {
      if (val == "/") this.checkLogin();
    },
  },
  beforeCreate() {
    if (!this.$store._modulesNamespaceMap["auth/"])
      this.$store.registerModule("auth", auth);
  },
  methods: {
    ...mapMutations("auth", ["setCurrentUser", "setUserPermission"]),
    getUserPermission(acessToken) {
      this.$axios
        .get(`${this.systemApiUrl}${apiUrl.GET_USER_PERMISSION}`, {
          headers: { Authorization: `Bearer ${acessToken}` },
        })
        .then((response) => {
          this.setUserPermission(response.result);
        });
    },
      checkLogin() {
      if (this.$auth) {
        this.$auth.getProfile(false).then((profile) => {
          if (!profile && this.authorize) {
            this.$auth.signIn();
          } else if (profile) {
            this.setCurrentUser(profile);
            this.$auth.getAcessToken().then((token) => {
              this.$axios.defaults.headers.common[
                "Authorization"
              ] = `Bearer ${token}`;
            });
          }
        });
      }
    },
  },
};
</script>
