<template>
    <div class="container">
        <div class="row">
            <!--<div class="col-md-6 col-lg-7 box-l">
                <div class="img-area">
                    <img src="id/img-signup.jpg" alt="" class="w-100"/>
                </div>
                <h2>{{$t("system_name")}}</h2>
                <p>{{$t("system_slogan")}}</p>
            </div>-->
            <div class="col-md-12 col-lg-12 box-r fix-page-login">
                <div class="logo-m">
                    <img src="id/@logo-mobile.jpg" alt="" class="w-100" />
                </div>
                <div class="inner">
                    <template v-if="step == 1">
                        <h3 class="text-left"> {{ $t("register") }}</h3>
                        <span class="txt text-left">{{ $t("welcome_to") }} <em>{{$t("system_name")}}</em></span>
                        <div class="custom-input">
                            <input type="text" id="ipt-emailorphone" name="email" v-model="userInfo.username" @keyup.enter="checkAndSendOTP" class="ipt">
                            <label for="ipt-emailorphone" class="lbl">{{$t('email_or_phonenumber')}}</label>
                        </div>
                        <div class="btn-area">
                            <button type="button" class="btn" @click="checkAndSendOTP"> {{ $t("register_next_step") }}</button>
                        </div>
                    </template>
                    <template v-else-if="step == 2">
                        <h3>{{ $t("register") }}</h3>
                        <div>
                            <span class="txt-lbl">{{ $t("account") }}: <em>{{ userInfo.username }}</em></span>
                            <div class="custom-input">
                                <input type="text" id="ipt-emailorphone" class="ipt" v-model="otp">
                                <label for="ipt-emailorphone" class="lbl">{{$t('otp_input')}}</label>
                                <a href="javascript:;" class="resend-authen-code disable" v-if="seconds > 0">
                                    {{ $t("otp_resend_in") }}
                                    <span class="countdown" v-if="seconds > 0">({{ seconds }}s)</span>
                                </a>
                                <a href="javascript:;" class="resend-authen-code" @click="resendOTP" v-else>
                                    {{$t('register_resend_otp') }}
                                </a>
                            </div>
                        </div>
                        <div class="btn-area">
                            <button type="button" class="btn" @click="checkOTP">{{ $t("check") }}</button>
                        </div>
                        <a href="javascript:void(0)" class="txt-choice txt-cancel" @click="cancel">{{ $t("cancel") }}</a>
                    </template>
                    <template v-else-if="step == 3">
                        <h3>{{ $t("register") }}</h3>
                        <ul class="form-register">
                            <li class="custom-input">
                                <input type="text" id="ipt-lastname" class="ipt" v-model="userInfo.fullName">
                                <label for="ipt-lastname" class="lbl">Họ và tên</label>
                            </li>
                            <li class="custom-input">
                                <input-password v-model="userInfo.password" @enter="updateInfo" :label="$t('password')" />
                            </li>
                            <li class="custom-input">
                                <input-password v-model="userInfo.rePassword" @enter="updateInfo" :label="$t('repassword')" />
                            </li>
                        </ul>
                        <span class="text-center txt-date-birth">{{$t('dob')}}</span>
                        <date-dropdown v-model="userInfo.birthdate"
                                       min="1800"
                                       :max="new Date().getFullYear() - 5 + ''"
                                       class="m-b-25" />
                        <div class="btn-area">
                            <button type="button" class="btn" @click="updateInfo">{{$t("update")}}</button>
                        </div>
                        <a href="javascript:void(0)" class="txt-choice txt-cancel" @click="cancel">{{ $t("cancel") }}</a>
                    </template>
                    <p class="txt-register">{{ $t("login_call_to_action_msg") }} <router-link to="/login" class="txt-link">{{ $t("login_now") }}</router-link></p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                step: 1,
                userInfo: {
                    id: 0,
                    username: '',
                    address: '',
                    birthdate: (new Date().getFullYear() - 5) + '/1/1'
                },
                otp: '',
                seconds: 0,
            };
        },
        created() {
            this.checkUserProcessRegist();
        },
        methods: {
            checkUserProcessRegist() {
                const cookieUserName = this.$cookie.get(this.$cookieConstants.REGISTER_USER_NAME);
                if (cookieUserName) {
                    this.userInfo.username = cookieUserName;
                    const $loading = this.$loading.show();
                    this.$axios
                        .post(this.$api.ACCOUNT_CHECK_PROCESS_REGIST, {
                            username: this.userInfo.username,
                        })
                        .then((data) => {
                            $loading.hide();
                            if (!data.result) {
                                this.$cookie.delete(this.$cookieConstants.REGISTER_USER_NAME);
                            } else {
                                this.step = 2;
                                this.setTimerOTP(data.result.times);
                            }
                        })
                        .catch((ex) => {
                            $loading.hide();
                            this.$cookie.delete(this.$cookieConstants.REGISTER_USER_NAME);
                        });
                }
            },
            checkAndSendOTP() {
                const $loading = this.$loading.show();
                this.$axios
                    .post(this.$api.ACCOUNT_CHECK_USERNAME_AND_SEND_OTP, {
                        username: this.userInfo.username,
                    })
                    .then((data) => {
                        $loading.hide();
                        this.$success(this.$t('enter_security_code'));
                        this.step = 2;
                        this.setTimerOTP(data.result.times);
                        this.$cookie.set(this.$cookieConstants.REGISTER_USER_NAME, this.userInfo.username);
                    })
                    .catch((ex) => {
                        $loading.hide();
                        const error = this.$api.getApiError(ex);
                        if (error.error === 'invalid_grant') {
                            this.$error(this.$t(error.error_description));
                        } else {
                            this.$error(error);
                        }
                    });
            },
            setTimerOTP(seconds) {
                this.seconds = seconds;
                this.intervalTimer = setInterval(() => {
                    if (this.seconds <= 0) {
                        clearInterval(this.intervalTimer);
                    }

                    this.seconds--;
                }, 1000);
            },
            resendOTP() {
                const $loading = this.$loading.show();
                this.$axios
                    .post(this.$api.ACCOUNT_RESEND_OTP, {
                        username: this.userInfo.username,
                    })
                    .then((data) => {
                        $loading.hide();
                        this.setTimerOTP(data.result.times);
                    })
                    .catch((ex) => {
                        $loading.hide();
                        const error = this.$api.getApiError(ex);
                        if (error.error === 'invalid_grant') {
                            this.$error(this.$t(error.error_description));
                        } else {
                            this.$error(error);
                        }
                    });
            },
            checkOTP() {
                const $loading = this.$loading.show();
                this.$axios
                    .post(this.$api.ACCOUNT_CHECK_OTP_ADD_USER, {
                        username: this.userInfo.username,
                        otp: this.otp,
                    })
                    .then((data) => {
                        $loading.hide();
                        this.userInfo.id = data.result;
                        this.step = 3;
                        this.$cookie.delete(this.$cookieConstants.REGISTER_USER_NAME);
                    })
                    .catch((ex) => {
                        $loading.hide();
                        const error = this.$api.getApiError(ex);
                        if (error.error === 'invalid_grant') {
                            this.$error(this.$t(error.error_description));
                        } else {
                            this.$error(error);
                        }
                    });
            },
            cancelUpdateInfo() {
                this.$router.push('/');
            },
            updateInfo() {
                if (!this.userInfo.firstName) {
                    return this.$error(this.$t('error_first_name_empty'));
                }
                if (!this.userInfo.lastName) {
                    return this.$error(this.$t('error_last_name_empty'));
                }
                if (!this.userInfo.password) {
                    return this.$error(this.$t('error_password_empty'));
                } else {
                    if (!this.checkPassword(this.userInfo.password)) {
                        return this.$error(this.$t('error_password_invalid'));
                    }
                }
                if (!this.userInfo.rePassword) {
                    return this.$error(this.$t('error_repassword_empty'));
                }
                if (this.userInfo.password || this.userInfo.rePassword) {
                    if (this.userInfo.password !== this.userInfo.rePassword)
                        return this.$error(this.$t('error_confirm_new_password'));
                }
                if (!this.userInfo.birthdate) {
                    return this.$error(this.$t('error_birth_date_empty'));
                }
                if (!this.userInfo.gender) {
                    return this.$error(this.$t('error_gender_empty'));
                }
                const $loading = this.$loading.show();
                this.$axios
                    .post(this.$api.ACCOUNT_UPDATE_USER, this.userInfo)
                    .then((_) => {
                        $loading.hide();
                        this.$router.push('/');
                    })
                    .catch((ex) => {
                        $loading.hide();
                        const error = this.$api.getApiError(ex);
                        if (error.error === 'invalid_grant') {
                            this.$error(this.$t(error.error_description));
                        } else {
                            this.$error(error);
                        }
                    });
            },
            cancel() {
                this.$cookie.delete(this.$cookieConstants.REGISTER_USER_NAME);
                this.step = 1;
            },
        },
    };
</script>
