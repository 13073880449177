<template>
  <main role="main">
    <section class="jumbotron text-center">
      <div class="container">
        <h1 class="jumbotron-heading">
          {{$t("set_password")}}
        </h1>
      </div>
    </section>

    <div class="album py-5 bg-light">
      <div class="container">
        <div class="row">
          <div class="col-md-4 col-centered">
            <form class="form-signin">
              <div class="wrap-input100 m-b-25">
                <input
                  v-model="form.password"
                  class="input100"
                  type="password"
                  name="pass"
                  :placeholder="$t('password')"
                >
                <span class="focus-input100" />
              </div>
              <div class="wrap-input100 m-b-25">
                <input
                  v-model="form.rePassword"
                  class="input100"
                  type="password"
                  name="pass"
                  :placeholder="$t('repassword')"
                >
                <span class="focus-input100" />
              </div>

              <div class="container-login100-form-btn">
                <button
                  class="login100-form-btn"
                  type="button"
                  @click="setPassword"
                >
                  {{$t("set_password")}}
                </button>
              </div>
              <div class="container-login100-form-btn mt-2">
                <router-link :to="localePath('/manager/profile')">
                  {{ $t("cancel") }}
                </router-link>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>
<style>
.col-centered {
  float: none;
  margin: 0 auto;
}
</style>
<script>
export default {
  layout: 'manager',
  data() {
    return {
      form: {},
    };
  },
  methods: {
    setPassword() {
      const $loading = this.$loading.show();
      this.$axios
        .post(this.$api.USER_SET_PASSWORD, this.form)
        .then((response) => {
           $loading.hide();
          this.$success(this.$t('update_success'));
          this.$router.push(this.localePath('/mananger'));
        })
        .catch((ex) => {
          $loading.hide();
          const error = this.$api.getApiError(ex);
          if (error.error === 'invalid_grant') {
            this.$error(this.$t(error.error_description));
          } else {
            this.$error(error);
          }
        });
    },
  },
};
</script>
