/* global process */
import Vue from 'vue';
import axios from 'axios';
import VueCookie from 'vue-cookie';
import { API_URL } from './api';
import { cookieConstant } from '../constants/cookie';
import Auth from './auth';

const authSettings = new Auth().getSetting();
axios.defaults.baseURL = API_URL;

function setToken(accessToken) {
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
}

const accessToken = VueCookie.get(cookieConstant.ACCESSTOKEN);
if (accessToken) setToken(accessToken);

axios.setToken = (accessToken) => {
    setToken(accessToken);
};

let acessToken = null;
if (typeof (localStorage) !== 'undefined') {
    try {
        const oidcUser = JSON.parse(localStorage.getItem(`oidc.user:${authSettings.authority}:${authSettings.client_id}`) || null);
        if (oidcUser) { acessToken = oidcUser.access_token; }
    } catch (e) {

    }
}
if (acessToken) { setToken(acessToken); }

axios.setHeader = (newLocale) => {
    axios.defaults.headers.common['Accept-Language'] = newLocale;
};

axios.interceptors.response.use((response) => {
    return response.data;
}, (error) => {
    return Promise.reject(error);
});

export default axios;

Vue.prototype.$axios = axios;
Vue.use(axios);
