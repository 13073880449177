<template>
    <main role="main">
        <section class="jumbotron text-center">
            <div class="container">
                <h1 class="jumbotron-heading">
                    {{$t("AccessToken")}}
                </h1>
            </div>
        </section>

        <div class="album py-5 bg-light">
            <div class="container">
                <div class="row">
                    <div class="col-md-12">
                        {{token}}
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>
<script>
    export default {
        layout: 'manager',
        data() {
            return { token: '' }
        },
        created() {
            this.$auth.getAcessToken().then(token => {
                this.token = token;
            });
        },
    };
</script>
