<template>
  <div>
    <section class="jumbotron text-center">
      <div class="container">
        <h1 class="jumbotron-heading">
          {{$t('account_recovery_ways')}}
        </h1>
        <p>Các tùy chọn này có thể dùng để đảm bảo rằng bạn chính là người đăng nhập hoặc để liên hệ với bạn nếu có hoạt động đáng ngờ trong tài khoản của bạn</p>
      </div>
    </section>
    <div class="album py-5 bg-light">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-8 border">
            <div
              class="container-login 100-form-btn"
              v-if="!type"
            >
              <div
                class="row p-4 recovery-item"
                style="border-bottom:1px solid #eaeaea;"
                @click="update('phone_number_recovery')"
              >
                <div class="col-6">
                  {{$t('phone_number_recovery')}}
                </div>
                <div class="col-6">
                  <span v-if="mobileRecovery">{{mobileRecovery}}</span>
                  <span v-else>Chưa có số điện thoại</span>
                  <a
                    href="javascript:;"
                    style="float:right"
                  >
                    <i class="fa fa-angle-right"></i>
                  </a>
                </div>
              </div>
              <div
                class="row p-4 recovery-item"
                @click="update('email_recovery')"
              >
                <div class="col-6">
                  {{$t('email_recovery')}}
                </div>
                <div class="col-6">
                  <span v-if="emailRecovery">{{emailRecovery}}</span>
                  <span v-else>Chưa có email</span>
                  <a
                    href="javascript:;"
                    style="float:right"
                  >
                    <i class="fa fa-angle-right"></i>
                  </a>
                </div>
              </div>
            </div>
            <div
              class="container-login 100-form-btn"
              v-if="type =='email_recovery'"
            >
              <div class="row p-4 recovery-item">
                <div class="form-group col-12">
                  <label>Cập nhật email khôi phục</label>
                  <input
                    type="email"
                    v-model="value"
                    class="form-control"
                  />
                </div>
                <div class="col-12 text-right">
                  <button
                    class="btn btn-danger mr-3"
                    @click="type =''"
                  >{{$t('cancel')}}</button>
                  <button
                    class="btn btn-primary"
                    @click="save()"
                  >{{$t('update')}}</button>
                </div>
              </div>
            </div>
            <div
              class="container-login 100-form-btn"
              v-if="type =='phone_number_recovery'"
            >
              <div class="row p-4 recovery-item">
                <div class="form-group col-12">
                  <label>Cập nhật số điện thoại khôi phục</label>
                  <input
                    type="text"
                    v-model="value"
                    class="form-control"
                  />
                </div>
                <div class="col-12 text-right">
                  <button
                    class="btn btn-danger mr-3"
                    @click="type =''"
                  >{{$t('cancel')}}</button>
                  <button
                    class="btn btn-primary"
                    @click="save()"
                  >{{$t('update')}}</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  layout: 'manager',
  data() {
    return {
      value: '',
      type: '',
      userClaim: [],
    };
  },
  computed: {
    emailRecovery() {
      var obj = this.userClaim.find((k) => k.type == 'email_recovery');
      if (obj) return obj.value;
      return '';
    },
    mobileRecovery() {
      var obj = this.userClaim.find((k) => k.type == 'phone_number_recovery');
      if (obj) return obj.value;
      return '';
    },
  },
  created() {
    this.getUserClaim();
  },
  methods: {
    update(type) {
      this.type = type;
      if (this.type == 'email_recovery') this.value = this.emailRecovery;
      else this.value = this.mobileRecovery;
    },
    save() {
      const $loading = this.$loading.show();
      this.$axios
        .post(this.$api.ACCOUNT_UPDATE_CLAIM, { claimType: this.type, claimValue: this.value })
        .then((data) => {
          $loading.hide();
          this.$success(this.$t('update_success'));
          this.type = '';
          this.getUserClaim();
        })
        .catch((ex) => {
          $loading.hide();
          const error = this.$api.getApiError(ex);
          this.$error(error);
        });
    },
    getUserClaim() {
      const $loading = this.$loading.show();
      this.$axios
        .get(this.$api.ACCOUNT_GET_CLAIM)
        .then((data) => {
          $loading.hide();
          this.userClaim = data.result;
        })
        .catch((ex) => {
          $loading.hide();
        });
    },
  },
};
</script>

<style scoped>
.recovery-item {
  cursor: pointer;
}

.recovery-item:hover {
  background: #eeeeee;
  color: #0558be;
}
</style>
