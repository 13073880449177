
import AuthService from '../../plugins/auth'
import axios from '../../plugins/axios';
import apiUrl from '../../constants/api'
export const logoutAll = ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
        axios.get(apiUrl.USER_LOGOUT_ALL).then(_ => {
            new AuthService().signOut();
            return resolve(dt);
        }).catch(err => {
            return reject(err);
        });
    })
}
