<template>
  <div>
    <recaptcha
      :siteKey="appSettings.configs.recaptchaSiteKey"
      action="login"
      @validate="setCaptcha"
      @error="captchaError"
    />
  </div>
</template>

<script>
import recaptcha from "../components/common/recaptcha/recaptcha";

export default {
  methods: {
    setCaptcha(data) {
      console.log(data);
      if (typeof window !== undefined)
        window.flutter_inappwebview.callHandler("captchaValid", data);
    },
    captchaError(data) {
      if (typeof window !== undefined)
        window.flutter_inappwebview.callHandler("captchaError", data);
    },
  },
  components: {
    recaptcha,
  },
  created() {
    console.log(this.appSettings.configs.recaptchaSiteKey);
  },
};
</script>
