// https://github.com/ankurk91/vue-toast-notification
import Vue from 'vue';
import VueToast from 'vue-toast-notification';
// Import one of the available themes
// import 'vue-toast-notification/dist/theme-default.css';
import 'vue-toast-notification/dist/theme-sugar.css';

Vue.use(VueToast);

Vue.prototype.$error = (msg) => {
  Vue.$toast.clear();
  Vue.$toast.open({
    message: msg,
    type: 'error',
    position: 'top',
    duration: 3000,
  });
};

Vue.prototype.$success = (msg) => {
  Vue.$toast.clear();
  Vue.$toast.open({
    message: msg,
    type: 'success',
    position: 'top',
    duration: 3000,
  });
};
