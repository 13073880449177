<template>
    <div>
        <div style="margin:100px auto; text-align:center;" v-if="!error">
            <div class="lds-dual-ring"></div>
        </div>
        <div style=" margin: 100px auto; text-align: center; color: red; border: solid 1px #ffdbdb; padding: 20px; background: #fdeeee; width: 615px; border-radius: 5px;" v-if="error">Vui lòng kiểm tra giờ trên máy tính của bạn có bị chậm hay không? Sau đó <router-link to="/" style="display:inline">thử lại</router-link>.</div>
    </div>
</template>
<style>
    .lds-dual-ring { display: inline-block; width: 80px; height: 80px; }
        .lds-dual-ring:after { content: " "; display: block; width: 64px; height: 64px; margin: 8px; border-radius: 50%; border: 6px solid #6e4c40; border-color: #6e4c40 transparent #6e4c40 transparent; animation: lds-dual-ring 1.2s linear infinite; }

    @keyframes lds-dual-ring {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
    }
</style>
<script type="text/javascript">
    export default {
        data() {
            return {
                error: false
            }
        },
        created() {
            this.$auth.signinRedirectCallback().then(() => {
                this.$router.push('/');
            }).catch((err) => {
                console.log(err.message);
                if (err.message.lastIndexOf('iat is in the future') != -1) {
                    try {
                        localStorage.clear();
                    } catch (ex) {
                        console.log(ex);
                    }
                    this.error = true;
                } else {
                    this.$router.push('/error?e=' + err.message);
                }
            });
        },
    };
</script>
