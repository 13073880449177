<template>
    <main class="content-page">
        <div class="container">
            <div class="main-ct">
                <div class="box">
                    <div class="box-head">
                        <h2>
                            {{$t('point_history')}}
                        </h2>
                        <div class="user-point">Điểm còn lại: {{point}}</div>
                    </div>
                    <table class="tbl-pay w-100">
                        <thead>
                            <tr>
                                <th>
                                    Thời gian
                                </th>
                                <th>
                                    Khối chức năng
                                </th>
                                <th>
                                    Hành động
                                </th>
                                <th>
                                    Điểm
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <template v-for="item in listData">
                                <tr :key="item.id">
                                    <td class="pl-2"><span>{{item.createdDate | moment("HH:mm DD-MM-YYYY")}}</span></td>
                                    <td><um-web-block :value="item.blockId" :display="true" /></td>
                                    <td><span>{{item.actionName}} <b>{{item.objectTitle}}</b></span></td>
                                    <td v-if="item.point > 0">+ <b class="color-green">{{item.point}}</b></td>
                                    <td v-else>- <b class="color-danger">{{item.point}}</b></td>
                                </tr>
                                <tr class="no-border" :key="`key_${item.id}`"></tr>
                            </template>
                        </tbody>
                    </table>
                    <div class="btn-area mt-4">
                        <button type="button" class="btn-view-more" v-if="total > objData.pageIndex * objData.pageSize" @click="pageChange()">Xem thêm</button>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>
<style scoped>
    .tbl-pay tbody tr td, .tbl-pay thead th {
        font-size: 15px;
    }
</style>
<script>
    export default {
        layout: 'manager',
        middleware: 'authenticated',
        data() {
            return {
                objData: {
                    pageIndex: 1,
                    pageSize: 10,
                },
                total: 0,
                listData: [],
                point: 0
            };
        },
        created() {
            this.getData();
            this.getUserPoint();
        },
        methods: {
            pageChange(page) {
                this.objData.pageIndex++;
                this.getData();
            },
            getData() {
                const $loading = this.$loading.show();
                this.$axios
                    .post(this.$api.USER_POINT_HISTORY,  this.objData)
                    .then((response) => {
                        $loading.hide();
                        this.listData.push(...response.result.data);
                        this.total = response.result.totalRows;
                    })
                    .catch((ex) => {
                        $loading.hide();
                        const error = this.$api.getApiError(ex);
                        this.$error(error);
                    });
            },
             getUserPoint() {
                this.$axios
                    .get(this.$api.USER_POINT)
                    .then((response) => {
                        this.point = response.result;
                    })
                    .catch(() => {
                    });
            }
        },
    };
</script>
