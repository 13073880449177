<template>
    <layout-main>
        <h2 class="name-page" slot="left">Cài đặt mật khẩu</h2>
        <div class="block-content-tab-follow">
            <div class="item-content-tab-follow ct-style-block">
                <div class="block-edit-info-page">
                    <div class="content-edit-info-page">
                        <div class="block-lst-item-password">
                            <div class="block-lst-yc">
                                <p class="name-info-password">Yêu cầu người dùng đặt mật khẩu mới khi đăng nhập lần đầu</p>
                                <div class="lst-choose-tt-radio">
                                    <div class="item-choose-page radio-type">
                                        <input type="radio" name="OptionPage" id="lb4" v-model="data.requireChangePasswordFirsLogin" :value="true">
                                        <label for="lb4">
                                            <span class="icon"></span>
                                            <span class="name">Có</span>
                                        </label>
                                    </div>
                                    <div class="item-choose-page radio-type">
                                        <input type="radio" name="OptionPage" id="lb5" v-model="data.requireChangePasswordFirsLogin" :value="false">
                                        <label for="lb5">
                                            <span class="icon"></span>
                                            <span class="name">Không</span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div class="block-qt-password">
                                <p class="name-info-password"></p>
                                <div class="lst-choose-item-permission">
                                    <div class="item-permission">
                                        <div class="box-check-type">
                                            <input type="checkbox" id="check-1" v-model="data.numberOfCharacters">
                                            <label for="check-1">
                                                <span class="check">
                                                    <img src="@/assets/img-fix/icon-check.svg" alt="">
                                                </span>
                                                <span class="checked">
                                                    <img src="@/assets/img-fix/icon-check-active.svg" alt="">
                                                </span>
                                                <span class="txt">Tối thiếu 6 ký tự</span>
                                            </label>
                                        </div>
                                    </div>
                                    <div class="item-permission">
                                        <div class="box-check-type">
                                            <input type="checkbox" id="check-2" v-model="data.passwordIncludeSpecialCharacter">
                                            <label for="check-2">
                                                <span class="check">
                                                    <img src="@/assets/img-fix/icon-check.svg" alt="">
                                                </span>
                                                <span class="checked">
                                                    <img src="@/assets/img-fix/icon-check-active.svg" alt="">
                                                </span>
                                                <span class="txt">Ít nhất một ký tự đặc biệt</span>
                                            </label>
                                        </div>
                                    </div>
                                    <div class="item-permission">
                                        <div class="box-check-type">
                                            <input type="checkbox" id="check-7" v-model="data.passwordIncludeUpercaseCharacter">
                                            <label for="check-7">
                                                <span class="check">
                                                    <img src="@/assets/img-fix/icon-check.svg" alt="">
                                                </span>
                                                <span class="checked">
                                                    <img src="@/assets/img-fix/icon-check-active.svg" alt="">
                                                </span>
                                                <span class="txt">Ít nhất một ký tự viết hoa</span>
                                            </label>
                                        </div>
                                    </div>
                                    <div class="item-permission">
                                        <div class="box-check-type">
                                            <input type="checkbox" id="check-8" v-model="data.passwordIncludeNumberCharacter">
                                            <label for="check-8">
                                                <span class="check">
                                                    <img src="@/assets/img-fix/icon-check.svg" alt="">
                                                </span>
                                                <span class="checked">
                                                    <img src="@/assets/img-fix/icon-check-active.svg" alt="">
                                                </span>
                                                <span class="txt">Ít nhất một ký tự là số</span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="block-gh-password">
                                <div class="item-gh-password">

                                    <div class="item-choose-option-dvc">
                                        <p class="name-info-password">Giới hạn thời gian bắt buộc đổi mật khẩu mới (ngày)</p>
                                        <div class="ct-input-info-new content-choose-option-dvc">
                                            <input type="number" class="" v-model="data.timeChangePassword">
                                        </div>
                                    </div>
                                    <div class="item-choose-option-dvc">
                                        <p class="name-info-password">Khóa tài nếu nhập sai mật khẩu nhiều lần</p>
                                        <div class="content-choose-option-dvc">
                                            <select name="" id="" class="ct-select" v-model="data.numberWrongPassword">
                                                <option value="1">1 lần</option>
                                                <option value="2">2 lần</option>
                                                <option value="3">3 lần</option>
                                                <option value="5">5 lần</option>
                                            </select>
                                            <span class="icon-slect">
                                                <svg width="9" height="5" viewBox="0 0 9 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M8.88018 0.122028C8.72044 -0.0406667 8.46141 -0.0406945 8.30164 0.122056L4.5001 3.99408L0.698359 0.122028C0.538623 -0.0406667 0.279586 -0.0406945 0.119823 0.122056C-0.0399409 0.284778 -0.0399409 0.548583 0.119823 0.711306L4.21084 4.87797C4.28756 4.95611 4.39161 5 4.5001 5C4.60859 5 4.71266 4.95608 4.78935 4.87794L8.88015 0.711278C9.03994 0.548583 9.03994 0.28475 8.88018 0.122028Z" fill="#414B5B"></path>
                                                </svg>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="bottom-popup-action">
                        <div class="left-bottom-popup-action">
                            <div class="btn-cancel">
                                <a href="javascript:;" @click="setDefaultConfig" class="btn-text">Đặt lại mặc định</a>
                            </div>
                        </div>
                        <div class="right-bottom-popup-action">
                            <div class="btn-save">
                                <a href="javascript:;" @click="saveConfig" class="btn-bg-linear">Lưu</a>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </layout-main>
</template>

<script>
    import { configPassword} from '../../constants/enumConfig.js'
    export default {
        components: {
        },
        name: "User",
        data() {
            return {
                data: {
                    requireChangePasswordFirsLogin: true,
                    numberOfCharacters: true,
                    passwordIncludeSpecialCharacter: true,
                    passwordIncludeUpercaseCharacter: true,
                    passwordIncludeNumberCharacter: true,
                    timeChangePassword: 90,
                    numberWrongPassword: 5
                }
            };
        },
        computed: {
            dataConfig() {
                return this.appSettings.configs.data.result.filter(o => o.typeName === 'Security')
            }
        },
        watch: {
        },
        methods: {
            init() {
                if (this.dataConfig.find(o => o.key === configPassword.RequireChangePasswordFirstLogin)) {
                    this.data.requireChangePasswordFirsLogin = this.dataConfig.find(o => o.key === configPassword.RequireChangePasswordFirstLogin).value === 'true'
                }
                if (this.dataConfig.find(o => o.key === configPassword.NumberOfCharacters)) {
                    this.data.numberOfCharacters =  this.dataConfig.find(o => o.key === configPassword.NumberOfCharacters).value === 'true'
                }
                if (this.dataConfig.find(o => o.key === configPassword.PasswordIncludeSpecialCharacter)) {
                    this.data.passwordIncludeSpecialCharacter = this.dataConfig.find(o => o.key === configPassword.PasswordIncludeSpecialCharacter).value === 'true'
                }
                if (this.dataConfig.find(o => o.key === configPassword.PasswordIncludeNumberCharacter)) {
                    this.data.passwordIncludeNumberCharacter = this.dataConfig.find(o => o.key === configPassword.PasswordIncludeNumberCharacter).value === 'true'
                }
                if (this.dataConfig.find(o => o.key === configPassword.TimeChangePassword)) {
                    this.data.timeChangePassword = Number(this.dataConfig.find(o => o.key === configPassword.TimeChangePassword).value)
                }
                if (this.dataConfig.find(o => o.key === configPassword.NumberWrongPassword)) {
                    this.data.numberWrongPassword = Number(this.dataConfig.find(o => o.key === configPassword.NumberWrongPassword).value)
                }
            },
            saveConfig() {
                var list =[]
                for (const property in this.data) {
                    list.push({
                        key: property,
                        value: this.data[property]+''
                    })
                }
                this.$axios.post(this.$api.CONFIG_CREATE_FOR_PASSWORD, { listData: list}).then(() => {
                    this.$message("Lưu thành công")
                })
            },
            setDefaultConfig() {
                this.data = {
                    requireChangePasswordFirsLogin: true,
                    numberOfCharacters: true,
                    passwordIncludeSpecialCharacter: true,
                    passwordIncludeNumberCharacter: true,
                    timeChangePassword: 90,
                    numberWrongPassword: 5
                }
            }
        },
        created() {
            this.init();
        },
    };
</script>
<style>
    .vue-treeselect__control {
        height: 50px !important;
    }
</style>
