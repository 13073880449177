<template>
    <div class="wrap" v-if="appSettings">
        <div class="wp-login-page">
            <div class="texture-login-top">
                <img src="@/assets/img-fix/texture-top-login.png" alt="">
            </div>
            <div class="texture-login-bottom">
                <img src="@/assets/img-fix/texture-bottom-login.png" alt="">
            </div>
            <div class="layout-login-page">
                <div class="logo-company" v-if="false">
                    <a href="" class="logo-login">
                        <img src="@/assets/img-fix/logo-white.svg" alt="">
                    </a>
                    <div class="slogan">Trung tâm giám sát dịch vụ công quốc gia</div>
                </div>
                <div class="content-login-page" v-show="mode==='login'">
                    <div class="box-login-ct">
                        <div class="title-login">
                            <h2 class="txt-big-title-login">Đăng nhập</h2>
                            <p class="txt-small-title-login">Xin chào! Rất vui được gặp lại bạn.</p>
                        </div>
                        <div class="content-box-ct">
                            <div class="form-info-new">
                                <div class="ct-input-info-new">
                                    <label class="label-bold">Tên đăng nhập</label>
                                    <input type="text" v-model="form.username"
                                           id="ipt-emailorphone"
                                           name="email"
                                           autofocus>
                                </div>
                            </div>
                            <div class="form-info-new" :class="{'form-error':showError}">
                                <div class="ct-input-info-new">
                                    <label class="label-bold">Mật khẩu</label>
                                    <input type="password" class="" :type="isHidePassword?'password':'text'" v-model="form.password" v-on:keydown.enter="loginByAccount">
                                    <div class="lst-option-icon-view">
                                        <div class="item-view-pass icon-show-pass" v-show="!isHidePassword">
                                            <svg width="20" height="12" viewBox="0 0 20 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M19.8381 5.54157C16.8172 1.86604 13.5556 0 10.1347 0C8.77943 0 7.40782 0.298889 6.05253 0.884551C4.98708 1.34904 3.9298 1.99125 2.91334 2.79502C1.19474 4.15618 0.182357 5.51329 0.141535 5.56984C-0.0544098 5.83642 -0.0462454 6.19993 0.161946 6.45843C3.17868 10.138 6.44442 12 9.86529 12C11.2206 12 12.5922 11.7011 13.9475 11.1154C15.0129 10.651 16.0702 10.0088 17.0867 9.20498C18.8053 7.84382 19.8176 6.48671 19.8585 6.43016C20.0544 6.16358 20.0462 5.80007 19.8381 5.54157ZM16.1355 8.09424C14.7149 9.21306 12.4779 10.5459 9.86529 10.5459C7.0445 10.5459 4.29311 9.01515 1.68052 5.99394C2.09282 5.51329 2.84802 4.70953 3.8604 3.90979C5.281 2.79098 7.51803 1.45809 10.1306 1.45809C12.9514 1.45809 15.7028 2.98889 18.3154 6.0101C17.9031 6.49074 17.1479 7.29855 16.1355 8.09424Z" fill="#777E8A"></path>
                                                <path d="M12.7065 5.27499C12.3024 5.27499 11.9717 5.60215 11.9717 6.00202C11.9717 7.08044 11.0859 7.95692 9.99592 7.95692C8.90598 7.95692 8.02014 7.08044 8.02014 6.00202C8.02014 4.92359 8.90598 4.04712 9.99592 4.04712C10.4001 4.04712 10.7307 3.71996 10.7307 3.32009C10.7307 2.92023 10.4001 2.59307 9.99592 2.59307C8.09362 2.59307 6.55056 4.12386 6.55056 6.00202C6.55056 7.88017 8.09362 9.41097 9.99592 9.41097C11.8982 9.41097 13.4413 7.88017 13.4413 6.00202C13.4413 5.60215 13.1106 5.27499 12.7065 5.27499Z" fill="#777E8A"></path>
                                            </svg>
                                        </div>
                                        <div class="item-view-pass" v-show="!isHidePassword">
                                            <svg width="20" height="15" viewBox="0 0 20 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M12.483 7.66846L13.6189 6.53252C13.7016 6.84174 13.75 7.16502 13.75 7.50002C13.75 9.56783 12.0678 11.25 10 11.25C9.665 11.25 9.34172 11.2016 9.0325 11.1189L10.1684 9.98298C11.4105 9.89829 12.3983 8.91048 12.483 7.66846ZM19.9213 7.19643C19.8444 7.05815 18.7541 5.14768 16.6228 3.52862L15.7133 4.43815C17.2673 5.58081 18.252 6.91346 18.6397 7.50096C17.8989 8.62892 14.993 12.5 10 12.5C9.25125 12.5 8.555 12.4042 7.89984 12.2514L6.87281 13.2784C7.81703 13.568 8.85484 13.75 10 13.75C16.5427 13.75 19.7864 8.04627 19.9213 7.80361C20.0263 7.61471 20.0263 7.38533 19.9213 7.19643ZM17.3169 1.06691L3.56688 14.8169C3.44484 14.9389 3.28484 15 3.125 15C2.96516 15 2.80516 14.9389 2.68313 14.8169C2.43891 14.5727 2.43891 14.1772 2.68313 13.9331L4.4325 12.1838C1.60438 10.4764 0.167344 7.96314 0.07875 7.80361C-0.02625 7.61471 -0.02625 7.38518 0.07875 7.19627C0.213594 6.95377 3.45734 1.25004 10 1.25004C11.6917 1.25004 13.157 1.63597 14.4097 2.2066L16.4331 0.183164C16.6773 -0.0610545 17.0728 -0.0610545 17.3169 0.183164C17.5609 0.427382 17.5611 0.822849 17.3169 1.06691ZM5.36453 11.2517L6.94688 9.66939C6.50984 9.05627 6.25 8.30861 6.25 7.50002C6.25 5.43221 7.93219 3.75003 10 3.75003C10.8086 3.75003 11.5563 4.00987 12.1694 4.44675L13.4489 3.16722C12.438 2.76238 11.2892 2.50003 10 2.50003C5.00703 2.50003 2.10109 6.37112 1.36047 7.49908C1.83078 8.21189 3.18266 10.0163 5.36453 11.2517ZM7.85422 8.76189L11.2619 5.35424C10.8902 5.13471 10.4622 5.00003 10 5.00003C8.62156 5.00003 7.5 6.12159 7.5 7.50002C7.5 7.96221 7.63469 8.39017 7.85422 8.76189Z" fill="#777E8A"></path>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                                <p class="txt-fill-fail" v-if="showError">{{errorText}}</p>
                            </div>
                        </div>
                        <div class="group-bottom-btn-popup">
                            <div class="left-bottom-btn-popup">
                                <div class="btn-cancel">
                                    <router-link to="/forgot-password">
                                        Quên mật khẩu?
                                    </router-link>
                                </div>
                            </div>
                            <div class="right-bottom-btn-popup">
                                <div class="btn-save">
                                    <a href="javascript:;" class="btn-bg-linear" @click="loginByAccount">
                                        <span class="txt">Truy cập</span>
                                        <span class="icon">

                                            <svg width="16" height="11" viewBox="0 0 16 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M15.7871 4.85381L10.6961 0.187141C10.5589 0.0657028 10.3752 -0.00149374 10.1845 2.52016e-05C9.99384 0.00154415 9.81143 0.0716569 9.67659 0.195262C9.54175 0.318868 9.46526 0.486076 9.4636 0.660874C9.46194 0.835672 9.53525 1.00407 9.66773 1.12981L13.5172 4.65847H0.72728C0.534393 4.65847 0.349407 4.72871 0.213015 4.85374C0.0766239 4.97876 0 5.14833 0 5.32514C0 5.50195 0.0766239 5.67152 0.213015 5.79655C0.349407 5.92157 0.534393 5.99181 0.72728 5.99181H13.5172L9.66773 9.52047C9.59827 9.58197 9.54286 9.65553 9.50474 9.73687C9.46663 9.81821 9.44657 9.90569 9.44573 9.99421C9.44489 10.0827 9.46329 10.1705 9.49986 10.2524C9.53643 10.3344 9.59043 10.4088 9.65872 10.4714C9.727 10.534 9.8082 10.5835 9.89758 10.617C9.98696 10.6505 10.0827 10.6674 10.1793 10.6666C10.2759 10.6659 10.3713 10.6475 10.46 10.6125C10.5488 10.5776 10.629 10.5268 10.6961 10.4631L15.7871 5.79647C15.9234 5.67146 16 5.50192 16 5.32514C16 5.14837 15.9234 4.97883 15.7871 4.85381Z" fill="white"></path>
                                            </svg>

                                        </span>
                                    </a>
                                </div>
                            </div>

                        </div>
                        <div class="group-bottom-btn-popup">
                            <!--<div class="left-bottom-btn-popup">
                                <facebook-login :app-id="appSettings.configs.facebookAppId"
                                                @login="loginViaFacebook" class="mr-3" />
                            </div>-->
                            <div class="right-bottom-btn-popup">
                                <google-login :client-id="appSettings.configs.googleClientId"
                                              :api-key="appSettings.configs.googleAPIKey"
                                              @login="loginViaGoogle" v-if="appSettings.configs.googleClientId" />
                            </div>
                        </div>
                    </div>
                </div>
                <changePasswordFirstLogin v-if="mode==='FirstLogin' || mode==='Expires'" :mode="mode" :username="form.username"></changePasswordFirstLogin>
            </div>
        </div>
    </div>

    <!--<div class="container" v-if="appSettings">

        <div class="row">-->
    <!--<div class="col-md-6 col-lg-7 box-l">
        <div class="img-area">
            <img src="id/img-signup.jpg" alt="" class="w-100" />
        </div>
        <h2>{{ $t("system_name") }}</h2>
        <p>{{ $t("system_slogan") }}</p>
    </div>-->
    <!--<div class="col-md-12 col-lg-12 box-r fix-page-login">
        <div class="logo-m">
            <img src="id/@logo-mobile.jpg"
                 alt=""
                 class="w-100" />
        </div>
        <div class="inner">
            <template v-if="step == 1">
                <h3 class="text-left">{{ $t("login") }}</h3>-->
    <!--<span class="txt text-left">{{ $t("welcome_back") }} </span>-->
    <!--<div class="custom-input">
        <input v-model="form.username"
               id="ipt-emailorphone"
               class="ipt"
               type="text"
               name="email"
               v-on:keydown.enter.prevent="checkAccount" autofocus />
        <label for="ipt-emailorphone" class="lbl">
            {{
            $t("email_or_phonenumber")
            }}
        </label>
    </div>
    <div class="btn-area">
        <button type="button" class="btn" @click="checkAccount">
            {{ $t("continue") }}
        </button>
    </div>
    <div class="another-choice d-flex flex-column" v-if="false">
        <div class="d-flex flex-wrap justify-content-center mt-3">
            <facebook-login :app-id="appSettings.configs.facebookAppId"
                            @login="loginViaFacebook" />

            <google-login :client-id="appSettings.configs.googleClientId"
                          :api-key="appSettings.configs.googleAPIKey"
                          @login="loginViaGoogle" />
        </div>
        <span class="text-center line-h">{{ $t("or") }}</span>
        <div class="qr-code-login" :class="{'creat-qr': !countDownExpireQRCode}">
            <div class="qrcode-area" v-if="qrCode">-->
    <!--<img :src="qrCodeImage" width="150" class="qrcode" />-->
    <!--<vue-qrcode :value="qrCode"
                                class="qrcode"
                                :width="150" :margin="0" />
                </div>
                <div class="grup-qr-new" v-if="!countDownExpireQRCode">
                    <span class="txt-qr-finish">{{ $t("expired_qr_code") }}</span>
                    <a href="javascript:void(0)" @click="genQrCode()" class="create-code">{{ $t("regenerate_qr_code") }}</a>
                </div>
            </div>
            <p class="txt-note-qr">{{$t('scan_this_code_with')}} <span class="color-txt"> {{$t('bnd_app')}}</span> {{$t('for_mobile_devices_to_sign_in_now')}}.</p>
        </div>
    </template>
    <template v-else-if="step == 2">
        <h3>{{ $t("login") }}</h3>
        <input :value="form.username"
               type="text"
               name="email"
               disabled
               style="display: none" />
        <div class="custom-input">
            <input-password v-model="form.password" @enter="loginByAccount" :label="$t('password')" ref="txtPass" />-->
    <!--<input :value="form.username"
                       type="text"
                       name="email"
                       disabled
                       style="display: none" />
                <input v-model="form.password"
                       id="ipt-passw"
                       class="ipt"
                       type="password"
                       name="pass"
                       v-on:keydown.enter.prevent="loginByAccount" />
                <label for="ipt-passw" class="lbl">
                    {{
    $t("password")
                    }}
                </label>-->
    <!--</div>
    <div class="wrap-input100 validate-input m-b-25"
         data-validate="Enter password">
        ch
        <recaptcha :siteKey="appSettings.configs.recaptchaSiteKey" action="login" @validate="setCaptcha" :key="keyShowCaptcha" v-if="keyShowCaptcha" />
    </div>
    <div class="btn-area">
        <button type="button" class="btn" @click="loginByAccount">
            {{ $t("login") }}
        </button>
    </div>-->
    <!--<div class="txt-pass">
        <router-link to="/forgot-password">
            {{ $t("fogot_password") }}
        </router-link>
    </div>-->
    <!--<a href="javascript:void(0)"
       class="txt-choice"
       @click="changeLoginType('otp')">
        {{ $t("login_by_otp") }}
    </a>-->
    <!--</template>
    <template v-else-if="step == 3">
        <h3>{{ $t("login") }}</h3>
        <span class="txt-lbl">

            {{ $t("choose_method_receiving_authentication_code")}}
        </span>-->
    <!-- [D] Thêm class "open" vào class "selbox"  để mở selectbox -->
    <!--<um-web-select-ul :options="twoFactorTypes"
                          code="type"
                          label="title"
                          v-model="twoFa.codeReceiveMethod" />
        <div class="custom-input">
            <input type="text"
                   id="ipt-emailorphone"
                   class="ipt"
                   v-model="twoFa.twoFaCode"
                   @keydown.enter="loginByTwoFa" autofocus ref="txtTwoFaCode" />
            <label for="ipt-emailorphone" class="lbl">
                {{ $t("input_2fa_code")}}
            </label>
            <template v-if="twoFa.codeReceiveMethod != 'authenticator' && twoFa.codeReceiveMethod != 'recoveryCode'">
                <a href="javascript:;"
                   class="resend-authen-code disable"
                   v-if="seconds > 0">
                    {{ $t("otp_resend_in") }}
                    <span class="countdown" v-if="seconds > 0">({{ seconds }}s)</span>
                </a>
                <a href="javascript:;"
                   class="resend-authen-code"
                   @click="sendCode"
                   v-else>
                    {{isSendOtp ? $t("register_resend_otp") : $t("send_code")}}
                    <span class="countdown" v-if="seconds > 0">({{ seconds }}s)</span>
                </a>
            </template>
        </div>
        <template v-if="!twoFa.code_sent">
            <template v-if="!this.isLoginOTP">
                <div class="list-form-check">
                    <div class="form-check checkbox-check mb-3">
                        <input class="form-check-input" type="checkbox" v-model="twoFa.rememberMachine" id="checkbox-usefor" checked="">
                        <label class="form-check-label" for="checkbox-usefor">{{ $t("remember_device") }}</label>
                    </div>
                </div>
            </template>
            <div class="btn-area">
                <button type="button" class="btn" @click="loginByTwoFa">
                    {{ $t("login") }}
                </button>
            </div>
        </template>

        <a href="javascript:void(0)"
           @click="changeLoginType('password')"
           v-if="isLoginOTP && !this.isUserLoginOTP"
           class="txt-choice">{{ $t("login_by_password") }}</a>
    </template>
    <template v-else-if="step == 4">
        <div class="logo-m">
            <img src="id/@logo-mobile.jpg"
                 alt=""
                 class="w-100" />
        </div>
        <h3>{{ $t("login") }}</h3>
        <div>
            <span class="txt-lbl">{{ $t("scan_qr_code_log_in") }}</span>
            <div class="group-qr" :class="{'creat-qr': !countDownExpireQRCode}">
                <div class="qrcode-area">-->
    <!-- <vue-qrcode
      value="authenticatorUri"
      class="qrcode"
      :width="250"
    /> -->
    <!--<img :src="qrCodeImage" width="250" class="qrcode" />
                </div>
                <div class="grup-qr-new" v-if="!countDownExpireQRCode">
                    <span class="txt-qr-finish">{{ $t("expired_qr_code") }}</span>
                    <a href="javascript:void(0)" @click="genQrCode()" class="create-code">{{ $t("regenerate_qr_code") }}</a>
                </div>
            </div>
        </div>
        <a href="javascript:void(0)"
           class="txt-choice"
           @click="step = 1">{{ $t("login_by_password") }}</a>
    </template>-->
    <!--<p class="txt-register">
        {{ $t("register_call_to_action_msg") }}
        <router-link :to="localePath('/register') + '?returnUrl=' + returnUrl"
                     class="txt-link">{{ $t("register_now") }}</router-link>
    </p>-->
    <!--/d
                </div>


                /d
            </div>
        </div>
    </div>-->
</template>
<style type="text/css">
    .twofa-form.form-group { text-align: left; }

    .resend-authen-code { max-width: 120px !important; }
</style>
<script>
    import {
        ERROR_MULTI_TIME,
        CAPTCHA_INVALID,
        CAPTCHA_ERROR_EMPTY,
    } from "../constants/errorCode";
    import googleLogin from "../components/openid/google/google-login";
    import facebookLogin from "../components/openid/facebook/facebook-login";
    import recaptcha from '../components/common/recaptcha/recaptcha';
    import modalExpireChangePassword from '../components/layout/modal-expire-change-password';
    import { HubConnectionBuilder, LogLevel } from "@aspnet/signalr";
    import VueQrcode from 'vue-qrcode';
    import changePasswordFirstLogin from './changepassword-first-time-login'
    export default {
        data() {
            return {
                step: 0,
                twoFactorTypes: null,

                form: {
                    username: "",
                    password: "",
                },
                twoFa: {
                    twoFaCode: null,
                    rememberMachine: true,
                    useRecoveryCode: false,
                    code_sent: false,
                    codeReceiveMethod: "email",
                },
                user: {
                    name: "",
                },
                guest: false,
                tryGetAccessTokenCount: 0,
                isLoginOTP: true,
                seconds: 0,
                isUserLoginOTP: false,
                keyShowCaptcha: 0,
                authenticatorUri: "",
                isSendOtp: false,
                countDownExpireQRCode: 0,
                qrCodeImage: "",
                qrCode: "",
                isHidePassword: true,
                showError: false,
                errorText: "",
                mode: "login"
            };
        },
        components: { facebookLogin, googleLogin, recaptcha, VueQrcode, changePasswordFirstLogin, modalExpireChangePassword },
        computed: {
        },
        async created() {
            this.step = 1;
            this.$auth.getProfile(false).then((profile) => {
                if (profile) this.$router.push("/manager/info");
            });
            //this.genQrCode();
        },
        methods: {
            returnUrl() {
                if (this.$route.query.ReturnUrl) return this.$route.query.ReturnUrl;
                else return this.$route.query.redirect_uri || "";
            },
            loginByAccount() {
                this.login(this.form.username, this.form.password);
            },
            setCaptcha(data) {
                this.form.captcha = data;
            },
            loginQrStep() {
                this.step = 4;
                this.genQrCode();
            },
            genQrCode() {
                const $loading = this.$loading.show();
                this.$axios
                    .get(this.$api.ACCOUNT_GET_QRCODE)
                    .then((data) => {
                        $loading.hide();
                        if (data.result) {
                            let result = data.result;
                            this.qrCodeImage = result.image;
                            this.qrCode = result.code;
                            this.connectSignalR();
                            this.countDownExpireQRCode = result.time * 60;
                            this.countDownQRCode();
                        }
                    })
                    .catch((err) => {
                        $loading.hide();
                        this.$error(this.$api.getApiError(err));
                    });
            },

            countDownQRCode() {
                if (this.countDownExpireQRCode > 0) {
                    setTimeout(() => {
                        this.countDownExpireQRCode -= 1;
                        this.countDownQRCode();
                    }, 1000);
                }
            },

            loginQRCode() {
                const $loading = this.$loading.show();
                this.$axios
                    .get(this.$api.ACCOUNT_LOGIN_QRCODE + "?key=" + this.qrCode)
                    .then((data) => {
                        $loading.hide();
                        window.location = this.returnUrl();
                    })
                    .catch((err) => {
                        $loading.hide();
                        this.$error(this.$api.getApiError(err));
                    });
            },

            connectSignalR() {
                this.signalRConnection = new HubConnectionBuilder()
                    .withUrl(`${this.$config.realTimeEndpoint}?userId=` + this.qrCode)
                    .configureLogging(LogLevel.Information)
                    .build();
                this.signalRConnection.on("qrLogin", (data) => {
                    console.log("haha");
                    this.loginQRCode();
                });
                this.signalRConnection.start().then((_) => {
                    console.log("ok");
                });
            },

            changeLoginType(isQRCode) {
                if (isQRCode) this.getQRCodeImage();
                else this.qrCodeImage = "";
            },

            login(username, password, isExternal = false) {
                const $loading = this.$loading.show();
                this.$axios
                    .post(
                        isExternal
                            ? this.$api.ACCOUNT_LOGIN_EXTERNAL
                            : this.$api.ACCOUNT_LOGIN,
                        {
                            username,
                            password,
                            returnUrl: this.returnUrl(),
                            captcha: this.form.captcha,
                        }
                    )
                    .then((data) => {
                        $loading.hide();
                        if (data) {
                            if (data.result.isOk) {
                                //Hiển thị cảnh báo đổi mật khẩu nếu sắp hết hạn
                                window.location = data.result.redirectUrl;
                            } else if (data.result.requiresChangePassword) {
                                this.mode = data.result.type
                            }
                            else if (data.result.requiresTwoFactor) {
                                //Yêu cầu xác thực 2 bước
                                this.twoFactorTypes = data.result.twoFactorTypes;
                                if (this.twoFactorTypes && this.twoFactorTypes.length > 0) {
                                    this.twoFa.codeReceiveMethod = this.twoFactorTypes[0].type;
                                    if (this.twoFactorTypes.filter(k => k.type === 'authenticator').length > 0) {
                                        this.twoFactorTypes.push({ type: "recoveryCode", title: this.$t("use_recovery_code") })
                                    }
                                }
                                this.step = 3;
                                this.isLoginOTP = false;
                                setTimeout(() => {
                                    if (this.$refs.txtTwoFaCode)
                                        this.$refs.txtTwoFaCode.$el.focus();
                                }, 100);
                            }
                        } else {
                            this.$error(this.$t("login_invalid_info"));
                        }
                    })
                    .catch((ex) => {
                        $loading.hide();
                        this.showError = true;
                        let apiError = this.$api.getApiError(ex);
                        switch (apiError) {
                            case 'login_invalid_info':
                                this.errorText = "Mật khẩu hoặc tài khoản không chính xác. Vui lòng thử lại.."
                                break
                            case 'error_multi_time':
                                this.errorText = "Đăng nhập sai quá số lần cho phép"
                                break
                            case 'login_fail_multi':
                                this.errorText = "Đăng nhập sai quá số lần cho phép"
                                break
                            case 'account_is_locked':
                                this.errorText = "Tài khoản đã bị khóa"
                                break
                            default:
                                this.errorText = "Mật khẩu hoặc tài khoản không chính xác. Vui lòng thử lại.."
                        }
                        if (
                            apiError == ERROR_MULTI_TIME ||
                            apiError == CAPTCHA_INVALID ||
                            apiError == CAPTCHA_ERROR_EMPTY
                        ) {
                            this.keyShowCaptcha++;
                        }
                        /* this.$error(this.$t(apiError));*/
                    });
            },
            loginViaFacebook(info) {
                if (info && info.token && info.email && info.id) {
                    this.currentTypeViaLogin = "Facebook";
                    this.currentTokenLogin = info.token;
                    this.login("Facebook", info.token, true);
                } else {
                    console.log('fb_info', info)
                    this.$error(this.$t("login_invalid_external_info"));
                }
            },
            loginViaGoogle(info) {
                var userInfo = this.parseJwt(info.credential)
                if (info && userInfo.email && userInfo.sub) {
                    this.currentTypeViaLogin = "Google";
                    this.currentTokenLogin = info;
                    this.login("Google", info.credential, true);
                } else {
                    this.$error(this.$t("login_invalid_external_info"));
                }

            },
            parseJwt(token) {
                var base64Url = token.split('.')[1];
                var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
                var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
                    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
                }).join(''));

                return JSON.parse(jsonPayload);
            },
            loginByTwoFa() {
                if (!this.twoFa.twoFaCode) {
                    return this.$error(this.$t("please_enter_code_2fa_code"));
                }

                this.twoFa.useRecoveryCode = this.twoFa.codeReceiveMethod == 'recoveryCode';
                if (this.isLoginOTP) return this.loginByOTP();
                let data = {};
                let uri = "";
                if (this.twoFa.useRecoveryCode) {
                    data = {
                        recoveryCode: this.twoFa.twoFaCode,
                    };
                    uri = this.$api.ACCOUNT_LOGIN_2FA_RECOVERY_CODE;
                } else {
                    data = {
                        twoFactorCode: this.twoFa.twoFaCode,
                        rememberMachine: this.twoFa.rememberMachine,
                    };
                    uri = this.$api.ACCOUNT_LOGIN_2FA;
                }

                const $loading = this.$loading.show();
                this.$axios
                    .post(uri, data)
                    .then((data) => {
                        $loading.hide();
                        if (this.returnUrl()) window.location.href = this.returnUrl();
                    })
                    .catch((err) => {
                        $loading.hide();
                        this.$error(this.$api.getApiError(err));
                    });
            },
            sendCode() {
                if (this.isLoginOTP) return this.loginSendCodeOTP();
                if (!this.twoFa.codeReceiveMethod) {
                    return this.$error(this.$t("please_select_method_receive_2fa_code"));
                }
                const $loading = this.$loading.show();
                this.$axios
                    .post(this.$api.ACCOUNT_LOGIN_2FA_SEND_CODE, {
                        codeReceiveMethod: this.twoFa.codeReceiveMethod,
                    })
                    .then((data) => {
                        $loading.hide();
                        this.$success(this.$t("code_has_been_sent"));
                        this.setTimerOTP(data.result.data.expirySecond);
                        this.isSendOtp = true;
                    })
                    .catch((err) => {
                        $loading.hide();
                        this.$error(this.$api.getApiError(err));
                        this.isSendOtp = true;
                    });
            },
            loginSendCodeOTP() {
                const $loading = this.$loading.show();
                this.$axios
                    .post(this.$api.ACCOUNT_LOGIN_SEND_OTP, {
                        username: this.form.username,
                    })
                    .then((data) => {
                        $loading.hide();
                        this.$success(this.$t("code_has_been_sent"));
                        this.$cookie.set(
                            this.$cookieConstants.LOGIN_INFO,
                            JSON.stringify({
                                username: this.form.username,
                                times: data.result.times,
                                method: this.twoFa.codeReceiveMethod,
                            })
                        );
                        console.log(
                            JSON.parse(this.$cookie.get(this.$cookieConstants.LOGIN_INFO))
                        );
                        this.setTimerOTP(data.result.times);
                        this.isSendOtp = true;
                    })
                    .catch((err) => {
                        $loading.hide();
                        this.$error(this.$t(err.data));
                        this.isSendOtp = true;
                    });
            },
            loginByOTP() {
                if (!this.twoFa.twoFaCode) {
                    return this.$error(this.$t("otp_error_blank"));
                }
                let uri = this.$api.ACCOUNT_LOGIN_BY_OTP;
                let data = {
                    username: this.form.username,
                    OTP: this.twoFa.twoFaCode,
                    rememberMachine: this.twoFa.rememberMachine,
                    returnUrl: this.returnUrl,
                    otpType: this.twoFa.codeReceiveMethod,
                };

                const $loading = this.$loading.show();
                this.$axios
                    .post(uri, data)
                    .then(() => {
                        $loading.hide();
                        if (this.returnUrl) window.location.href = this.returnUrl;
                    })
                    .catch((err) => {
                        $loading.hide();
                        this.$error(this.$api.getApiError(err));
                    });
            },
            getOTPSupport() {
                const $loading = this.$loading.show();
                this.$axios
                    .get(
                        this.$api.ACCOUNT_LOGIN_OTP_SUPPORT +
                        "?userName=" +
                        this.form.username
                    )
                    .then((data) => {
                        $loading.hide();
                        this.twoFactorTypes = data.result;
                        this.twoFa.codeReceiveMethod = this.twoFactorTypes[0].type;
                    })
                    .catch((err) => {
                        $loading.hide();
                        this.$error(this.$api.getApiError(err));
                    });
            },

            checkAccount() {
                const $loading = this.$loading.show();
                this.$axios
                    .post(this.$api.ACCOUNT_LOGIN_CHECK, this.form)
                    .then((data) => {
                        $loading.hide();
                        console.log(data);
                        if (data.result && data.result.loginByOTP) {
                            this.isUserLoginOTP = true;
                            this.changeLoginType("otp");
                        } else {
                            this.step = 2;
                            if (data.result.isErrorMulti) this.keyShowCaptcha++;
                        }
                        setTimeout(() => {
                            if (this.$refs.txtPass && this.$refs.txtPass.$el.querySelector('input'))
                                this.$refs.txtPass.$el.querySelector('input').focus();
                        }, 100);
                    })
                    .catch((err) => {
                        $loading.hide();
                        this.$error(this.$api.getApiError(err));
                    });
            },
            setTimerOTP(seconds) {
                this.seconds = seconds;
                this.intervalTimer = setInterval(() => {
                    if (this.seconds <= 0) {
                        clearInterval(this.intervalTimer);
                    }

                    this.seconds--;
                }, 1000);
            },
            changeLoginType(type) {
                switch (type) {
                    case "otp":
                        this.isLoginOTP = true;
                        this.getOTPSupport();
                        this.step = 3;
                        break;
                    case "password":
                        this.step = 2;
                        this.isLoginOTP = false;
                    default:
                        break;
                }
            },
        },
    };
</script>
