import Vue from 'vue';
import alert from 'sweetalert2';

alert.setDefaults({
    width: '400px',
    focusConfirm: true
});

Vue.prototype.$alert = alert;

Vue.prototype.$confirm = (msg, okLabel, cancelLabel, title, html) => {
    return new Promise((resolve, reject) => {
        alert({
            title: title || 'Xác nhận',
            text: msg,
            html: html || '',
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: '#4cae4c',
            confirmButtonText: okLabel || 'Ok',
            cancelButtonText: cancelLabel || 'Hủy',
        }).then((res) => {
            if (res.value) {
                return resolve();
            }
        });
    });
}


Vue.prototype.$message = (msg, type, closeTime) => {
    return new Promise((resolve, reject) => {
        alert({
            type: type || 'success',
            title: msg || '',
            showConfirmButton: false,
            timer: closeTime || 1500
        });
    });
}


// Vue.prototype.$success = (msg, closeTime) => {
//     return new Promise((resolve, reject) => {
//         alert({
//             type: 'success',
//             title: msg || '',
//             showConfirmButton: false,
//             timer: closeTime || 1500
//         });
//     });
// }

// Vue.prototype.$error = (msg, closeTime) => {
   
//     return new Promise((resolve, reject) => {
//         alert({
//             type: 'error',
//             title: msg || '',
//             showConfirmButton: false,
//             timer: closeTime || 1500
//         });
//     });
// }

Vue.prototype.$info = (msg, closeTime) => {
    return new Promise((resolve, reject) => {
        alert({
            type: 'info',
            title: msg || '',
            showConfirmButton: false,
            timer: closeTime || 1500
        });
    });
}
