<template>
    <div class="d-flex date-birth">
        <!-- Day -->
        <um-web-select-ul class=" ml-0" :options="days" code="day" label="day" v-model="selectedDay"  style="min-width:85px;"/>

        <!-- Month -->
        <um-web-select-ul :options="months" code="id" label="month" v-model="selectedMonth" style="min-width:120px;"/>

        <!-- Year -->
        <um-web-select-ul :options="years" code="year" label="year" v-model="selectedYear" style="min-width:90px;"/>
    </div>
</template>

<script>
const defaultMonths = {
  0: 'January',
  1: 'February',
  2: 'March',
  3: 'April',
  4: 'May',
  5: 'June',
  6: 'July',
  7: 'August',
  8: 'September',
  9: 'October',
  10: 'November',
  11: 'December',
};

export default {
  name: 'DateDropdown',
  props: {
    value: {
      required: false,
    },
    default: {
      type: String,
      required: false,
    },
    min: {
      type: String,
      required: false,
    },
    max: {
      type: String,
      required: false,
    },
    monthsNames: {
      type: String,
      default:
        'Tháng 1, Tháng 2, Tháng 3, Tháng 4, Tháng 5, Tháng 6, Tháng 7, Tháng 8, Tháng 9, Tháng 10, Tháng 11, Tháng 12',
      required: false,
    },
    dateTimeFormat: {
      type: String,
      default: 'yyyy-mm-dd',
    },
  },

  data() {
    return {
      days: [],
      selectedDay: '',
      selectedMonth: '',
      selectedYear: '',
    };
  },

  computed: {
    // Returns if there are default date settings.
    initialDate() {
      return !!(this.default || this.min);
    },

    // The date property, converted to a date.
    specifiedDate() {
      return new Date(this.default);
    },

    // The minimum date the will allow user to select.
    minDate() {
      if (this.min) {
        return new Date(this.min);
      }
      return null;
    },

    // The maximum date the will allow user to select.
    maxDate() {
      if (this.max) {
        return new Date(this.max);
      }
      return null;
    },

    // Returns a formatted date e.g. 01.02.2018.
    calculatedDate() {
      const day = this.selectedDay >= 10 ? this.selectedDay : `0${this.selectedDay}`;
      const month =
        this.selectedMonth + 1 >= 10 ? this.selectedMonth + 1 : `0${this.selectedMonth + 1}`;

      const temp = this.dateTimeFormat.toLowerCase();
      return temp
        .replace('dd', day)
        .replace('mm', month)
        .replace('yyyy', this.selectedYear);
      // return `${day}.${month}.${this.selectedYear}`;
    },

    // The alternative names of months
    dividedNamesOfMonths() {
      if (this.monthsNames) {
        return this.monthsNames.split(','); // .replace(/\s/g, '')
      }
      return null;
    },

    // Computes a list of month.
    months() {
      const months = [];

      for (let i = 0; i < 12; i++) {
        if (this.dividedNamesOfMonths) {
            months.push({ id: i, month: this.dividedNamesOfMonths[i]});
        } else {
            months.push({ id: i, month: defaultMonths[i] });
        }
      }
        return months;
      //return months.map((month, index) => {
      //  return { month, selected: index === this.selectedMonth };
      //});
    },

    // Computes a list of years.
    years() {
      // Set the first year of the array.
      let firstYear;

      if (this.min) {
        firstYear = this.minDate.getFullYear();
      } else if (this.default) {
        firstYear = this.specifiedDate.getFullYear();
      } else {
        firstYear = new Date().getFullYear();
      }

      // Create a range of years to loop through which is either the maximum
      // date minus the first year, or simply 100.
      const through = this.max ? this.maxDate.getFullYear() + 1 - firstYear : 101;
      const years = [];

        for (let i = firstYear + through, len = firstYear; i > len; i--) {
        years.push(i);
      }

      return years.map((year) => {
        return { year, selected: year === this.selectedYear };
      });
    },
  },

  created() {
    // Set the date when the component was created

    this.setDate();
  },

  updated() {
    // Send the data on change
    this.sendDate();
  },

  methods: {
    getDays() {
      const days = [];
      const daysNumberOfMonth = new Date(this.selectedYear, this.selectedMonth + 1, 0).getDate();

      for (let i = 1; i < daysNumberOfMonth + 1; i++) {
        days.push(i);
      }

      return days.map((day) => {
        return { day, selected: days === this.selectedDay };
      });
    },

    updateDays() {
      this.days = this.getDays();
    },

    // Send data to upper component.
    sendDate() {
      const date = this.format ? this.format(this.calculatedDate) : this.calculatedDate;

      this.$emit('input', date);
    },

    // Set the initial date.
    setDate() {
      this.updateDays();

      // If a value property has been passed to the component, that will be
      // used instead of the current day.
      let date;
      if (this.value) {
        date = new Date(this.value);
      } else if (this.min && this.max && !this.default) {
        date = new Date(this.min);
      } else if (this.default) {
        date = new Date(this.default);
      } else {
        date = new Date();
      }

      if (this.initialDate) {
        this.selectedDay = date.getDate() + 1;
      } else {
        this.selectedDay = date.getDate();
      }

      this.selectedDay = date.getDate();
      this.selectedMonth = date.getMonth();
      this.selectedYear = date.getFullYear();

      this.sendDate();
    },
  },
};
</script>

<style scoped>
.date-dropdown-container {
  display: flex;
}
.date-dropdown-select {
  display: inline-block;
  border: 1px solid #e0e0e0;
  border-radius: 3px;
  padding: 8px;
  margin-right: 10px;
}
</style>
