import Vue from 'vue';
import Vuex from 'vuex';

const modules = {};
const context = require.context('.', true, /store\.js$/);

context.keys().forEach((key) => {
  const m = key.replace('./', '').replace('/store.js', '');
  modules[m] = context(key).default;
  modules[m].namespaced = true;
});
Vue.use(Vuex);

const store = new Vuex.Store({
  modules,
});

export default store;
