<template>
    <layout-main>
        <h2 class="name-page" slot="left">Quản lý tên miền CORS</h2>

        <div class="lst-filter-top">
            <div class="search-form-emc">
                <input type="text"
                       placeholder="Tìm kiếm ..."
                       v-model="filter.keyword" />
                <!-- Nút search -->
                <span class="search">
                    <svg width="14"
                         height="14"
                         viewBox="0 0 14 14"
                         fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                        <path d="M6.1653 0C2.76585 0 0 2.76585 0 6.1653C0 9.56495 2.76585 12.3306 6.1653 12.3306C9.56495 12.3306 12.3306 9.56495 12.3306 6.1653C12.3306 2.76585 9.56495 0 6.1653 0ZM6.1653 11.1924C3.39338 11.1924 1.13821 8.93725 1.13821 6.16533C1.13821 3.39341 3.39338 1.13821 6.1653 1.13821C8.93722 1.13821 11.1924 3.39339 11.1924 6.1653C11.1924 8.93722 8.93722 11.1924 6.1653 11.1924Z"
                              fill="#777E8A"></path>
                        <path d="M13.8333 13.0285L10.5704 9.76566C10.348 9.54332 9.98798 9.54332 9.76565 9.76566C9.54332 9.9878 9.54332 10.3482 9.76565 10.5704L13.0285 13.8333C13.1397 13.9444 13.2852 14 13.4309 14C13.5764 14 13.7221 13.9444 13.8333 13.8333C14.0556 13.6111 14.0556 13.2507 13.8333 13.0285Z"
                              fill="#777E8A"></path>
                    </svg>
                </span>
            </div>
            <!--<div class="search-form-emc">
                <select v-model="filter.clientId" class="ct-select">
                    <option value="0">Tất cả Client</option>
                    <option :value="item.id" v-for="item,index in listClient">{{item.clientId}}</option>
                </select>
            </div>-->
            <div class="group-btn-top">
                <div class="btn-add-new">
                    <a href="javascript:;"
                       @click="openPopup(0)"
                       class="btn-bg-linear-orange">
                        <span class="icon">
                            <svg width="16"
                                 height="16"
                                 viewBox="0 0 16 16"
                                 fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path d="M7.21641 1.68359V7.21641H1.68359C1.47577 7.21641 1.27646 7.29896 1.12951 7.44592C0.982557 7.59287 0.9 7.79218 0.9 8C0.9 8.20782 0.982557 8.40713 1.12951 8.55408C1.27646 8.70104 1.47577 8.78359 1.68359 8.78359H7.21641V14.3164C7.21641 14.5242 7.29896 14.7235 7.44592 14.8705C7.59287 15.0174 7.79218 15.1 8 15.1C8.20782 15.1 8.40713 15.0174 8.55408 14.8705C8.70104 14.7235 8.78359 14.5242 8.78359 14.3164V8.78359H14.3164C14.5242 8.78359 14.7235 8.70104 14.8705 8.55408C15.0174 8.40713 15.1 8.20782 15.1 8C15.1 7.79218 15.0174 7.59287 14.8705 7.44592C14.7235 7.29896 14.5242 7.21641 14.3164 7.21641H8.78359V1.68359C8.78359 1.47577 8.70104 1.27646 8.55408 1.12951C8.40713 0.982557 8.20782 0.9 8 0.9C7.79218 0.9 7.59287 0.982557 7.44592 1.12951C7.29896 1.27646 7.21641 1.47577 7.21641 1.68359Z"
                                      fill="white"
                                      stroke="white"
                                      stroke-width="0.2"></path>
                            </svg>
                        </span>
                        <span class="txt">Thêm mới</span>
                    </a>
                </div>
            </div>
        </div>
        <div class="block-table-ql-follow ct-style-block ct-table">
            <div class="block-table-user-ui">
                <table class="table">
                    <thead>
                        <tr>
                            <th class="stt">STT</th>
                            <th class="user-lg-tb">Origin</th>
                            <th class="user-lq">ClientId</th>
                            <th class="option-btn-action"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item, index) in listData" :key="index">
                            <td class="stt">
                                <div class="content-table">
                                    <p class="txt-content-table">
                                        {{ filter.pageSize * (filter.pageIndex - 1) + index + 1 }}
                                    </p>
                                </div>
                            </td>
                            <td class="user-lg-tb">
                                <div class="lst-avatar-login">
                                    <p class="txt-name">{{ item.origin }}</p>
                                </div>
                            </td>
                            <td class="user-lq">
                                <p class="txt-content-table">
                                    {{ listClient.find(o=>o.id== item.clientId)?listClient.find(o=>o.id== item.clientId).clientId:'' }}
                                </p>
                            </td>
                           
                            <td class="option-btn-action">
                                <div class="lst-choose-option-table">
                                    <div class="item-btn-action">
                                        <a href="javascript:;"
                                           @click="openPopup(item.id)" title="Sửa">
                                            <svg width="14"
                                                 height="16"
                                                 viewBox="0 0 14 16"
                                                 fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <path d="M2.44391 13.2697C2.58651 13.2697 2.61503 13.2552 2.74337 13.226L5.3102 12.7016C5.58115 12.6287 5.85209 12.4976 6.06599 12.2791L12.2834 5.92755C13.2389 4.95151 13.2389 3.27622 12.2834 2.30017L11.7558 1.73203C10.8004 0.75599 9.14619 0.75599 8.19076 1.73203L1.97332 8.09815C1.77368 8.3021 1.63108 8.59346 1.55978 8.87025L1.01789 11.5216C0.946591 12.0169 1.08919 12.4976 1.43144 12.8473C1.70238 13.124 2.10166 13.2697 2.44391 13.2697ZM2.92875 9.14703L9.14619 2.78091C9.55973 2.35845 10.3155 2.35845 10.7148 2.78091L11.2567 3.33449C11.7415 3.82979 11.7415 4.52905 11.2567 5.00978L5.05352 11.3759L2.41539 11.8275L2.92875 9.14703Z"
                                                      fill="#777E8A"></path>
                                                <path d="M12.2264 14.1729H1.70238C1.28884 14.1729 1.01789 14.4497 1.01789 14.8722C1.01789 15.2946 1.36014 15.5714 1.70238 15.5714H12.1693C12.5829 15.5714 12.9251 15.2946 12.9251 14.8722C12.9109 14.4497 12.5686 14.1729 12.2264 14.1729Z"
                                                      fill="#777E8A"></path>
                                                <path d="M2.44391 13.2697C2.58651 13.2697 2.61503 13.2552 2.74337 13.226L5.3102 12.7016C5.58115 12.6287 5.85209 12.4976 6.06599 12.2791L12.2834 5.92755C13.2389 4.95151 13.2389 3.27622 12.2834 2.30017L11.7558 1.73203C10.8004 0.75599 9.14619 0.75599 8.19076 1.73203L1.97332 8.09815C1.77368 8.3021 1.63108 8.59346 1.55978 8.87025L1.01789 11.5216C0.946591 12.0169 1.08919 12.4976 1.43144 12.8473C1.70238 13.124 2.10166 13.2697 2.44391 13.2697ZM2.92875 9.14703L9.14619 2.78091C9.55973 2.35845 10.3155 2.35845 10.7148 2.78091L11.2567 3.33449C11.7415 3.82979 11.7415 4.52905 11.2567 5.00978L5.05352 11.3759L2.41539 11.8275L2.92875 9.14703Z"
                                                      stroke="#777E8A"
                                                      stroke-width="0.1"></path>
                                                <path d="M12.2264 14.1729H1.70238C1.28884 14.1729 1.01789 14.4497 1.01789 14.8722C1.01789 15.2946 1.36014 15.5714 1.70238 15.5714H12.1693C12.5829 15.5714 12.9251 15.2946 12.9251 14.8722C12.9109 14.4497 12.5686 14.1729 12.2264 14.1729Z"
                                                      stroke="#777E8A"
                                                      stroke-width="0.1"></path>
                                            </svg>
                                        </a>
                                    </div>
                                    <div class="item-btn-action">
                                        <a href="javascript:;" @click="remove(item.id)" title="Xóa">
                                            <svg width="16"
                                                 height="16"
                                                 viewBox="0 0 16 16"
                                                 fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <path d="M13.55 14.1297V4.025H14.5C14.6457 4.025 14.7856 3.96759 14.8888 3.86528C14.992 3.76295 15.05 3.62407 15.05 3.47917C15.05 3.33427 14.992 3.19539 14.8888 3.09306C14.7856 2.99074 14.6457 2.93333 14.5 2.93333H11.05V1.99168C11.05 1.99168 11.05 1.99167 11.05 1.99167C11.0501 1.71973 10.943 1.45861 10.7517 1.26402C10.5603 1.06944 10.3 0.956805 10.0262 0.950015V0.95H10.025H6C5.72166 0.95 5.45463 1.05965 5.25769 1.25495C5.06073 1.45027 4.95 1.71526 4.95 1.99167V2.93333H1.5C1.35427 2.93333 1.21442 2.99074 1.11124 3.09306C1.00805 3.19539 0.95 3.33427 0.95 3.47917C0.95 3.62407 1.00805 3.76295 1.11124 3.86528C1.21442 3.96759 1.35427 4.025 1.5 4.025H2.45L2.45 14.1297L2.45 14.1298C2.45136 14.6056 2.64258 15.0615 2.98174 15.3979C3.32089 15.7342 3.78041 15.9237 4.25986 15.925H4.26L11.76 15.925L11.7607 15.925C12.2366 15.9183 12.6908 15.7265 13.0256 15.3907C13.3603 15.055 13.5487 14.6021 13.55 14.1298V14.1297ZM3.55 14.1297V14.1297V4.025H12.45V14.1297C12.45 14.3162 12.3753 14.4951 12.2422 14.6271C12.1091 14.7591 11.9284 14.8333 11.74 14.8333H4.24066C4.05572 14.8283 3.88011 14.752 3.75116 14.6207C3.62207 14.4892 3.54994 14.313 3.55 14.1297ZM6.05 2.04167H9.95V2.93333H6.05V2.04167ZM6.34 12.95C6.48573 12.95 6.62558 12.8926 6.72876 12.7903C6.83195 12.6879 6.89 12.5491 6.89 12.4042V6.45417C6.89 6.30927 6.83195 6.17039 6.72876 6.06806C6.62558 5.96574 6.48573 5.90833 6.34 5.90833C6.19427 5.90833 6.05442 5.96574 5.95124 6.06806C5.84805 6.17039 5.79 6.30927 5.79 6.45417V12.4042C5.79 12.5491 5.84805 12.6879 5.95124 12.7903C6.05442 12.8926 6.19427 12.95 6.34 12.95ZM9.66 12.95C9.80573 12.95 9.94558 12.8926 10.0488 12.7903C10.152 12.6879 10.21 12.5491 10.21 12.4042V6.45417C10.21 6.30927 10.152 6.17039 10.0488 6.06806C9.94558 5.96574 9.80573 5.90833 9.66 5.90833C9.51427 5.90833 9.37442 5.96574 9.27124 6.06806C9.16805 6.17039 9.11 6.30927 9.11 6.45417V12.4042C9.11 12.5491 9.16805 12.6879 9.27124 12.7903C9.37442 12.8926 9.51427 12.95 9.66 12.95Z"
                                                      fill="#777E8A"
                                                      stroke="#777E8A"
                                                      stroke-width="0.1"></path>
                                            </svg>
                                        </a>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <paging :total="total"
                    :page-index="filter.pageIndex"
                    @change="pageChange" />
        </div>
        <!-- popup -->
        <div class="popup-page open-popup" id="add-user-new-page" v-if="openPop">
            <div class="content-popup-page" v-if="!isOpenPer">
                <div class="main-popup-page">
                    <div class="main-popup-top">
                        <h2 class="name-popup">{{ id == 0 ? "Thêm mới" : "Sửa" }}</h2>
                        <div class="block-user-new-page">
                            <div class="row">
                                <div class="col-6">
                                    <div class="form-info-new">
                                        <div class="ct-input-info-new">
                                            <label class="label-bold">Tên miền</label>
                                            <input type="text"
                                                   class=""
                                                   v-model="form.origin"
                                                   />
                                        </div>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="form-info-new">
                                        <label class="label-bold">Client</label>
                                        <select v-model="form.clientId" class="ct-select">
                                            <option :value="item.id" v-for="item,index in listClient">{{item.clientId}}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="bottom-popup-action">
                        <div class="left-bottom-popup-action">
                            <div class="btn-cancel">
                                <a href="javascript:;" @click="closePop" class="btn-text">hủy</a>
                            </div>
                        </div>
                        <div class="right-bottom-popup-action">
                            <div class="btn-test-tt">
                                <a href="javascript:;" @click="submitData">Lưu lại & Đóng</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="close-popup-icon" @click="closePop">
                <svg width="16"
                     height="16"
                     viewBox="0 0 16 16"
                     fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                    <path d="M8.93803 8.00418L15.7966 1.14562C16.0613 0.889968 16.0686 0.468148 15.813 0.203437C15.5573 -0.061274 15.1355 -0.0686141 14.8708 0.187039C14.8652 0.192388 14.8598 0.197854 14.8544 0.203437L7.99582 7.062L1.13729 0.203398C0.872577 -0.0522551 0.450759 -0.044915 0.195107 0.219796C-0.0542988 0.478026 -0.0542988 0.887392 0.195107 1.14562L7.05364 8.00418L0.195107 14.8627C-0.0650355 15.1229 -0.0650355 15.5447 0.195107 15.8049C0.455288 16.065 0.877106 16.065 1.13729 15.8049L7.99582 8.94636L14.8543 15.8049C15.1191 16.0606 15.5409 16.0532 15.7965 15.7885C16.0459 15.5303 16.0459 15.1209 15.7965 14.8627L8.93803 8.00418Z"
                          fill="#868E96"></path>
                </svg>
            </div>
            <div class="bg-popup-page"></div>
        </div>
    </layout-main>
</template>

<script>
    //import { API_URL } from '../../plugins/api';
    export default {
        components: {
        },
        name: "ClientCorsOrigin",
        data() {
            return {
                filter: {
                    keyword: "",
                    pageIndex: 1,
                    pageSize: 15,
                    clientId: null
                },
                listData: [],
                total: 0,
                openPop: false,
                //detail
                form: {
                    origin: '',
                    clientId: null
                },
                isLoader: false,
                id: 0,
                listClient:[]
            };
        },
        watch: {
            "filter.keyword"(val) {
                clearTimeout(this.timeDelay);
                this.timeDelay = setTimeout(() => {
                    this.$set(this.filter, "pageIndex", 1);
                    this.$set(this.filter, "keyword", val);
                    this.search();
                }, 500);
            },
            //Search theo status
            //"filter.clientId"(val) {
            //    this.$set(this.filter, "pageIndex", 1);
            //    this.$set(this.filter, "clientId", val);
            //    this.search();
            //}
        },
        methods: {
            search() {
                this.filter.pageIndex = 1;
                this.getData();
            },
            getData() {
                const $loading = this.$loading.show();
                this.$axios
                    .get(this.$api.CLIENT_CORS_ORIGIN_GET_LIST, { params: this.filter })
                    .then((response) => {
                        console.log(response)
                        $loading.hide();
                        this.listData = response.result.data;
                        this.total = response.result.totalRows;
                    })
                    .catch((ex) => {
                        $loading.hide();
                    });
            },
            pageChange(pageNum) {
                this.filter.pageIndex = pageNum;
                this.getData();
            },

            getDetail() {
                const $loading = this.$loading.show();
                this.$axios.get(`${this.$api.CLIENT_CORS_ORIGIN_GET_BY_ID}?id=${this.id}`)
                    .then((response) => {
                        $loading.hide();
                        if (response) {
                            let data = response.result;
                            this.form = data;
                            this.openPop = true;
                        }
                    })
                    .catch((response) => {
                        $loading.hide();
                        this.$message(response.message, "error");
                    });
            },
            remove(id) {
                this.$confirm("Bạn có chắc chắn muốn xóa dữ liệu này không?").then(
                    () => {
                        this.$axios.delete(`${this.$api.CLIENT_CORS_ORIGIN_REMOVE}?id=${id}`)
                            .then((o) => {
                                this.$message("Xóa thành công!");
                                this.search();
                            })
                            .catch((response) => {
                                this.$message(response.message, "error");
                            });
                    }
                );
            },
            submitData() {
                var me = this;
                if (!this.form.redirectUri) {
                    return this.$message("Tên miền bắt buộc phải nhập!", "error");
                }
                if (!this.form.clientId) {
                    return this.$message("Client bắt buộc phải chọn!", "error");
                }

                const $loading = this.$loading.show();

                this.$axios.post(this.$api.CLIENT_CORS_ORIGIN_SAVE, this.form)
                    .then((response) => {
                        $loading.hide();
                        this.$message("Cập nhật thành công!");
                        this.closePop();
                    })
                    .catch((response) => {
                        $loading.hide();
                        this.$message(response.message, "error");
                    });
            },
            closePop() {
                this.openPop = false;
                this.id = 0;
                this.form = {
                    redirectUri: "",
                    clientId: null
                };
                this.search();
            },
            openPopup(id) {
                this.id = id;
                if (id == 0) {
                    this.openPop = true;
                } else {
                    this.getDetail();
                }
            },
            getListClients() {
                this.$axios
                    .get(this.$api.USER_GET_ALL_CLIENT)
                    .then((res) => {
                        this.listClient = res.result;
                    })
                    .catch(() => { });
            }
        },
        created() {
            this.search();
            this.getListClients();
        },
    };
</script>
<style>
    .vue-treeselect__control {
        height: 50px !important;
    }
</style>
