import apiUrl from '@/constants/api'
import axios from '../../plugins/axios'
export default {
    state: () => ({
        menuList: [
            {
                title: "Thông tin cá nhân",
                description: "manager_info",
                url: "",
                icon: "windows",
                staticKey: "personal_info",
                tree: true,
                subMenus: [
                    {
                        title: "Thông tin chung",
                        url: "/manager/info",
                        staticKey: "info_title"
                    },
                    {
                        title: "Đổi mật khẩu",
                        url: "/manager/change-password",
                        staticKey: "info_title"
                    },
                    //{
                    //    title: "Hồ sơ",
                    //    url: "/account/profile",
                    //    staticKey: "userProfile"
                    //},
                    //{
                    //    title: "Sổ địa chỉ",
                    //    url: "/manager/address",
                    //    staticKey: "address_manager"
                    //},
                    //{
                    //    title: "Email",
                    //    url: "/manager/email",
                    //    staticKey: "email"
                    //}
                    //{
                    //    title: "Điện thoại",
                    //    url: "/account/phone",
                    //    staticKey: "address_phone"
                    //}
                ]
            },
            {
                title: 'Quản lý người dùng',
                url: '',
                icon: 'folder',
                tree: true,
                requireAdmin:true,
                subMenus: [
                    {
                        title: 'Người dùng',
                        url: '/user',
                        icon: 'folder',
                        permissions: ["UserManagement"]
                    },
                    {
                        title: 'Quản lý tên miền tích hợp',
                        url: '/client-redirect-uris',
                        icon: 'folder',
                        permissions: ["UserManagement"]
                    },
                    {
                        title: 'Quản lý tên miền Cors',
                        url: '/client-cors-origin',
                        icon: 'folder',
                        permissions: ["UserManagement"]
                    },
                    //{
                    //    title: 'Nhóm quyền',
                    //    url: '/role',
                    //    icon: 'folder',
                    //    permissions: ["UserManagement"]
                    //},
                    {
                        title: 'Cài đặt bảo mật',
                        url: '/password-config',
                        icon: 'folder',
                        permissions: ["UserManagement"]
                    }

                ]
            },
        ],
        appSettings: {}
    }),
    mutations: {
        INIT_SYSTEM: (state, data) => {
            state.appSettings = data;
        }
    },
    actions: {
        initSystem({ state, commit }) {
            return new Promise((resolve, reject) => {
                return axios.get(apiUrl.SYSTEM_INIT_DATA).then(res => {
                    commit('INIT_SYSTEM', res.result);
                    return resolve(res.result);
                }).catch(err => {
                    commit('INIT_SYSTEM', {});
                    return reject(err);
                });
            })
        }
    },
    getters: {
        menuList: state => state.menuList,
        appSettings: state => state.appSettings
    }
}
