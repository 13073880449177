<template>
    <layout-main>
        <h2 class="name-page" slot="left">Đổi mật khẩu</h2>
        <div class="block-content-tab-follow">
            <div class="item-content-tab-follow ct-style-block">
                <div class="block-edit-info-page">
                    <div class="content-edit-info-page">
                        <div class="block-password-change" v-if="step==1">
                            <p class="txt-fill-pass">Vui lòng nhập mật khẩu hiện tại để xác thực bạn là chủ tài khoản</p>
                            <div class="lst-item-form-password">
                                <div class="item-form-password">
                                    <div class="form-info-new">
                                        <div class="ct-input-info-new">

                                            <input :type="isHideCurrentPassword?'password':'text'" class="" v-model="dataPassword.currentpassword">
                                            <div class="lst-option-icon-view" @click="isHideCurrentPassword = !isHideCurrentPassword">
                                                <div class="item-view-pass icon-show-pass" v-if="isHideCurrentPassword">
                                                    <svg width="20" height="12" viewBox="0 0 20 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M19.8381 5.54157C16.8172 1.86604 13.5556 0 10.1347 0C8.77943 0 7.40782 0.298889 6.05253 0.884551C4.98708 1.34904 3.9298 1.99125 2.91334 2.79502C1.19474 4.15618 0.182357 5.51329 0.141535 5.56984C-0.0544098 5.83642 -0.0462454 6.19993 0.161946 6.45843C3.17868 10.138 6.44442 12 9.86529 12C11.2206 12 12.5922 11.7011 13.9475 11.1154C15.0129 10.651 16.0702 10.0088 17.0867 9.20498C18.8053 7.84382 19.8176 6.48671 19.8585 6.43016C20.0544 6.16358 20.0462 5.80007 19.8381 5.54157ZM16.1355 8.09424C14.7149 9.21306 12.4779 10.5459 9.86529 10.5459C7.0445 10.5459 4.29311 9.01515 1.68052 5.99394C2.09282 5.51329 2.84802 4.70953 3.8604 3.90979C5.281 2.79098 7.51803 1.45809 10.1306 1.45809C12.9514 1.45809 15.7028 2.98889 18.3154 6.0101C17.9031 6.49074 17.1479 7.29855 16.1355 8.09424Z" fill="#777E8A"></path>
                                                        <path d="M12.7065 5.27499C12.3024 5.27499 11.9717 5.60215 11.9717 6.00202C11.9717 7.08044 11.0859 7.95692 9.99592 7.95692C8.90598 7.95692 8.02014 7.08044 8.02014 6.00202C8.02014 4.92359 8.90598 4.04712 9.99592 4.04712C10.4001 4.04712 10.7307 3.71996 10.7307 3.32009C10.7307 2.92023 10.4001 2.59307 9.99592 2.59307C8.09362 2.59307 6.55056 4.12386 6.55056 6.00202C6.55056 7.88017 8.09362 9.41097 9.99592 9.41097C11.8982 9.41097 13.4413 7.88017 13.4413 6.00202C13.4413 5.60215 13.1106 5.27499 12.7065 5.27499Z" fill="#777E8A"></path>
                                                        77
                                                    </svg>
                                                </div>
                                                <div class="item-view-pass icon-show-pass" v-else>
                                                    <svg width="20" height="15" viewBox="0 0 20 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M12.483 7.66846L13.6189 6.53252C13.7016 6.84174 13.75 7.16502 13.75 7.50002C13.75 9.56783 12.0678 11.25 10 11.25C9.665 11.25 9.34172 11.2016 9.0325 11.1189L10.1684 9.98298C11.4105 9.89829 12.3983 8.91048 12.483 7.66846ZM19.9213 7.19643C19.8444 7.05815 18.7541 5.14768 16.6228 3.52862L15.7133 4.43815C17.2673 5.58081 18.252 6.91346 18.6397 7.50096C17.8989 8.62892 14.993 12.5 10 12.5C9.25125 12.5 8.555 12.4042 7.89984 12.2514L6.87281 13.2784C7.81703 13.568 8.85484 13.75 10 13.75C16.5427 13.75 19.7864 8.04627 19.9213 7.80361C20.0263 7.61471 20.0263 7.38533 19.9213 7.19643ZM17.3169 1.06691L3.56688 14.8169C3.44484 14.9389 3.28484 15 3.125 15C2.96516 15 2.80516 14.9389 2.68313 14.8169C2.43891 14.5727 2.43891 14.1772 2.68313 13.9331L4.4325 12.1838C1.60438 10.4764 0.167344 7.96314 0.07875 7.80361C-0.02625 7.61471 -0.02625 7.38518 0.07875 7.19627C0.213594 6.95377 3.45734 1.25004 10 1.25004C11.6917 1.25004 13.157 1.63597 14.4097 2.2066L16.4331 0.183164C16.6773 -0.0610545 17.0728 -0.0610545 17.3169 0.183164C17.5609 0.427382 17.5611 0.822849 17.3169 1.06691ZM5.36453 11.2517L6.94688 9.66939C6.50984 9.05627 6.25 8.30861 6.25 7.50002C6.25 5.43221 7.93219 3.75003 10 3.75003C10.8086 3.75003 11.5563 4.00987 12.1694 4.44675L13.4489 3.16722C12.438 2.76238 11.2892 2.50003 10 2.50003C5.00703 2.50003 2.10109 6.37112 1.36047 7.49908C1.83078 8.21189 3.18266 10.0163 5.36453 11.2517ZM7.85422 8.76189L11.2619 5.35424C10.8902 5.13471 10.4622 5.00003 10 5.00003C8.62156 5.00003 7.5 6.12159 7.5 7.50002C7.5 7.96221 7.63469 8.39017 7.85422 8.76189Z" fill="#777E8A"></path>
                                                    </svg>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>

                            <p class="txt-fill-fail" v-if="isCheckingCurrentPassword">Mật khẩu không chính xác. Vui lòng thử lại.</p>
                            <div class="btn-next">
                                <a href="javascript:;" class="btn-bg-linear" @click="checkCurrentPassword">
                                    <span class="txt">Tiếp theo</span>
                                    <span class="icon">

                                        <svg width="16" height="11" viewBox="0 0 16 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M15.7871 4.85381L10.6961 0.187141C10.5589 0.0657028 10.3752 -0.00149374 10.1845 2.52016e-05C9.99384 0.00154415 9.81143 0.0716569 9.67659 0.195262C9.54175 0.318868 9.46526 0.486076 9.4636 0.660874C9.46194 0.835672 9.53525 1.00407 9.66773 1.12981L13.5172 4.65847H0.72728C0.534393 4.65847 0.349407 4.72871 0.213015 4.85374C0.0766239 4.97876 0 5.14833 0 5.32514C0 5.50195 0.0766239 5.67152 0.213015 5.79655C0.349407 5.92157 0.534393 5.99181 0.72728 5.99181H13.5172L9.66773 9.52047C9.59827 9.58197 9.54286 9.65553 9.50474 9.73687C9.46663 9.81821 9.44657 9.90569 9.44573 9.99421C9.44489 10.0827 9.46329 10.1705 9.49986 10.2524C9.53643 10.3344 9.59043 10.4088 9.65872 10.4714C9.727 10.534 9.8082 10.5835 9.89758 10.617C9.98696 10.6505 10.0827 10.6674 10.1793 10.6666C10.2759 10.6659 10.3713 10.6475 10.46 10.6125C10.5488 10.5776 10.629 10.5268 10.6961 10.4631L15.7871 5.79647C15.9234 5.67146 16 5.50192 16 5.32514C16 5.14837 15.9234 4.97883 15.7871 4.85381Z" fill="white"></path>
                                            hi
                                        </svg>

                                    </span>
                                </a>
                            </div>
                        </div>
                        <div class="block-password-change" v-else-if="step==2">
                            <p class="txt-fill-pass">Khuyến cáo: Mật khẩu từ 6 - 50 ký tự; Mật khẩu nên bao gồm ít nhất 1 ký tự chữ, số, và 1 ký tự đặc biệt</p>
                            <div class="lst-item-form-password">
                                <div class="item-form-password">
                                    <div class="form-info-new">
                                        <div class="ct-input-info-new">
                                            <label class="label-bold">Mật khẩu mới</label>
                                            <input :type="isHideNewPassword?'password':'text'" class="" v-model="dataPassword.newpassword">
                                            <div class="lst-option-icon-view" @click="isHideNewPassword= !isHideNewPassword">
                                                <div class="item-view-pass icon-show-pass" v-if="isHideNewPassword">
                                                    <svg width="20" height="12" viewBox="0 0 20 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M19.8381 5.54157C16.8172 1.86604 13.5556 0 10.1347 0C8.77943 0 7.40782 0.298889 6.05253 0.884551C4.98708 1.34904 3.9298 1.99125 2.91334 2.79502C1.19474 4.15618 0.182357 5.51329 0.141535 5.56984C-0.0544098 5.83642 -0.0462454 6.19993 0.161946 6.45843C3.17868 10.138 6.44442 12 9.86529 12C11.2206 12 12.5922 11.7011 13.9475 11.1154C15.0129 10.651 16.0702 10.0088 17.0867 9.20498C18.8053 7.84382 19.8176 6.48671 19.8585 6.43016C20.0544 6.16358 20.0462 5.80007 19.8381 5.54157ZM16.1355 8.09424C14.7149 9.21306 12.4779 10.5459 9.86529 10.5459C7.0445 10.5459 4.29311 9.01515 1.68052 5.99394C2.09282 5.51329 2.84802 4.70953 3.8604 3.90979C5.281 2.79098 7.51803 1.45809 10.1306 1.45809C12.9514 1.45809 15.7028 2.98889 18.3154 6.0101C17.9031 6.49074 17.1479 7.29855 16.1355 8.09424Z" fill="#777E8A"></path>
                                                        <path d="M12.7065 5.27499C12.3024 5.27499 11.9717 5.60215 11.9717 6.00202C11.9717 7.08044 11.0859 7.95692 9.99592 7.95692C8.90598 7.95692 8.02014 7.08044 8.02014 6.00202C8.02014 4.92359 8.90598 4.04712 9.99592 4.04712C10.4001 4.04712 10.7307 3.71996 10.7307 3.32009C10.7307 2.92023 10.4001 2.59307 9.99592 2.59307C8.09362 2.59307 6.55056 4.12386 6.55056 6.00202C6.55056 7.88017 8.09362 9.41097 9.99592 9.41097C11.8982 9.41097 13.4413 7.88017 13.4413 6.00202C13.4413 5.60215 13.1106 5.27499 12.7065 5.27499Z" fill="#777E8A"></path>
                                                    </svg>
                                                </div>
                                                <div class="item-view-pass icon-show-pass" v-else>
                                                    <svg width="20" height="15" viewBox="0 0 20 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M12.483 7.66846L13.6189 6.53252C13.7016 6.84174 13.75 7.16502 13.75 7.50002C13.75 9.56783 12.0678 11.25 10 11.25C9.665 11.25 9.34172 11.2016 9.0325 11.1189L10.1684 9.98298C11.4105 9.89829 12.3983 8.91048 12.483 7.66846ZM19.9213 7.19643C19.8444 7.05815 18.7541 5.14768 16.6228 3.52862L15.7133 4.43815C17.2673 5.58081 18.252 6.91346 18.6397 7.50096C17.8989 8.62892 14.993 12.5 10 12.5C9.25125 12.5 8.555 12.4042 7.89984 12.2514L6.87281 13.2784C7.81703 13.568 8.85484 13.75 10 13.75C16.5427 13.75 19.7864 8.04627 19.9213 7.80361C20.0263 7.61471 20.0263 7.38533 19.9213 7.19643ZM17.3169 1.06691L3.56688 14.8169C3.44484 14.9389 3.28484 15 3.125 15C2.96516 15 2.80516 14.9389 2.68313 14.8169C2.43891 14.5727 2.43891 14.1772 2.68313 13.9331L4.4325 12.1838C1.60438 10.4764 0.167344 7.96314 0.07875 7.80361C-0.02625 7.61471 -0.02625 7.38518 0.07875 7.19627C0.213594 6.95377 3.45734 1.25004 10 1.25004C11.6917 1.25004 13.157 1.63597 14.4097 2.2066L16.4331 0.183164C16.6773 -0.0610545 17.0728 -0.0610545 17.3169 0.183164C17.5609 0.427382 17.5611 0.822849 17.3169 1.06691ZM5.36453 11.2517L6.94688 9.66939C6.50984 9.05627 6.25 8.30861 6.25 7.50002C6.25 5.43221 7.93219 3.75003 10 3.75003C10.8086 3.75003 11.5563 4.00987 12.1694 4.44675L13.4489 3.16722C12.438 2.76238 11.2892 2.50003 10 2.50003C5.00703 2.50003 2.10109 6.37112 1.36047 7.49908C1.83078 8.21189 3.18266 10.0163 5.36453 11.2517ZM7.85422 8.76189L11.2619 5.35424C10.8902 5.13471 10.4622 5.00003 10 5.00003C8.62156 5.00003 7.5 6.12159 7.5 7.50002C7.5 7.96221 7.63469 8.39017 7.85422 8.76189Z" fill="#777E8A"></path>
                                                    </svg>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="item-form-password">
                                    <div class="form-info-new">
                                        <div class="ct-input-info-new">
                                            <label class="label-bold">Nhập lại mật khẩu</label>
                                            <input :type="isHideConfirmPassword?'password':'text'" class="" v-model="dataPassword.confirmnewpassword">
                                            <div class="lst-option-icon-view" @click="isHideConfirmPassword=!isHideConfirmPassword">
                                                <div class="item-view-pass icon-show-pass" v-if="isHideConfirmPassword">
                                                    <svg width="20" height="12" viewBox="0 0 20 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M19.8381 5.54157C16.8172 1.86604 13.5556 0 10.1347 0C8.77943 0 7.40782 0.298889 6.05253 0.884551C4.98708 1.34904 3.9298 1.99125 2.91334 2.79502C1.19474 4.15618 0.182357 5.51329 0.141535 5.56984C-0.0544098 5.83642 -0.0462454 6.19993 0.161946 6.45843C3.17868 10.138 6.44442 12 9.86529 12C11.2206 12 12.5922 11.7011 13.9475 11.1154C15.0129 10.651 16.0702 10.0088 17.0867 9.20498C18.8053 7.84382 19.8176 6.48671 19.8585 6.43016C20.0544 6.16358 20.0462 5.80007 19.8381 5.54157ZM16.1355 8.09424C14.7149 9.21306 12.4779 10.5459 9.86529 10.5459C7.0445 10.5459 4.29311 9.01515 1.68052 5.99394C2.09282 5.51329 2.84802 4.70953 3.8604 3.90979C5.281 2.79098 7.51803 1.45809 10.1306 1.45809C12.9514 1.45809 15.7028 2.98889 18.3154 6.0101C17.9031 6.49074 17.1479 7.29855 16.1355 8.09424Z" fill="#777E8A"></path>
                                                        <path d="M12.7065 5.27499C12.3024 5.27499 11.9717 5.60215 11.9717 6.00202C11.9717 7.08044 11.0859 7.95692 9.99592 7.95692C8.90598 7.95692 8.02014 7.08044 8.02014 6.00202C8.02014 4.92359 8.90598 4.04712 9.99592 4.04712C10.4001 4.04712 10.7307 3.71996 10.7307 3.32009C10.7307 2.92023 10.4001 2.59307 9.99592 2.59307C8.09362 2.59307 6.55056 4.12386 6.55056 6.00202C6.55056 7.88017 8.09362 9.41097 9.99592 9.41097C11.8982 9.41097 13.4413 7.88017 13.4413 6.00202C13.4413 5.60215 13.1106 5.27499 12.7065 5.27499Z" fill="#777E8A"></path>
                                                    </svg>
                                                </div>
                                                <div class="item-view-pass icon-show-pass" v-else>
                                                    <svg width="20" height="15" viewBox="0 0 20 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M12.483 7.66846L13.6189 6.53252C13.7016 6.84174 13.75 7.16502 13.75 7.50002C13.75 9.56783 12.0678 11.25 10 11.25C9.665 11.25 9.34172 11.2016 9.0325 11.1189L10.1684 9.98298C11.4105 9.89829 12.3983 8.91048 12.483 7.66846ZM19.9213 7.19643C19.8444 7.05815 18.7541 5.14768 16.6228 3.52862L15.7133 4.43815C17.2673 5.58081 18.252 6.91346 18.6397 7.50096C17.8989 8.62892 14.993 12.5 10 12.5C9.25125 12.5 8.555 12.4042 7.89984 12.2514L6.87281 13.2784C7.81703 13.568 8.85484 13.75 10 13.75C16.5427 13.75 19.7864 8.04627 19.9213 7.80361C20.0263 7.61471 20.0263 7.38533 19.9213 7.19643ZM17.3169 1.06691L3.56688 14.8169C3.44484 14.9389 3.28484 15 3.125 15C2.96516 15 2.80516 14.9389 2.68313 14.8169C2.43891 14.5727 2.43891 14.1772 2.68313 13.9331L4.4325 12.1838C1.60438 10.4764 0.167344 7.96314 0.07875 7.80361C-0.02625 7.61471 -0.02625 7.38518 0.07875 7.19627C0.213594 6.95377 3.45734 1.25004 10 1.25004C11.6917 1.25004 13.157 1.63597 14.4097 2.2066L16.4331 0.183164C16.6773 -0.0610545 17.0728 -0.0610545 17.3169 0.183164C17.5609 0.427382 17.5611 0.822849 17.3169 1.06691ZM5.36453 11.2517L6.94688 9.66939C6.50984 9.05627 6.25 8.30861 6.25 7.50002C6.25 5.43221 7.93219 3.75003 10 3.75003C10.8086 3.75003 11.5563 4.00987 12.1694 4.44675L13.4489 3.16722C12.438 2.76238 11.2892 2.50003 10 2.50003C5.00703 2.50003 2.10109 6.37112 1.36047 7.49908C1.83078 8.21189 3.18266 10.0163 5.36453 11.2517ZM7.85422 8.76189L11.2619 5.35424C10.8902 5.13471 10.4622 5.00003 10 5.00003C8.62156 5.00003 7.5 6.12159 7.5 7.50002C7.5 7.96221 7.63469 8.39017 7.85422 8.76189Z" fill="#777E8A"></path>
                                                    </svg>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div class="bottom-popup-action" v-if="step==2">
                            <div class="left-bottom-popup-action">
                                <div class="btn-cancel">
                                    <a href="javascript:;" class="btn-text" @click="cancel">Hủy bỏ</a>
                                </div>
                            </div>
                            <div class="right-bottom-popup-action">
                                <div class="btn-save">
                                    <a href="javascript:;" class="btn-bg-linear" @click="changePassword">Xác nhận đổi</a>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <!--Thông báo-->
            <div class="popup-page open-popup ct-popup-small" id="add-detele-popup" v-if="isShowNotification">
                <div class="content-popup-page">
                    <div class="main-popup-page">
                        <div class="block-notification-detele">
                            <h2 class="title">Thông báo</h2>
                            <div class="box-notification-detele">
                                <div class="icon-notification">
                                    <img src="img-fix/icon-ntf.svg" alt="">
                                </div>
                                <p class="txt-content-detele">
                                    Đổi mật khẩu mới thành công</span>
                                </p>
                            </div>
                            <p class="txt-small-note">Bạn có muốn đăng xuất tài khoản này khỏi tất cả các thiết bị trước đó không? <span class="txt-bold">(Khuyến nghị nên dùng)</span></p>
                            <div class="group-bottom-btn-popup">
                                <div class="left-bottom-btn-popup">
                                    <div class="btn-cancel">
                                        <a href="javascript" @click="backToMain" class="btn-text">Không</a>
                                    </div>
                                </div>
                                <div class="right-bottom-btn-popup">
                                    <div class="btn-save">
                                        <a href="javascript:;" @click="logoutAll" class="btn-bg-linear">Đăng xuất ngay</a>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <div class="bg-popup-page"></div>
            </div>
        </div>
    </layout-main>
    <!--<main class="content-page">
    <div class="container">
        <div class="main-ct">
            <div class="box type2">
                <div class="box-head">
                    <h2>{{$t("change_password")}}</h2>
                </div>
                <div class="box-ct">
                    <div class="row mb-5 pb-5" v-if="step == 1">
                        <div class="col-md-6 m-order2">
                            <ul class="row lst-itm-info">
                                <li class="col-md-12">
                                    <div class="custom-input">
                                        <input-password v-model="dataPassword.currentpassword" :label="$t('current_password')"/>
                                    </div>
                                </li>
                                <li class="col-md-12">
                                    <div class="custom-input">
                                        <input-password v-model="dataPassword.newpassword" :label="$t('new_password')" />
                                    </div>
                                    <span class="txt-mess">Yêu cầu tối thiểu 6 ký tự</span>
                                </li>
                                <li class="col-md-12">-->
    <!-- [D] Thêm class "is-err" vào class "custom-input" khi xuất hiện trạng thái error input-->
    <!--<div class="custom-input">
        <input-password v-model="dataPassword.newpassworddataPassword.confirmnewpassword" :label="$t('confirm_new_password')" />
    </div>-->
    <!--<span class="txt-mess">Mật khẩu không trùng khớp</span>-->
    <!--</li>
                                    <li class="col-md-12">
                                        <button type="button" class="btn btn-update" @click="changePassword"> {{ $t('next') }}</button>
                                    </li>
                                </ul>
                                <router-link class="txt-link mb-4" to="/forgot-password" v-if="!iframe">
                                    {{ $t("fogot_password") }}?
                                </router-link>
                            </div>
                            <div class="col-md-6">
                                <div class="box-gray">
                                    <div class="strength-password d-flex flex-wrap align-items-center">
                                        <strong>Độ mạnh</strong>
                                        <span class="itm-level" :class="{'is-pass': passwordStrength >= l }" v-for="l in 4"></span>
                                    </div>
                                    <div class="guide-lst mt-2">
                                        <ol>
                                            <li>1. Sử dụng ít nhất 6 ký tự</li>
                                            <li>2. Chứa ít nhất 1 chữ số</li>
                                            <li>3. Chứa ít nhất 1 ký tự viết hoa</li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row mb-5 pb-5" v-else>
                            <div class="col-md-6 m-order2">
                                <ul class="row lst-itm-info">
                                    <li class="col-md-12" v-if="false">
                                        <div class="authen-changePass-idPage">
                                            <div class="custom-input selbox">
                                                <button type="button" class="btn-selbox ipt">Số điện thoại:0976123456</button>
                                                <label class="lbl">Nhận mã xác thực qua</label>
                                                <div class="selbox-lst-area scroll">
                                                    <ul class="selbox-lst">
                                                        <li><button type="button" class="sel-value">Số điện thoại: 0976123456</button></li>
                                                        <li><button type="button" class="sel-value">Email:demoemail@gmail.com</button></li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <button class="resend-otp-chanegPass disable">Gửi mã <span class="countdown">(60s)</span></button>
                                        </div>
                                    </li>

                                    <li class="col-md-12">
                                        <div class="custom-input">
                                            <input type="number" name="otp" id="authen-changePass" class="ipt" v-model="dataPassword.otp">
                                            <label for="authen-changePass" class="lbl">{{$t('otp_input')}}</label>
                                            <a href="javascript:;" class="resend-authen-code disable" v-if="seconds > 0">
                                                {{ $t("otp_resend_in") }}
                                                <span class="countdown" v-if="seconds > 0">({{ seconds }}s)</span>
                                            </a>
                                            <a href="javascript:;" class="resend-authen-code" @click="resendOTP" v-else>
                                                {{$t('register_resend_otp') }}
                                            </a>
                                        </div>
                                    </li>
                                    <li class="col-md-12">
                                        <div class="out-device-changePass">
                                            <ul>
                                                <li class="form-check form-check-inline radio-check">
                                                    <input class="form-check-input" type="radio" name="addr-type" v-model="isLogout" :value="false" id="out-device-1" checked>
                                                    <label class="form-check-label" for="out-device-1"> {{ $t("keep_login") }}</label>
                                                </li>

                                                <li class="form-check form-check-inline radio-check mt-2">
                                                    <input class="form-check-input" type="radio" name="addr-type" v-model="isLogout" :value="true" id="out-device-2">
                                                    <label class="form-check-label" for="out-device-2"> {{ $t("logout_all") }}</label>
                                                </li>
                                            </ul>
                                        </div>
                                    </li>
                                    <li class="col-md-12">
                                        <button type="button" class="btn btn-update" @click="changePassword"> {{ $t("update") }}</button>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>-->
</template>
<style>
    .col-centered {
        float: none;
        margin: 0 auto;
    }
</style>
<script>
    import { mapActions } from "vuex";
    export default {
        props: ['iframe'],
        layout: 'manager',
        middleware: 'authenticated',
        data() {
            return {
                step: 1,
                seconds: 0,
                dataPassword: {
                    currentpassword: '',
                    newpassword: '',
                    confirmnewpassword: '',
                    otp: '',
                },
                showCurrentPassword: true,
                responseData: null,
                isLogout: false,
                isCheckingCurrentPassword: false,
                isShowNotification: false,
                isHideCurrentPassword: true,
                isHideNewPassword: true,
                isHideConfirmPassword: true
            };
        },
        computed: {
            passwordStrength() {
                var strongRegex = new RegExp("^(?=.{8,})(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*\\W).*$", "g");
                var mediumRegex = new RegExp("^(?=.{6,})(((?=.*[A-Z])(?=.*[a-z]))|((?=.*[A-Z])(?=.*[0-9]))|((?=.*[a-z])(?=.*[0-9]))).*$", "g");
                var enoughRegex = new RegExp("(?=.{6,}).*", "g");
                var pwd = this.dataPassword.newpassword;
                if (pwd.length == 0) {
                    return 0;
                } else if (false == enoughRegex.test(pwd)) {
                    return 1;
                } else if (strongRegex.test(pwd)) {
                    if (pwd.length > 8) return 4;
                    return 3;
                } else if (mediumRegex.test(pwd)) {
                    return 2;
                } else {
                    return 1;
                }
                return 0;
            }
        },
        created() {
            const $loading = this.$loading.show();
            this.$axios
                .get(this.$api.ACCOUNT_HAS_TEMP_PASSWORD)
                .then((data) => {
                    this.responseData = data.result;
                    if (this.responseData.isOk) {
                        this.showCurrentPassword = !this.responseData.data;
                    } else {
                        this.$error(this.responseData.msg);
                    }
                    $loading.hide();
                })
                .catch((ex) => {
                    console.log(ex);
                    $loading.hide();
                });
        },
        methods: {
            ...mapActions('user', ['logoutAll']),

            changePassword() {
                if (!this.dataPassword.newpassword) {
                    return this.$error('Mật khẩu không được để trống');
                }
                if (!this.dataPassword.confirmnewpassword) {
                    return this.$error('Nhập lại mật khẩu không được để trống');
                }
                if (this.dataPassword.confirmnewpassword != this.dataPassword.newpassword) {
                    return this.$error('Nhập lại mật khẩu không trùng khớp');
                }
                if (!this.checkPassword(this.dataPassword.newpassword)) {
                    return this.$error('Mật khẩu không hợp lệ');
                }
                const $loading = this.$loading.show();
                this.$axios
                    .post(this.$api.ACCOUNT_CHANGE_PASSWORD, this.dataPassword)
                    .then((data) => {
                        $loading.hide();
                        if (data.result) {
                            //this.$success(this.$t('change_password_success'));
                            this.isShowNotification = true;
                        }
                    })
                    .catch((ex) => {
                        $loading.hide();
                        const error = this.$api.getApiError(ex);
                        if (error.error === 'invalid_grant') {
                            this.$error(this.$t(error.error_description));
                        } else {
                            this.$error(error);
                        }
                    });
            },
            checkCurrentPassword() {
                if (!this.dataPassword.currentpassword) {
                    return this.$error('Mật khẩu không được để trống');
                }
                const $loading = this.$loading.show();
                this.$axios
                    .post(this.$api.ACCOUNT_CHECK_CURRENT_PASSWORD, this.dataPassword)
                    .then((data) => {
                        $loading.hide();
                        console.log('143123412', data);
                        if (data.result) {
                            this.step = 2
                            this.isCheckingCurrentPassword = false;
                        } else {
                            this.isCheckingCurrentPassword = true;
                        }
                    })
                    .catch((ex) => {
                        $loading.hide();
                        const error = this.$api.getApiError(ex);
                        if (error.error === 'invalid_grant') {
                            this.$error(this.$t(error.error_description));
                        } else {
                            this.$error(error);
                        }
                    });
            },
            resendOTP() {
                this.dataPassword.otp = "";
                this.$api.resentOTP(this);
            },
            cancel() {
                this.dataPassword = {};
                this.step = 1;
            },
            backToMain() {
                this.$route.push('/')
            }
        },
    };
</script>
