import Vue from 'vue';
import App from './App.vue';
import router from './router';
import { i18n } from './locales/config'
// plugins
import './plugins/cookie'
import './plugins/axios'
import './plugins/vue-loading-overlay'
import './plugins/api'
import './plugins/vue-toast-notification'
import './plugins/date-dropdown'
import './plugins/filter'
import './plugins/alert'
import lodash from 'lodash';
import VueLodash from 'vue-lodash';
import Modal from './components/common/modal'
import TSelect from './components/common/tselect'
import InputPassword from './components/common/input-password'
import VuePagination from './components/common/vue-pagination'
import selectDateDrop from './components/common/select-date-drop'
import paging from './components/common/pager'
import avatar from './components/common/avatar'
import layoutMain from './components/layout/main'
import './common/common'
import Vuelidate from 'vuelidate'
import vClickOutside from 'v-click-outside'
import Fragment from 'vue-fragment';

// mixin
import './plugins/i18n'
import './plugins/common'
import './assets/css/bootstrap.min.css';
import './assets/css/style.css';
import "./assets/css/custom.css";
import OtpInput from "@bachdgvn/vue-otp-input";
import config from "../nuxt.config.js"
// Vuex
import store from './store';
import AuthService from './plugins/auth.js';
import VueMoment from 'vue-moment';

Vue.prototype.$auth = new AuthService();
Vue.prototype.$config = config;
Vue.config.productionTip = false;
Vue.component('modal', Modal);
Vue.component('tselect', TSelect);
Vue.use(VueLodash, lodash);
Vue.use(Vuelidate);
Vue.use(vClickOutside);
Vue.use(VueMoment);
Vue.use(Fragment.Plugin);
Vue.component("v-otp-input", OtpInput);
Vue.component("input-password", InputPassword);
Vue.component("vue-pagination", VuePagination);
Vue.component("select-date-drop", selectDateDrop);
Vue.component("layout-main", layoutMain);
Vue.component("paging", paging);
Vue.component("avatar", avatar);

new Vue({
    i18n,
    router,
    store,
    render: (h) => h(App),
}).$mount('#app');
