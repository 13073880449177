import Vue from 'vue';
import { mapGetters, mapActions } from 'vuex';
if (!process.env.VUE_ENV) {
    Vue.mixin({
        data() {
            return {
                ww: window.innerWidth
            };
        },
        computed: {
           
        },
        methods: {
            ...mapActions(['updateTrigger']),
            handleResize() {
                this.ww = window.innerWidth;
            },
            toCamelCase(str) {
                return str
                    .replace(/\s(.)/g, function ($1) { return $1.toUpperCase(); })
                    .replace(/\s/g, '')
                    .replace(/^(.)/, function ($1) { return $1.toLowerCase(); });
            }
        },
        mounted: function() {
            window.addEventListener('resize', this.handleResize);
            if (this.scope) document.body.classList.add(this.scope);
        },
        beforeDestroy: function() {
            window.removeEventListener('resize', this.handleResize);
        },
        destroyed() {
            if (this.scope) document.body.classList.remove(this.scope);
        }
    });
}
