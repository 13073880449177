<template>
    <div :class="cssClass" :style="[style, customStyle]" :title="avatarAlt">
        <span v-if="!this.src">{{ userInitial }}</span>
    </div>
</template>

<script>
export default {
    name: 'Avatar',
    props: {
        username: {
            type: String
        },
        initials: {
            type: String
        },
        backgroundColor: {
            type: String
        },
        color: {
            type: String
        },
        customStyle: {
            type: Object
        },
        size: {
            type: Number,
            default: 36
        },
        src: {
            type: String
        },
        rounded: {
            type: Boolean,
            default: true
        },
        lighten: {
            type: Number,
            default: 90
        },
        cssClass: {
            type: String,
            default: 'avatar'
        }
    },

    data() {
        return {
            backgroundColors: [
                '#F44336',
                '#FF4081',
                '#9C27B0',
                '#673AB7',
                '#22b9ff',
                '#2196F3',
                '#03A9F4',
                '#00BCD4',
                '#009688',
                '#4CAF50',
                '#8BC34A',
                '#CDDC39',
                /* '#FFEB3B' , */ '#FFC107',
                '#FF9800',
                '#FF5722',
                '#795548',
                '#9E9E9E',
                '#607D8B'
            ]
        };
    },

    computed: {
        background() {
            return (
                this.backgroundColor ||
                this.randomBackgroundColor(this.avatarAlt.length, this.backgroundColors)
            );
        },
        avatarAlt() {
            if (this.username == '' || !this.username) return 'H V';
            return this.username;
        },
        fontColor() {
            return this.color || this.lightenColor(this.background, this.lighten);
        },

        isImage() {
            return Boolean(this.src);
        },

        style() {
            const style = {
                width: `${this.size}px`,
                height: `${this.size}px`,
                borderRadius: this.rounded ? '50%' : 0,
                textAlign: 'center',
                verticalAlign: 'middle'
            };
            let source = this.src;
            if (this.isImage) {
                if (this.src.lastIndexOf('http') == -1)
                    source = this.appSettings.configs.storageDomain + this.src;
                if (
                    (source.lastIndexOf(this.appSettings.configs.storageDomain) != -1 ||
                        source.lastIndexOf('dekiru.vn') != -1) &&
                    source.lastIndexOf('?') == -1
                ) {
                    source += '?w=' + this.size + '&h=' + this.size + '&mode=crop';
                }
            }

            const imgBackgroundAndFontStyle = {
                background: `url('${source}') no-repeat scroll 0% 0% / ${this.size}px ${
                    this.size
                }px content-box border-box transparent`
                //, url('/images-fix/no-avatar.png')
                //'background-clip': 'border-box',
                //'background-repeat': 'no-repeat',
                //'background-origin': 'content-box',
                //'background-size': 'cover'
            };

            const initialBackgroundAndFontStyle = {
                backgroundColor: this.background,
                font: Math.floor(this.size / 3) + 'px/100px Helvetica, Arial, sans-serif',
                fontWeight: 'normal',
                color: this.fontColor,
                lineHeight: `${this.size + Math.floor(this.size / 20)}px`
            };

            const backgroundAndFontStyle = this.isImage
                ? imgBackgroundAndFontStyle
                : initialBackgroundAndFontStyle;

            Object.assign(style, backgroundAndFontStyle);

            return style;
        },

        userInitial() {
            const initials = this.initials || this.initial(this.avatarAlt);
            return initials;
        }
    },

    mounted() {
        this.$emit('avatar-initials', this.avatarAlt, this.userInitial);
    },

    methods: {
        initial(username) {
            let parts = username.split(/[ -]/);
            let initials = '';

            for (var i = 0; i < parts.length; i++) {
                initials += parts[i].charAt(0);
            }

            if (initials.length > 3 && initials.search(/[A-Z]/) !== -1) {
                initials = initials.replace(/[a-z]+/g, '');
            }

            initials = initials.substr(0, 3).toUpperCase();

            return initials;
        },

        randomBackgroundColor(seed, colors) {
            return colors[seed % colors.length];
        },

        lightenColor(hex, amt) {
            // From https://css-tricks.com/snippets/javascript/lighten-darken-color/
            var usePound = false;

            if (hex[0] === '#') {
                hex = hex.slice(1);
                usePound = true;
            }

            var num = parseInt(hex, 16);
            var r = (num >> 16) + amt;

            if (r > 255) r = 255;
            else if (r < 0) r = 0;

            var b = ((num >> 8) & 0x00ff) + amt;

            if (b > 255) b = 255;
            else if (b < 0) b = 0;

            var g = (num & 0x0000ff) + amt;

            if (g > 255) g = 255;
            else if (g < 0) g = 0;

            return (usePound ? '#' : '') + (g | (b << 8) | (r << 16)).toString(16);
        }
    }
};
</script>
